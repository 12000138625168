import "./Comparison.scss";

import PropTypes from "prop-types";
import React from "react";

import configTextForElements from "../../configs/configTextForElements";
import ComparisonTable from "../ComparisonTable/ComparisonTable";

const DefaultComparison = (props) => {
  const { wrongData } = props;
  const { wrongMessage } = configTextForElements;

  return (
    <div className="Comparison default-comparison">
      <div className="wrapper-description">
        {wrongData && <h3>{wrongMessage}</h3>}
        {!wrongData && (
          <>
            <h3>You can to compare two mortgages on this page.</h3>
            <h3>
              But at the beginning you have to select two saved mortgages.
            </h3>
          </>
        )}
      </div>
      <ComparisonTable />
    </div>
  );
};

export default DefaultComparison;

DefaultComparison.propTypes = {
  wrongData: PropTypes.bool,
};

DefaultComparison.defaultProps = {
  wrongData: false,
};
