import { getCmhcInsurance } from "./components/Insurance/helpers";
import config from "./configs/configCards";
import configRebate from "./configs/configRebate";

const { places } = configRebate;
/* LAND TRANSFER TAX */
const getLandTransferTaxFromRates = (rates, prop_value) => {
  let total = 0;
  let previousMax = 0;
  for (let i = 0; i < rates.length; i += 1) {
    const [max, rate] = rates[i];
    const t = prop_value >= max ? max : prop_value;
    const diff = t - previousMax;
    total += (diff * rate) / 100;
    if (prop_value < max) {
      break;
    }
    previousMax = max;
  }
  return total;
};

const getLandTransferTax = (property_value, region, city) => {
  let provincial = 0;
  let municipal = 0;

  const provincialRates = config.landTransferTax[region];
  const cityRates = config.landTransferTax[city];

  if (provincialRates) {
    provincial = getLandTransferTaxFromRates(provincialRates, property_value);
  }
  if (cityRates) {
    municipal = getLandTransferTaxFromRates(cityRates, property_value);
  }

  const total = provincial + municipal;

  return {
    provincial,
    municipal,
    total,
  };
};

/* REBATE */
const getRebateValue = (current_place, list_places) => {
  const region = current_place.code_name;
  const { city } = current_place;
  const output = list_places[city]
    ? list_places[city].maximum + list_places[region].maximum
    : list_places[region].maximum;
  return output;
};

const getRebate = (loc) => {
  const is_show = !!places[loc.code_name];
  const maximum_rebate = is_show ? getRebateValue(loc, places) : 0;
  return { is_show, maximum_rebate };
};

const getRealLandTransferTax = (ltt, max_rebate, rebate_arr) => {
  const delta = ltt > max_rebate ? ltt - max_rebate : 0;
  const output = rebate_arr.first_time ? delta : ltt;
  return output;
};

/* CASH NEEDED */
export default function getCashNeeded(data) {
  const { down_payment, property, location, rebate } = data;
  const { code_name, city } = location;
  const { insurance_percent } = getCmhcInsurance(property, down_payment);

  const LAWYER_FEES = 1000;
  const REGION_VALUES = {
    MB: 0.07,
    ON: 0.08,
    QB: 0.09975,
  };
  const cof = REGION_VALUES[code_name] ? REGION_VALUES[code_name] : 0;
  const rebate_value = getRebate(location);

  const down_payment_cash = (property / 100) * down_payment;
  const pst_insurance =
    cof * ((property - down_payment_cash) / 100) * insurance_percent;
  const land_transfer_tax = getLandTransferTax(property, code_name, city).total;
  const real_land_transfer_tax = getRealLandTransferTax(
    land_transfer_tax,
    rebate_value.maximum_rebate,
    rebate
  );
  const lawyer_fees = LAWYER_FEES;
  const title_insurance = property / 1000;
  const full_cash_needed =
    down_payment_cash +
    pst_insurance +
    real_land_transfer_tax +
    lawyer_fees +
    title_insurance;
  return {
    down_payment_cash,
    pst_insurance,
    land_transfer_tax: real_land_transfer_tax,
    rebate: rebate_value,
    lawyer_fees,
    title_insurance,
    full_cash_needed,
  };
}
