import "./CashNeeded.scss";

import { Collapse } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

import { setGroup } from "../../actions";
import { formatToMoney } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import Rebate from "../Rebate/Rebate";
import CashNeededTable from "./CashNeededTable";

const CashNeeded = () => {
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const active_keys = useActualState("collapses").cash_needed;

  const state_cash_needed = useActualState("cash_needed");

  function fCollapse(key) {
    dispatch(setGroup({ group: "collapses", name: "cash_needed", value: key }));
  }

  return (
    <div className="cash-needed">
      <Collapse
        className="cl-insurance-tip"
        activeKey={active_keys}
        onChange={fCollapse}
      >
        <Panel
          header="Cash Needed:"
          extra={<>{formatToMoney(state_cash_needed.full_cash_needed)}</>}
          key="1"
        >
          {state_cash_needed.rebate.is_show && <Rebate />}
          <CashNeededTable />
        </Panel>
      </Collapse>
    </div>
  );
};

export default CashNeeded;
