import "./ChartRechart.scss";

import PropTypes from "prop-types";
import React from "react";

const CustomizedPieLegend = (props) => {
  const { payload } = props;

  return (
    <>
      {payload[0] && (
        <div className="custom-legend">
          <ul>
            {payload.map((item, index) => {
              const i = index;
              return (
                <li key={i} style={{ color: `${item.color}` }}>
                  {item.value}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {!payload[0] && <div />}
    </>
  );
};

export default CustomizedPieLegend;

CustomizedPieLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.objectOf),
};

CustomizedPieLegend.defaultProps = {
  payload: [],
};
