/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./ComparisonTable.scss";

import { Button, Checkbox, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import base64 from "react-native-base64";
import { useDispatch, useSelector } from "react-redux";
import { RoughNotation } from "react-rough-notation";
import { useHistory } from "react-router-dom";

import { setAddValue } from "../../actions";
import configTFE from "../../configs/configTextForElements";
import DeleteMortgage from "../Mortgage/DeleteMortgage/DeleteMortgage";
import ShowNameMortgage from "../Mortgage/ShowNameMortgage";
import ShowOneMortgage from "../Mortgage/ShowOneMortgage/ShowOneMortgage";
import UploadMortgage from "../Mortgage/UploadMortgage/UploadMortgage";
import { showBtnComparison } from "./helper";

const IS_COMPARABLE = true;

const FullTable = () => {
  const dispatch = useDispatch();
  const { drawerBlock } = configTFE;
  const { Text } = Typography;
  const mortgage_items = useSelector((state) => state.mortgage_items);
  const [selected_checkboxes, setSelectedCheckboxes] = useState([]);
  const [flag_rough, setFlagRough] = useState(false);
  const [show_rough_notation, setShowRoughNotation] = useState(
    showBtnComparison(selected_checkboxes)
  );
  let show_rough_notation_timeout;

  useEffect(() => {
    clearTimeout(show_rough_notation_timeout);
    setShowRoughNotation(showBtnComparison(selected_checkboxes));
    show_rough_notation_timeout = setTimeout(() => {
      setShowRoughNotation(true);
    }, 7777);
  }, [flag_rough]);

  const history = useHistory();

  /**
   * returns string with couples key:value for URL
   * @param {array} basic - state.mortgage_items.
   * @param {array} condition - selected_checkboxes.
   * @param {setMortgageToUrl()} callback - set paramenetrs in URL after "?"
   */
  function prepareUrl(basic, condition) {
    const result = {};
    let num = 0;
    basic.reduce((str, __currentItem, index) => {
      const clear_data = _.omit(
        basic[index],
        "RESULT",
        "cash_needed",
        "description"
      );
      if (condition[index] === true) {
        result[num] = clear_data;
        num += 1;
      }
      return __currentItem;
    }, {});

    const json = JSON.stringify(result);
    const json64 = base64.encode(json);

    history.push(`/comparison/?${json64}`);
    dispatch(setAddValue({ name: "drawer", value: false }));
    setSelectedCheckboxes([]);
    ReactPixel.trackCustom("Compare mortgages");
  }

  /**
   * editing array with checked items from data.mortgage_items
   * @param {boolean} e - prop of checkbox
   * @param {integer} id - number of selected element from [data.mortgage_items]
   * @param {setSelectedCheckboxes()} callback - return changed list with selected items
   */
  function editArraySelect(e) {
    const selected_items = [...selected_checkboxes];
    selected_items[e.id] = e.checked;
    setSelectedCheckboxes(selected_items);
    setFlagRough(!flag_rough);
  }

  return (
    <div className="ComparisonTable">
      {IS_COMPARABLE && (
        <>
          <RoughNotation
            type="box"
            animationDuration={2000}
            iterations={5}
            color="red"
            show={!show_rough_notation}
          >
            <Button
              className={showBtnComparison(selected_checkboxes)}
              onClick={() => prepareUrl(mortgage_items, selected_checkboxes)}
              aria-label="Compare mortgages"
            >
              Compare mortgages
            </Button>
          </RoughNotation>
          <div>
            <Text>{drawerBlock.title5.text}</Text>
          </div>
        </>
      )}
      <table className="list-mortgages ant-table-content">
        <thead className="ant-table-thead">
          <tr>
            <th className="ant-table-cell"> </th>
            <th className="ant-table-cell">Name</th>
            <th className="ant-table-cell">Details</th>
            <th className="ant-table-cell">Upload</th>
            <th className="ant-table-cell">Delete</th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {mortgage_items.map((item, current) => {
            const k = current;
            return (
              <tr className="ant-table-row ant-table-row-level-0" key={k}>
                <td className="ant-table-cell">
                  <Checkbox
                    id={current}
                    onChange={(e) => editArraySelect(e.target)}
                    checked={selected_checkboxes[current]}
                  />
                </td>
                <td className="ant-table-cell">
                  <ShowNameMortgage id_mortgage={current} />
                </td>
                <td className="ant-table-cell">
                  <ShowOneMortgage id={current} />
                </td>
                <td className="ant-table-cell">
                  <UploadMortgage id_mortgage={current} />
                </td>
                <td className="ant-table-cell">
                  <DeleteMortgage id_mortgage={current} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FullTable;
