import config from "../../configs/configCards";

export const lawyer_fees = config.lawyerFees;

export const locationToUrl = (loc) => {
  const part_page = "/land-transfer-tax";
  const part_region = loc?.region ? `/${loc.region.replace(/\s/g, "")}` : "";
  const part_city = loc?.city ? `/${loc.city.replace(/\s/g, "")}` : "";
  return `${part_page}${part_region}${part_city}`;
};
