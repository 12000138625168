import "./Blog.scss";

import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";

import configBlog from "../../configs/configBlog";
import Post from "../BlogPost/Post";
import PostTitle from "../BlogPostTitle/PostTitle";
import GetMeta from "../GetMeta/GetMeta";
import { getImage } from "./helper";

const Blog = () => {
  const history = useHistory();
  const { authors, data_columns, data_rows } = configBlog;
  const wlh = window.location.href;
  const separator = wlh.lastIndexOf("/") === -1 ? 100 : wlh.lastIndexOf("/");
  const name_file = wlh.substr(separator + 1);
  const [loading, setLoading] = useState(true);
  // const [val_input, setValInput] = useState("");
  const val_input = "";

  const getDataRows = (input_data, search_txt) => {
    const output = [];
    Object.keys(input_data.rows).forEach((key) => {
      const file_destination = `/posts/${key}.md`;

      axios.get(file_destination).then((response) => {
        // setVvv(response.data.indexOf(search_txt));
        console.log(
          "response.data",
          file_destination,
          response.data.indexOf(search_txt)
        );
        const add_to_array = response.data.indexOf(search_txt);
        if (add_to_array > -1) {
          // console.log("add_to_array", add_to_array);
        }
        setLoading(false);
      });

      output.push({
        img: getImage(key),
        description: <PostTitle num_post={key} />,
        clickEvent: () => history.push(`blog/${key}`),
      });
    });

    return output;
  };

  const data_to_table = {
    columns: data_columns,
    rows: getDataRows({ rows: data_rows, authors }, val_input),
  };
  /* const onSearch = (el) => {
    setValInput(el.value);
  }; */

  return (
    <>
      {loading && (
        <div className="spinner-bg">
          <RingLoader loading={loading} size={150} />
        </div>
      )}
      <div className="Blog wrapper-div">
        <GetMeta page="blog" />
        {/* <Input
        placeholder="input search text"
        defaultValue={val_input}
        onChange={(e) => onSearch(e.target)}
        prefix={<FilterTwoTone />}
        style={{ width: 200 }}
      /> */}
        {name_file === "blog" && (
          <MDBDataTable
            paging={false}
            bordered
            small
            hover
            data={data_to_table}
            searching={false}
            entries={10}
            order={["date", "desc"]}
            className="list-posts"
          />
        )}
        {name_file !== "blog" && <Post file_name={name_file} />}
      </div>
    </>
  );
};

export default Blog;
