import "./Comparison.scss";

import React from "react";

import GetMeta from "../GetMeta/GetMeta";
import DefaultComparison from "./DefaultComparison";
import { checkIntegrityData } from "./helper";
import Mortgages from "./Mortgages";

const Comparison = () => {
  const wlh = window.location.href;
  const separator = wlh.lastIndexOf("?") === -1 ? 100 : wlh.lastIndexOf("?");
  const encode_str = wlh.substr(separator + 1);

  const data_is_right = checkIntegrityData(encode_str);
  return (
    <div className="Comparison wrapper-div site-card-wrapper">
      <GetMeta page="comparison" />
      {encode_str !== "" && data_is_right && (
        <Mortgages input_data={encode_str} />
      )}
      {encode_str !== "" && !data_is_right && <DefaultComparison wrongData />}
      {encode_str === "" && <DefaultComparison />}
    </div>
  );
};

export default Comparison;
