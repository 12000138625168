import { formatToMoney, isMobile } from "../../helpers/commonHelpers";

function getOneString(source_str, name_field) {
  return { [name_field]: Math.ceil(source_str[name_field]) };
}

export const scheduleForYears = (obj) => {
  const result = [];
  obj.reduce((__str, current_item, index) => {
    const i = index;
    if ((i + 1) % 12 === 0) {
      result.push(current_item);
    }
    return __str;
  }, []);
  return result;
};

export const getDataToRechart = (source, arr_field, type) => {
  const result = [];
  source.reduce((__str, current_item, index) => {
    const i = index;
    let start_obj = { name: i + 1 };
    arr_field.forEach((name_field) => {
      start_obj = Object.assign(
        start_obj,
        getOneString(current_item, name_field)
      );
    });
    result[i] = start_obj;
    return result[i];
  }, []);
  return { data: result, codes: arr_field, type };
};

export const scheduleSummaryForYears = (obj) => {
  const { payment1, payment2 } = obj;
  const result = [scheduleForYears(payment1), scheduleForYears(payment2)];
  return result;
};

export const getSummaryDataToRechart = (source, field, arr_field, type) => {
  const right_data = source.map((item) => {
    const one_set = item.map((el) => {
      return Math.ceil(el[field]);
    });
    return one_set;
  });

  let full_data = [];
  right_data[0].map((str, index) => {
    const j = index + 1;
    full_data = [...full_data, { name: j, mortgage_1: str }];
    return str;
  });

  right_data[1].map((str, index) => {
    const j = index + 1;
    full_data[j - 1] = { ...full_data[j - 1], mortgage_2: str };
    return str;
  });

  const result = { data: full_data, codes: arr_field, type };
  return result;
};

export const getNameSet = (arr, code) => {
  const output = arr.filter((el) => {
    return el.code === code;
  });
  return output[0].title;
};

export const getTypeChart = (bool) => {
  const result = bool ? "Bar" : "Line";
  return result;
};

export const getFormatMillion = (val) => {
  const result =
    val / 1000000 > 1 || val / 1000000 < -1
      ? `${formatToMoney(val / 1000000)}M`
      : `${formatToMoney(val / 1000)}K`;
  return result;
};

export const moneyView = (val) => {
  let result = 0;
  if (val !== 0) {
    result = isMobile() ? getFormatMillion(val) : formatToMoney(val);
  }
  return result;
};
