import "./PaymentTable.scss";

import { MDBDataTable } from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

import PaymentsSwitchers from "../PaymentsSwitchers/PaymentsSwitchers";

const PaymentTable = (props) => {
  const { data } = props;
  console.log("PaymentTable", data);
  const visual = { left: "Months", right: "Years" };
  return (
    <div className="payment-table wrapper">
      <PaymentsSwitchers name="payments_items" visual={visual} />
      <MDBDataTable
        striped
        bordered
        small
        hover
        data={data}
        searching={false}
        entriesOptions={[12, 24, 60, 120]}
        entries={12}
        order={["count", "asc"]}
      />
    </div>
  );
};

export default PaymentTable;

PaymentTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
};
