import "./PaymentsSwitchers.scss";

import { Switch } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { setGroup } from "../../actions";
import { useActualState } from "../../helpers/getStateValues";

const PaymentsSwitchers = (props) => {
  const dispatch = useDispatch();
  const { name, visual } = props;
  const { left, right } = visual;

  /** how show data: cards or table */
  function onChangeView(e, name_prop) {
    dispatch(setGroup({ group: "switchers", name: name_prop, value: e }));
  }

  const kind_of_view = useActualState("switchers")[name];
  return (
    <div className="switch-view-wrapper checkTablet">
      <div>{left}</div>
      <Switch
        className="switch-view"
        defaultChecked={kind_of_view}
        onChange={(e) => onChangeView(e, name)}
      />
      <div>{right}</div>
    </div>
  );
};

export default PaymentsSwitchers;

PaymentsSwitchers.propTypes = {
  name: PropTypes.string.isRequired,
  visual: PropTypes.objectOf(PropTypes.string).isRequired,
};
