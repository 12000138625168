import "../Mortgage.scss";

import { DeleteTwoTone } from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteMortgage } from "../../../actions";
import { getHumanMessage, getMessage, getMortgageName } from "../helpers";
import ModalTableLogic from "../ModalTableLogic";

const DeleteMortgage = (props) => {
  const { id_mortgage } = props;
  const mortgage_items = useSelector((state) => state.mortgage_items);
  const dispatch = useDispatch();

  const [visible, toggleVisible] = useState(false);

  /**
   * @see ant.modal
   * @param {string} callback
   */
  function showConfirm() {
    toggleVisible(!visible);
  }

  /**
   * @param {int} num - state.mortgage_items[num]
   * @param {deleteMortgage} callback - dispatch callback - set value state.mortgage_items.
   */
  function delMortgage(num) {
    dispatch(deleteMortgage(num));
    getMessage(getHumanMessage("deleteMortgage"), 2, "success");
    showConfirm();
  }

  return (
    <div className="Mortgage">
      <Modal
        className="mortgage-modal"
        title="Delete mortgage?"
        visible={visible}
        onOk={() => delMortgage(id_mortgage)}
        onCancel={() => showConfirm()}
        okText="YES"
        cancelText="NO"
      >
        <h3>{getMortgageName(mortgage_items[id_mortgage])}</h3>
        <div className="wrapper-table-modal">
          <ModalTableLogic id={id_mortgage} />
        </div>
        <Divider orientation="left">Description:</Divider>
        <div className="div-tip">
          <pre>{mortgage_items[id_mortgage].description[0]}</pre>
        </div>
      </Modal>
      <Button
        type="link"
        onClick={showConfirm}
        title="Delete"
        aria-label="Delete"
      >
        <DeleteTwoTone />
      </Button>
    </div>
  );
};

export default DeleteMortgage;

DeleteMortgage.propTypes = {
  id_mortgage: PropTypes.number.isRequired,
};
