import "./HeaderMenu.scss";

import {
  BarChartOutlined,
  CalculatorOutlined,
  DesktopOutlined,
  DollarOutlined,
  ExceptionOutlined,
  HeartTwoTone,
} from "@ant-design/icons";
import { Badge } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { setAddValue } from "../../actions";
import { formatToMoney, isMobile } from "../../helpers/commonHelpers";
import DrawerComponentLeft from "../DrawerComponentLeft/DrawerComponentLeft";

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const count_badge = useSelector((state) => state.mortgage_items.length);

  const total = useSelector((state) => state.state?.RESULT?.total);
  /**
   * toggle visible for Left Drawer
   * @param {boolean} val - flag
   * @param {toggleDrawer} callback - dispatch callback - set value state.state.drawer.
   */
  function setVisible(val) {
    dispatch(setAddValue({ name: "drawer", value: val }));
  }

  return (
    <div className="HeaderMenu">
      <div className="logo">
        <NavLink to="/" exact className="nav-link nav-link-logo" />
      </div>
      <div className="wrapper-menu">
        <nav className="wrapper-nav">
          <NavLink
            to="/"
            exact
            activeClassName="selectedLink"
            className="nav-link"
          >
            <CalculatorOutlined />
            <span className="navText">Calculator</span>
          </NavLink>
        </nav>
        <nav className="wrapper-nav">
          <NavLink
            to="/payments"
            exact
            activeClassName="selectedLink"
            className="nav-link"
          >
            <DollarOutlined />
            <span className="navText">Payments</span>
          </NavLink>
        </nav>
        <nav className="wrapper-nav">
          <NavLink
            to="/charts"
            exact
            activeClassName="selectedLink"
            className="nav-link"
          >
            <BarChartOutlined />
            <span className="navText">Charts</span>
          </NavLink>
        </nav>
        <nav className="wrapper-nav">
          <NavLink
            to="/blog"
            activeClassName="selectedLink"
            className="nav-link"
          >
            <ExceptionOutlined />
            <span className="navText">Blog</span>
          </NavLink>
        </nav>
        <nav className="wrapper-nav">
          <NavLink
            to="/overview"
            exact
            activeClassName="selectedLink"
            className="nav-link mobile watch-me"
          >
            {isMobile() ? (
              <span className="mobile navText">{formatToMoney(total)}</span>
            ) : (
              <DesktopOutlined />
            )}

            <span className="navText">Result</span>
          </NavLink>
        </nav>
        <nav className="wrapper-nav only-top">
          <NavLink
            to="/"
            onClick={(e) => {
              e.preventDefault();
              setVisible(true);
            }}
            className="nav-link"
          >
            <Badge count={count_badge} style={{ backgroundColor: "#52c41a" }}>
              <HeartTwoTone />
            </Badge>
            <span className="navText no-mobile">Saved</span>
          </NavLink>
        </nav>
      </div>
      <DrawerComponentLeft />
    </div>
  );
};

export default HeaderMenu;
