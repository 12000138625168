import "./CardComponent.scss";
import "../../scss/input.scss";

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, InputNumber, Modal, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { isMobile } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import { isCanada } from "../../helpers/helper";
import RatesDialog from "../RatesDialog/RatesDialog";
import { getCurrentRateBanks, getListBanks } from "../RatesList/helper";
import { useUserLocation } from "../SelectRegion/selectRegionService";
import { checkFieldIsMoney, returnFormatter } from "./helpers";
import ShowRange from "./ShowRange";
import ShowSlider from "./ShowSlider";

const CardInput = (props) => {
  const {
    value,
    name,
    id,
    prefix,
    suffix,
    min,
    max,
    step,
    onChange,
    onHandleChangeNumeric,
    onAfterChange,
    formattedData,
    show20,
  } = props;
  const location = useUserLocation();

  const [isEditMode, setEditMode] = useState(false);

  const marks = {
    [min]: formattedData(min, prefix, suffix),
    [max]: formattedData(max, prefix, suffix),
  };

  if (show20) {
    marks[20] = "20%";
  }

  function handleClick() {
    if (name === "mortgage_rate" && isCanada()) {
      Modal.info({
        content: <RatesDialog loc={location} />,
        okButtonProps: { style: { display: "none" } },
        className: "mortgage-rate",
        closable: true,
        maskClosable: true,
      });

      return;
    }
    setEditMode(true);
  }

  const onBlur = () => {
    setEditMode(false);
    onAfterChange(value);
  };

  const [textInput, setTextInput] = useState(null);
  const setTextInputRef = (element) => {
    setTextInput(element);
    if (textInput) {
      textInput.focus();
      textInput.position = 3;
    }
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onBlur();
    }
  };
  const handleFocus = (e) => {
    e.target.select();
  };

  const sw = useActualState("switchers");
  const { type_rates } = sw;
  const current_rate_bank = getCurrentRateBanks(
    value,
    getListBanks(type_rates)
  );

  const setInputValue = (sign, val, stp) => {
    const new_value = sign === "plus" ? val + stp : val - stp;
    //     console.log("idiwd ", min, new_value);
    if (new_value < min) {
      onAfterChange(min);
    } else if (new_value > max) {
      onAfterChange(max);
    } else {
      onAfterChange(new_value);
    }
  };

  const isDisabled = (boundary, val) => {
    const result = boundary === val ? "disabled" : "";
    return result;
  };

  const showActionDescription = (sign, val) => {
    const result = `${sign} ${formattedData(val, prefix, suffix)}`;
    return result;
  };
  return (
    <>
      <Card.Grid
        hoverable={false}
        className={`trick-input ${checkFieldIsMoney(prefix)} ${name}_alt`}
      >
        <div className={`input-elements-wrapper ${name}_alt`}>
          {!isEditMode && (
            <div
              className="span-holder span-value"
              onClick={() => handleClick()}
              onKeyPress={() => handleClick()}
              role="button"
              tabIndex="0"
            >
              <Button type="link" className={`cl-${id}`} aria-label="value">
                {returnFormatter(value, prefix, suffix)}
              </Button>
              {name === "mortgage_rate" && current_rate_bank.name && (
                <div className="list-images">
                  <a
                    href={current_rate_bank.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{current_rate_bank.name}</span>
                    <img
                      src={`/images/banks/${current_rate_bank.img}.png`}
                      alt={current_rate_bank.name}
                      title={current_rate_bank.name}
                      className="mini-img"
                    />
                  </a>
                </div>
              )}
            </div>
          )}
          {isEditMode && (
            <>
              {prefix && <div className="pre-suf prefix">{prefix}</div>}
              <InputNumber
                type="number"
                ref={setTextInputRef}
                // autoFocus
                inputmode="numeric"
                className={`be-input be-${name} ${
                  prefix.length > 0 ? "left-25" : ""
                }`}
                name={name}
                id={id}
                value={value}
                min={min}
                max={max}
                /* step="any" */
                /* step={step} */
                onFocus={handleFocus}
                onChange={onHandleChangeNumeric}
                onBlur={onBlur}
                onKeyDown={handleKeyPress}
              />
              {suffix && <div className="pre-suf suffix">{suffix}</div>}
            </>
          )}
        </div>
      </Card.Grid>
      <Card.Grid hoverable={false} style={{ paddingRight: 10 }}>
        {!isMobile() && name !== "mortgage_rate" && (
          <>
            <div className="set-value set-value-minus">
              <Tooltip title={showActionDescription("-", step)}>
                <Button
                  type="link"
                  className={isDisabled(min, value)}
                  onClick={() => setInputValue("minus", value, step)}
                  aria-label="minus"
                >
                  <MinusOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className="set-value set-value-plus">
              <Tooltip title={showActionDescription("+", step)}>
                <Button
                  type="link"
                  className={isDisabled(max, value)}
                  onClick={() => setInputValue("plus", value, step)}
                  aria-label="plus"
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
            </div>
            <ShowSlider
              tipFormatter={formattedData}
              onAfterChange={() => onAfterChange(value)}
              min={min}
              max={max}
              step={step}
              onChange={onChange}
              value={value}
              prefix={prefix}
              suffix={suffix}
            />
          </>
        )}
        {(isMobile() || name === "down_payment") && (
          <ShowRange
            tipFormatter={formattedData}
            min={min}
            max={max}
            onChange={onChange}
            prefix={prefix}
            suffix={suffix}
            name={name}
            mobile={isMobile()}
          />
        )}
      </Card.Grid>
    </>
  );
};

export default CardInput;

CardInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onHandleChangeNumeric: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  formattedData: PropTypes.func.isRequired,
  show20: PropTypes.bool,
};

CardInput.defaultProps = {
  id: "",
  prefix: "",
  suffix: "",
  show20: false,
};
