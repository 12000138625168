/* eslint-disable import/prefer-default-export */
import { FolderOpenTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import base64 from "react-native-base64";

import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import CreatePdf from "../CreatePdf/CreatePdf";
import NumericInput from "./NumericInput";

export const checkIntegrityData = (data) => {
  const decode_data = base64.decode(data);
  let result = true;
  try {
    JSON.parse(decode_data);
  } catch (e) {
    result = false;
  }
  return result;
};
/**
 *
 * @param {*} input_data
 * @param {*} index
 * @param {*} name_field
 * @param {*} toExecute
 */
export function returnInputElement(input_data, name_field, toExecute, index) {
  const original_data = input_data;
  const downpayment_money = formatToMoney(
    (original_data.property / 100) * original_data.down_payment
  );

  const switch_output = {
    property: () => (
      <NumericInput
        value={original_data.property}
        onChange={toExecute}
        name="property"
        prefix="$"
        index={index}
      />
    ),
    down_payment: () => (
      <>
        <NumericInput
          value={original_data.down_payment}
          onChange={toExecute}
          name="down_payment"
          suffix="%"
          index={index}
        />
        {downpayment_money}
      </>
    ),
    term_months: () => (
      <>
        <NumericInput
          value={original_data.amortization}
          onChange={toExecute}
          name="amortization"
          suffix="years"
          index={index}
        />
      </>
    ),
    goTo: () => (
      <Button
        type="link"
        onClick={() => toExecute(original_data)}
        aria-label="Upload"
      >
        <FolderOpenTwoTone /> Upload
      </Button>
    ),
    pdfAction: () => <CreatePdf data={original_data} />,
  };
  const output = switch_output[name_field]();
  return output;
}

export function formatMoney(val, suff) {
  const output =
    typeof suff === "string"
      ? addSuffix(formatToMoney(val), suff)
      : formatToMoney(val);
  return output;
}

export function lookForBigger(original_array, name_field) {
  let output = "";
  if (original_array[0][name_field] > original_array[1][name_field])
    output = "first";
  if (original_array[0][name_field] < original_array[1][name_field])
    output = "second";
  return output;
}
