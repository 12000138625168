import { FilePdfTwoTone } from "@ant-design/icons";
import { Button } from "antd";
// WE USE IT UNDIRECTLY!! DO NOT REMOVE!!
// eslint-disable-next-line no-unused-vars
import autoTable from "jspdf-autotable";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { RoughNotation } from "react-rough-notation";

import saveBodyPdf from "./saveBodyPdf";

const CreatePdf = (props) => {
  const { data } = props;
  const use_data = data || {};
  const [show_rough_notation, setShowRoughNotation] = useState(false);
  let show_rough_notation_timeout;

  function getPdf() {
    saveBodyPdf(use_data);
  }

  function showAnimation() {
    clearTimeout(show_rough_notation_timeout);
    setShowRoughNotation(true);
    show_rough_notation_timeout = setTimeout(() => {
      setShowRoughNotation(false);
    }, 3333);
  }

  return (
    <>
      <div className="btn-pdf several-buttons">
        <RoughNotation
          type="box"
          color="red"
          padding={10}
          show={show_rough_notation}
        >
          <Button
            type="link"
            onMouseOver={() => showAnimation()}
            onFocus={() => showAnimation()}
            onClick={() => getPdf()}
            aria-label="Download PDF"
          >
            <FilePdfTwoTone /> Download PDF
          </Button>
        </RoughNotation>
      </div>
    </>
  );
};

export default CreatePdf;

CreatePdf.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
};

CreatePdf.defaultProps = {
  data: {},
};
