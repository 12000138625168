// @ts-check
import "./SelectRegion.scss";

import { AimOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setCashNeeded } from "../../actions";
import { file_flag, getLocationLabel, getSelectedCountryFlag } from "./helper";
import { useUserLocation } from "./selectRegionService";
import TableWithRegions from "./TableWithRegions";

const SelectRegion = () => {
  const dispatch = useDispatch();
  const location = useUserLocation();
  const [is_modal_open, setIsModalOpen] = useState(false);

  function setNoLocation() {
    dispatch(setCashNeeded({ name: "location", value: {} }));
    setIsModalOpen(false);
  }
  const flag = getSelectedCountryFlag(location);

  return (
    <>
      <div className="SelectRegion">
        <div className="SelectRegion__label">
          {!location.code_name && <AimOutlined />}
          <Button
            type="link"
            className="SelectRegion__text"
            onClick={() => setIsModalOpen(true)}
            aria-label="Select Region"
          >
            <div className="show-location">
              {flag && (
                <img
                  alt=""
                  className="location-img"
                  src={`${file_flag}${flag}.png`}
                />
              )}
              {getLocationLabel(location)}
            </div>
          </Button>
          <DownOutlined onClick={() => setIsModalOpen(true)} />
        </div>
        {is_modal_open && (
          <Modal
            title="Select location:"
            // @ts-ignore
            autoFocusButton={false}
            visible={is_modal_open}
            okText="No Location"
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => setNoLocation()}
            onCancel={() => setIsModalOpen(false)}
          >
            <div className="location">
              <TableWithRegions onSelect={() => setIsModalOpen(false)} />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default SelectRegion;
