import configInsurance from "../../configs/configInsurance";
import { formatToMoney } from "../../helpers/commonHelpers";

export function getCmhcInsurance(property_value, down_payment_value) {
  let insurance_percent = 0;
  // todo use Strategy patern
  if (down_payment_value < 10) {
    insurance_percent = 4;
  } else if (down_payment_value < 15) {
    insurance_percent = 3.1;
  } else if (down_payment_value < 20) {
    insurance_percent = 2.8;
  }

  const delta = property_value - (property_value / 100) * down_payment_value;
  const insurance_value = (delta / 100) * insurance_percent;

  return {
    insurance_value,
    insurance_percent,
  };
}

export function convertPercentToMoney(property, down_payment, percent) {
  const delta = property - (property / 100) * down_payment;
  const output = formatToMoney((delta / 100) * percent);
  return output;
}

const { places } = configInsurance;

export const locationToUrl = (loc) => {
  let name_city = "";
  if (loc.city && loc.city === "Vancouver") name_city = "Vancouver";
  if (loc.city && loc.city !== "Vancouver")
    name_city = places[loc.city]?.url_name;

  const part_page = "/insurance";
  const url_region = `/${places[loc.code_name]?.url_name}`;
  const url_city = loc.city ? `/${name_city}` : "";
  const result = `${part_page}${url_region}${url_city}`;

  return result;
};
