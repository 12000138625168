import "./RatesList.scss";

import { Button, InputNumber, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setValue } from "../../actions";
import { useActualState } from "../../helpers/getStateValues";
import { getListBanks } from "./helper";

const RatesList = () => {
  const dispatch = useDispatch();
  const switchers = useActualState("switchers");
  const { type_rates } = switchers;

  const actual_list = getListBanks(type_rates);

  const value_mortgage_rate = useActualState("mortgage_rate");
  const [val_input, setValInput] = useState(value_mortgage_rate);

  const doSelectRate = (nameF, val) => {
    dispatch(setValue(nameF, val));
    Modal.destroyAll();
  };

  /**
   * Change value if value isNumber
   */
  function onHandleChangeNumeric(inputValue) {
    const inputValueParsed = Math.abs(parseFloat(inputValue));
    if (!Number(inputValueParsed)) {
      return;
    }
    setValInput(inputValueParsed);
  }

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className="rates-list">
      <table className="table rates">
        <thead>
          <tr>
            <td colSpan={2}>Provider</td>
            <td>Rate</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {actual_list.map((entry, index) => {
            const i = index;
            return (
              <tr key={i}>
                <td>
                  <a
                    href={entry.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`/images/banks/${entry.img}.png`}
                      alt={entry.name}
                      title={entry.name}
                      className="mini-img"
                    />
                  </a>
                </td>
                <td>
                  <a
                    href={entry.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {`${entry.name}  >`}
                  </a>
                </td>
                <td className="rate-value">{`${entry.rate} %`}</td>
                <td>
                  <Button
                    title="Select"
                    onClick={() => doSelectRate("mortgage_rate", entry.rate)}
                    className="user-select-item"
                    aria-label="Select"
                  >
                    Select
                  </Button>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2}>Custom rate</td>
            <td className="custom-rate">
              <InputNumber
                type="number"
                onFocus={handleFocus}
                inputmode="numeric"
                value={val_input}
                min={0.01}
                autoFocus
                max={12}
                step={0.01}
                onChange={onHandleChangeNumeric}
              />
              <div>&nbsp;%</div>
            </td>
            <td>
              <Button
                title="Select"
                id="user-choice"
                onClick={() => doSelectRate("mortgage_rate", val_input)}
                className="user-select-item"
                aria-label="Select"
              >
                Select
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RatesList;
