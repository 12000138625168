import "./ChartCashNeeded.scss";

import _ from "lodash";
import React, { useState } from "react";
import ResizeDetector from "react-resize-detector";

import configChart from "../../configs/configChart";
import { useActualState } from "../../helpers/getStateValues";
import ChartRechart from "../ChartRechart/ChartRechart";

const ChartCashNeeded = () => {
  const parentRef = React.createRef();
  const state_cash_needed = useActualState("cash_needed");
  const { name_cash_needed_fields } = configChart;
  const necessary_cash_needed = _.omit(
    state_cash_needed,
    "rebate",
    "full_cash_needed"
  );

  const getRightFormatDataNecessaryCashNeeded = (obj, lib) => {
    const result = Object.entries(obj).reduce((current, item, index) => {
      const i = index;
      const human_name = lib.filter((el) => {
        return el.code === item[0];
      });

      current.push({ name: human_name[0].title, value: item[1], dataKey: i });
      return current;
    }, []);
    return result;
  };

  const [width_rechart, setWidthRechart] = useState(0);

  const data_to_rechart = {
    data: getRightFormatDataNecessaryCashNeeded(
      necessary_cash_needed,
      name_cash_needed_fields
    ),
    type: "Pie",
  };

  function onResize(width) {
    setWidthRechart(width);
  }

  return (
    <div className="Charts">
      <div className="wrapper-charts">
        <div ref={parentRef} className="wrapper-rechart">
          <ChartRechart width={width_rechart} data={data_to_rechart} />
        </div>
      </div>

      <ResizeDetector
        handleWidth
        handleHeight
        onResize={onResize}
        targetDomEl={parentRef.current}
      />
    </div>
  );
};

export default ChartCashNeeded;
