/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import React from "react";

import list_of_supported_locations from "../../configs/list_of_supported_locations";

export const file_flag = "../../../../images/flags/";

/**
 *
 * @param {MLocation} location
 * @returns one of the supported Locations from listOfSupportedLocations list
 */
export function getSupportedLocation(location) {
  const { city, region } = location;
  const location_by_city = _.find(list_of_supported_locations, { city });
  if (location_by_city) {
    return location_by_city;
  }

  // no city, but region
  const location_by_region = _.find(
    list_of_supported_locations.filter((l) => !l.city),
    {
      region,
    }
  );
  if (location_by_region) {
    return location_by_region;
  }
  return {};
}

/**
 *
 * @param {SupportedLocation} location
 * @returns label to display
 */
export function getLocationLabel(location) {
  const { city, code_name, region, country } = location;
  if (city && code_name) {
    // "Toronto, ON"
    return `${country}, ${city}, ${code_name}`;
  }
  if (region) {
    return `${country}, ${region}`;
  }
  return "Unknown location";
}

export function getCountryFlag(country) {
  const src_file = `${file_flag}${country}.png`;
  return (
    <>
      <img alt="" className="location-img" src={src_file} />
      <span className="cntry-name">{country}</span>
    </>
  );
}

export function getSelectedCountryFlag(location) {
  const res = location?.code_name
    ? location?.country?.toLocaleLowerCase()
    : null;
  return res;
}

export function isRebate(current_place, list_places) {
  const output = { flag: false, place: "" };
  const region = current_place.region
    ? current_place.region.replace(/\s/g, "")
    : "";
  const city = current_place.city ? current_place.city.replace(/\s/g, "") : "";
  if (list_places[region]) {
    output.flag = true;
    output.place = region;
    if (list_places[city]) output.place = city;
  }
  return output;
}
