// @ts-check
import "./RightPart.scss";

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const InvestmentCompResult = (props) => {
  const { payment } = props;
  const critical_points = [];

  payment.payment_schedule.forEach((el, idx) => {
    const prev_renter_net_worth =
      idx > 0
        ? payment.payment_schedule[idx - 1].renter_net_worth
        : payment.initial_renter_net_worth;

    const prev_landlord_net_worth =
      idx > 0
        ? payment.payment_schedule[idx - 1].landlord_net_worth
        : payment.initial_landlord_net_worth;

    const prev_diff = prev_renter_net_worth < prev_landlord_net_worth;
    const current_diff = el.renter_net_worth < el.landlord_net_worth;
    if (idx > 1 && prev_diff !== current_diff) {
      const tmpEl = { ...el };
      tmpEl.criticalPointLandlordIsNowBetter = current_diff;
      // eslint-disable-next-line no-param-reassign
      if (!payment.test) payment.test = [];
      critical_points.push(tmpEl);
    }
  });
  const lastEl = payment.payment_schedule[payment.payment_schedule.length - 1];
  payment.final_landlord_net_worth = lastEl.landlord_net_worth;
  payment.final_renter_net_worth = lastEl.renter_net_worth;

  let text = "";
  if (!critical_points.length) {
    if (payment.final_landlord_net_worth > payment.final_renter_net_worth) {
      text += `Buying is better for you.\nThe Owner's Net Worth will be always bigger than Renter's Net Worth.`;
    } else {
      text += `Renting is better for you.\nThe Renter's Net Worth will be always bigger than Owner's Net Worth.
      However, keep in mind that we are not including capital gain taxes here.`;
    }
  } else if (critical_points.length === 1) {
    const el = critical_points[0];
    if (payment.final_landlord_net_worth > payment.final_renter_net_worth) {
      text += `Eventually, buying will be better for you.\nThe Owner's Net Worth will be bigger after ${
        el.count
      } months or ~${Math.round(el.count / 12)} years.`;
    } else {
      text += `Eventually, renting will be better for you.\nThe Renter's Net Worth will be bigger after ${
        el.count
      } months or ~${Math.round(el.count / 12)} years.`;
    }
  } else {
    if (payment.final_landlord_net_worth > payment.final_renter_net_worth) {
      text += `Eventually, buying will be better for you.`;
    } else {
      text += `Eventually, renting will be better for you.`;
    }
    text += `\n but it's an interesting situation:\n`;
    const el = critical_points[0];
    const first = el.criticalPointLandlordIsNowBetter ? "Renter" : "Owner";
    const second = el.criticalPointLandlordIsNowBetter ? "Owner" : "Renter";
    const el2 = critical_points[1];

    text += `First ${el.count} months or~${Math.round(
      el.count / 12
    )} years it will be better to be a ${first}.`;
    text += `\n However, than it will be better to be a ${second} but only till  ${
      el2.count
    } months or~${Math.round(el2.count / 12)}
      when again ${first} Net Worth will become larger.`;
  }
  return (
    <div className="summary-advice">
      {text.split("\n").map((i, idx) => {
        const current = idx;
        return <p key={current}>{i}</p>;
      })}

      <p>
        For details, please look on the chart below or go to the{" "}
        <Link to="/payments">payments section.</Link>
      </p>
    </div>
  );
};

export default InvestmentCompResult;

InvestmentCompResult.propTypes = {
  payment: PropTypes.objectOf(PropTypes.any).isRequired,
};
