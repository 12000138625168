/* eslint-disable import/prefer-default-export */
export const isLinkFromFacebook = (str) => {
  const new_array = str.split("=");
  return new_array[0] === "fbclid";
};

export const isLinkFromRealtorCa = (str) => {
  const step_1 = str.split("&");
  const changed_values = {};
  step_1.map((item) => {
    const step_2 = item.split("=");
    changed_values[step_2[0]] = parseInt(step_2[1], 10)
      ? parseInt(step_2[1], 10)
      : step_2[1];
    return 1;
  });

  return changed_values;
};
