import "./PaymentOneRow.scss";

import PropTypes from "prop-types";
import React from "react";

const ModalWindow = (props) => {
  const { comparison, data } = props;
  console.log("ModalWindow - names: ", comparison, data);

  return (
    <div className="wrapper-table-modal">
      <table className="modal-data-table">
        <tbody>
          <tr>
            <td>Interest Payment</td>
            <td>{data.interest_payment}</td>
          </tr>
          <tr>
            <td>Total Interest</td>
            <td>{data.total_interest}</td>
          </tr>
          <tr>
            <td>Principal Payment</td>
            <td>{data.principal_payment}</td>
          </tr>
          <tr>
            <td>Total Payment</td>
            <td>{data.total_payment}</td>
          </tr>
          <tr>
            <td>Total Payments</td>
            <td>{data.total_payments}</td>
          </tr>
          <tr>
            <td>Balance</td>
            <td>{data.balance}</td>
          </tr>
          {comparison && (
            <>
              <tr>
                <td>Owner&apos;s Net Worth</td>
                <td>{data.landlord_net_worth}</td>
              </tr>
              <tr>
                <td>Renter&apos;s Net Worth</td>
                <td>{data.renter_net_worth}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ModalWindow;

ModalWindow.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  comparison: PropTypes.number.isRequired,
};
