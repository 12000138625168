import "./Comparison.scss";

import { CopyTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import base64 from "react-native-base64";
import { useDispatch } from "react-redux";
import ResizeDetector from "react-resize-detector";
import { Redirect } from "react-router-dom";

import { uploadMortgage } from "../../actions";
import configChart from "../../configs/configChart";
import { getClearData } from "../../helpers/commonHelpers";
import getLogic from "../../logic";
import ChartRechart from "../ChartRechart/ChartRechart";
import {
  getNameSet,
  getSummaryDataToRechart,
  scheduleSummaryForYears,
} from "../ChartRechart/helpers";
import { copyLinkMortgage, copyLinkTwoMortgages } from "../CopyLink/helper";
import {
  formatLocationData,
  getHumanMessage,
  getMessage,
} from "../Mortgage/helpers";
import OneRow from "./OneRow";
import OneRowStatic from "./OneRowStatic";

const Mortgages = (props) => {
  const { input_data } = props;
  const { name_fields } = configChart;
  const parentRef = React.createRef();

  const decode_data = base64.decode(input_data);
  const full_data = JSON.parse(decode_data);

  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);

  const [mortgages_on_page, setMortagesOnPage] = useState(full_data);
  const [width_rechart, setWidthRechart] = useState(0);

  const payment1 = getLogic(mortgages_on_page[0]);
  const payment2 = getLogic(mortgages_on_page[1]);
  const mortgages_array = [payment1, payment2];
  const is_show_insurance =
    payment1.mortgage_insurance + payment2.mortgage_insurance;
  const data_to_pdf = { ...mortgages_on_page };
  data_to_pdf[0].RESULT = payment1;
  data_to_pdf[1].RESULT = payment2;

  function onResize(width) {
    setWidthRechart(width);
  }

  function dataForPayment(values) {
    dispatch(uploadMortgage(values));
    setNavigate(true);
  }

  function changeMortgage(obj) {
    const { index, name, new_value } = obj;
    const new_obj = { ...mortgages_on_page };
    new_obj[index][name] = new_value;

    setMortagesOnPage(new_obj);
  }

  const data_to_rechart = getSummaryDataToRechart(
    scheduleSummaryForYears({
      payment1: payment1.payment_schedule,
      payment2: payment2.payment_schedule,
    }),
    "total_payments",
    ["mortgage_1", "mortgage_2"],
    "Line"
  );

  const flagImg1 = mortgages_on_page?.[0]?.location?.country?.toLocaleLowerCase();
  const flagImg2 = mortgages_on_page?.[1]?.location?.country?.toLocaleLowerCase();

  return (
    <>
      <div className="Mortgages ant-table">
        {navigate ? <Redirect to="" /> : null}
        <div className="center-position">
          <CopyToClipboard
            text={copyLinkTwoMortgages([
              getClearData(mortgages_on_page[0]),
              getClearData(mortgages_on_page[1]),
            ])}
            onCopy={() => getMessage(getHumanMessage("copyLink"), 2, "success")}
          >
            <div className="ant-btn create-link ant-btn-primary">
              copy Mortgages
            </div>
          </CopyToClipboard>
        </div>
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>Mortgage 1</th>
              <th>Mortgage 2</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr>
              <th>
                <img
                  alt=""
                  className="location-img"
                  src={`../../../../images/flags/${flagImg1}.png`}
                />
                {formatLocationData(mortgages_on_page[0].location)}
              </th>
              <th>
                <img
                  alt=""
                  className="location-img"
                  src={`../../../../images/flags/${flagImg2}.png`}
                />
                {formatLocationData(mortgages_on_page[1].location)}
              </th>
            </tr>
            <OneRow
              input_data={mortgages_on_page}
              name_field="goTo"
              func={dataForPayment}
            />
            <tr className="ant-table-row">
              <td className="ant-table-cell several-buttons">
                <CopyToClipboard
                  text={copyLinkMortgage(getClearData(mortgages_on_page[0]))}
                  onCopy={() =>
                    getMessage(getHumanMessage("copyLink"), 2, "success")
                  }
                >
                  <Button
                    type="link"
                    icon={<CopyTwoTone />}
                    aria-label="Copy Link"
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </td>
              <td className="ant-table-cell several-buttons">
                <CopyToClipboard
                  text={copyLinkMortgage(getClearData(mortgages_on_page[1]))}
                  onCopy={() =>
                    getMessage(getHumanMessage("copyLink"), 2, "success")
                  }
                >
                  <Button
                    type="link"
                    icon={<CopyTwoTone />}
                    aria-label="Copy Link"
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </td>
            </tr>
            <OneRow
              input_data={mortgages_on_page}
              name_field="property"
              func={changeMortgage}
              title="Property Value"
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="down_payment"
              func={changeMortgage}
              title="Down Payment"
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="term_months"
              func={changeMortgage}
              title="Term Years"
            />
            <tr className="ant-table-row">
              <td colSpan={2} className="ant-table-cell description">
                <div>{getNameSet(name_fields, "total_payments")}</div>
                <div ref={parentRef} className="wrapper-rechart">
                  <ChartRechart
                    width={width_rechart * 0.95}
                    data={data_to_rechart}
                  />
                </div>
              </td>
            </tr>
            <OneRowStatic
              input_data={mortgages_array}
              name_field="loan_amount"
              title="Loan Amount"
            />
            {/* <OneRowStatic
              input_data={mortgages_array}
              name_field="principal_and_interest"
              title="Principal And Interest"
            /> */}
            {is_show_insurance > 0 && (
              <OneRowStatic
                input_data={mortgages_array}
                name_field="mortgage_insurance"
                title="Mortgage Insurance"
              />
            )}
            <OneRowStatic
              input_data={mortgages_array}
              name_field="total"
              title="Monthly Payment"
            />
            <OneRow
              input_data={data_to_pdf}
              name_field="pdfAction"
              func={changeMortgage}
            />
          </tbody>
        </table>
        <ResizeDetector
          handleWidth
          handleHeight
          onResize={onResize}
          targetDomEl={parentRef.current}
        />
      </div>
    </>
  );
};

export default Mortgages;

Mortgages.propTypes = {
  input_data: PropTypes.string.isRequired,
};
