import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import getLogic from "../../logic";
import ModalTable from "./ModalTable";

const ModalTableLogic = (props) => {
  // id === -1, if we add new mortgage (save item)
  const { data, id } = props;
  const mortgage_items = useSelector((state) => state.mortgage_items);

  const source = id === -1 ? data : mortgage_items[id];

  const props_result = source.RESULT ? source.RESULT : getLogic(source);

  const right_values = {
    property: source.property,
    down_payment_proc: source.down_payment,
    down_payment_money: (source.property * source.down_payment) / 100,
    mortgage_rate: source.mortgage_rate,
    monthly_payment: props_result.principal_and_interest,
    monthly_total:
      props_result.principal_and_interest + props_result.m_monthly_expenses,
    location: source.location,
    amortization: source.amortization,
  };

  return <ModalTable data={right_values} />;
};

export default ModalTableLogic;

ModalTableLogic.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

ModalTableLogic.defaultProps = {
  data: {},
};
