import "./RebatePageTable.scss";

import { Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

import configRebate from "../../configs/configRebate";
import { formatToMoney } from "../../helpers/commonHelpers";

const RebatePageTable = (props) => {
  const { data } = props;
  const { description } = configRebate;

  const history = useHistory();
  const goHome = () => {
    history.replace("/");
  };
  const tlt = `<p><span class="red-name">${data.name}</span> - Land Transfer Tax Rebate</p>`;

  return (
    <Modal
      title={ReactHtmlParser(tlt)}
      visible
      onOk={goHome}
      onCancel={goHome}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <>
        <table className="table">
          <tbody>
            <tr>
              <td>{description.maximum}</td>
              <td>{formatToMoney(data.maximum)}</td>
            </tr>
            <tr>
              <td>{description.cover}</td>
              <td>{formatToMoney(data.cover)}</td>
            </tr>
            <tr>
              <td>{description.refund}</td>
              <td>{data.refund}</td>
            </tr>
          </tbody>
        </table>
        {data.tip && <div>{data.tip.txt}</div>}
      </>
    </Modal>
  );
};

export default RebatePageTable;

RebatePageTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
