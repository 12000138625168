import "../ChartRechart.scss";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";

import configChart from "../../../configs/configChart";
import CustomizedPieLegend from "../CustomizedPieLegend";
import renderActiveShape from "./renderActiveShape";

const ChartPie = (props) => {
  const { data, width } = props;
  const { rechart_colors } = configChart;
  const [active_index, setActiveIndex] = useState(0);
  function onPieEnter(__data, index) {
    setActiveIndex(index);
  }

  return (
    <div className="chart-pie">
      <PieChart width={width} height={width}>
        <Pie
          dataKey="value"
          activeIndex={active_index}
          activeShape={renderActiveShape}
          data={data}
          cx={width / 2}
          cy={width / 4.7}
          innerRadius={width / 7}
          outerRadius={width / 5.3}
          fill={rechart_colors[active_index]}
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => {
            const i = index;
            return (
              <Cell
                key={i}
                fill={rechart_colors[index % rechart_colors.length]}
              />
            );
          })}
        </Pie>
        <Legend verticalAlign="top" content={<CustomizedPieLegend />} />
      </PieChart>
    </div>
  );
};

export default ChartPie;

ChartPie.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};
