/**
 * @typedef {Object} SupportedLocation
 * @property {string} code_name - code_name
 * @property {string} region - region
 * @property {string} city - city
 */
const list_of_supported_locations_usa = [
  { code_name: "AL", region: "Alabama" },
  { code_name: "AK", region: "Alaska" },
  { code_name: "AZ", region: "Arizona" },
  { code_name: "AR", region: "Arkansas" },
  { code_name: "CA", region: "California" },
  { code_name: "CO", region: "Colorado" },
  { code_name: "CT", region: "Connecticut" },
  { code_name: "DE", region: "Delaware" },
  { code_name: "DC", region: "District of Columbia" },
  { code_name: "FL", region: "Florida" },
  { code_name: "GA", region: "Georgia" },
  { code_name: "HI", region: "Hawaii" },
  { code_name: "ID", region: "Idaho" },
  { code_name: "IL", region: "Illinois" },
  { code_name: "IN", region: "Indiana" },
  { code_name: "IA", region: "Iowa" },
  { code_name: "KS", region: "Kansas" },
  { code_name: "KY", region: "Kentucky" },
  { code_name: "LA", region: "Louisiana" },
  { code_name: "ME", region: "Maine" },
  { code_name: "MD", region: "Maryland" },
  { code_name: "MA", region: "Massachusetts" },
  { code_name: "MI", region: "Michigan" },
  { code_name: "MN", region: "Minnesota" },
  { code_name: "MS", region: "Mississippi" },
  { code_name: "MO", region: "Missouri" },
  { code_name: "MT", region: "Montana" },
  { code_name: "NE", region: "Nebraska" },
  { code_name: "NV", region: "Nevada" },
  { code_name: "NH", region: "New Hampshire" },
  { code_name: "NJ", region: "New Jersey" },
  { code_name: "NM", region: "New Mexico" },
  { code_name: "NY", region: "New York" },
  { code_name: "NC", region: "North Carolina" },
  { code_name: "ND", region: "North Dakota" },
  { code_name: "OH", region: "Ohio" },
  { code_name: "OK", region: "Oklahoma" },
  { code_name: "OR", region: "Oregon" },
  { code_name: "PA", region: "Pennsylvania" },
  { code_name: "RI", region: "Rhode Island" },
  { code_name: "SC", region: "South Carolina" },
  { code_name: "SD", region: "South Dakota" },
  { code_name: "TN", region: "Tennessee" },
  { code_name: "TX", region: "Texas" },
  { code_name: "UT", region: "Utah" },
  { code_name: "VT", region: "Vermont" },
  { code_name: "VA", region: "Virginia" },
  { code_name: "WA", region: "Washington" },
  { code_name: "WV", region: "West Virginia" },
  { code_name: "WI", region: "Wisconsin" },
  { code_name: "WY", region: "Wyoming" },
];

export default list_of_supported_locations_usa;
