import { DELETE_MORTGAGE } from "./types";

export default function addMortgage(num) {
  return {
    type: DELETE_MORTGAGE,
    payload: {
      num,
    },
  };
}
