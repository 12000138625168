/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./ComparisonTable.scss";

import React from "react";
import { useSelector } from "react-redux";

import EmptyTable from "./EmptyTable";
import FullTable from "./FullTable";

const ComparisonTable = () => {
  const mortgage_items = useSelector((state) => state.mortgage_items);
  return (
    <div className="ComparisonTable">
      {mortgage_items.length !== 0 && <FullTable />}
      {mortgage_items.length === 0 && <EmptyTable />}
    </div>
  );
};

export default ComparisonTable;
