import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { setValue } from "../../actions";
import config from "../../configs/configCards";
import { useActualState } from "../../helpers/getStateValues";
import { initialStateWithLimits } from "../../reducers/baseReducer";
import CardComponent from "./CardComponent";
import { getMinDownPayment } from "./helpers";

const CardComponentLogic = (props) => {
  const { name } = props;
  const { title, text, prefix, suffix } = config[name];
  const value = useActualState(name);
  const property = useActualState("property");

  const min =
    name === "down_payment"
      ? getMinDownPayment(property).min
      : initialStateWithLimits[name].min;

  const { max, step } = initialStateWithLimits[name];
  const dispatch = useDispatch();

  if (name === "down_payment") {
    // console.log(min, max, step);
  }

  return (
    <CardComponent
      title={title}
      text={text}
      name={name}
      prefix={prefix}
      suffix={suffix}
      step={step}
      min={min}
      max={max}
      val={value}
      onChange={(v) => dispatch(setValue(name, v))}
    />
  );
};

export default CardComponentLogic;

CardComponentLogic.propTypes = {
  name: PropTypes.string.isRequired,
};
