import "./Charts.scss";

import React from "react";

import ChartCashNeeded from "../ChartCashNeeded/ChartCashNeeded";
import ChartPayments from "../ChartPayments/ChartPayments";
import GetMeta from "../GetMeta/GetMeta";

const Charts = () => {
  return (
    <div className="Charts">
      <GetMeta page="charts" />
      <br />
      <h3 className="table-description">Cash Needed</h3>
      <ChartCashNeeded />
      <ChartPayments />
    </div>
  );
};

export default Charts;
