import "./Comparison.scss";

import PropTypes from "prop-types";
import React from "react";

import { returnInputElement } from "./helper";

const OneRow = (props) => {
  const { input_data, name_field, func, title } = props;
  return (
    <>
      {title !== "" && (
        <tr className="ant-table-row">
          <td className="ant-table-cell description" colSpan={2}>
            {title}
          </td>
        </tr>
      )}
      <tr className="ant-table-row">
        <td className="ant-table-cell folder-open">
          {returnInputElement(input_data[0], name_field, func, 0)}
        </td>
        <td className="ant-table-cell folder-open">
          {returnInputElement(input_data[1], name_field, func, 1)}
        </td>
      </tr>
    </>
  );
};

export default OneRow;

OneRow.propTypes = {
  input_data: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
  func: PropTypes.func.isRequired,
  name_field: PropTypes.string.isRequired,
  title: PropTypes.string,
};

OneRow.defaultProps = {
  title: "",
};
