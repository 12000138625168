/* eslint-disable no-undef */
import "./PostTitle.scss";

import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import configBlog from "../../configs/configBlog";
import AuthorImg from "../BlogAuthor/AuthorImg";
// import Categories from "./Categories";
import { getDate } from "./helper";

const PostTitle = (props) => {
  const { num_post } = props;
  const { data_rows } = configBlog;
  const file_title_destination = `/posts/${num_post}_title.md`;
  const [markdown_title, setMarkdownTitle] = useState("");

  const getMarkdownTitle = (file) => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setMarkdownTitle(text));
  };
  getMarkdownTitle(file_title_destination);

  return (
    <div className="post-title">
      <h2>{data_rows[num_post].title}</h2>
      <div>
        Created: {getDate(data_rows[num_post].created)}{" "}
        <AuthorImg file_name={num_post} />
      </div>
      {/* <Categories num_post={num_post} /> */}
      <ReactMarkdown plugins={[gfm]} source={`${markdown_title} ...`} />
      <div>
        <strong>Read more</strong>
      </div>
    </div>
  );
};

export default PostTitle;

PostTitle.propTypes = {
  num_post: PropTypes.string.isRequired,
};
