import "./ComparisonTable.scss";

import React, { useState } from "react";
import { RoughNotation } from "react-rough-notation";

import NoDataSvg from "../../helpers/noDataSvg";
import AddMortgage from "../Mortgage/AddMortgage/AddMortgage";

const EmptyTable = () => {
  const [show_rough_notation, setShowRoughNotation] = useState(false);
  let show_rough_notation_timeout;
  function showAnimation() {
    clearTimeout(show_rough_notation_timeout);
    setShowRoughNotation(true);
    show_rough_notation_timeout = setTimeout(() => {
      setShowRoughNotation(false);
    }, 3333);
  }

  return (
    <div className="empty-table">
      <div className="bold-400">
        Here you can compare 2 diffrent mortgages from saved.
      </div>
      <div className="ant-empty-image">
        <NoDataSvg />
        <p className="ant-empty-description">
          But now you do not have any saved mortgage.
        </p>
      </div>
      <div>
        You can{" "}
        <RoughNotation
          type="box"
          color="red"
          padding={3}
          show={show_rough_notation}
        >
          <span
            onMouseOver={() => showAnimation()}
            onFocus={() => showAnimation()}
          >
            <AddMortgage />
          </span>
        </RoughNotation>
        the current mortgage now.
      </div>
    </div>
  );
};

export default EmptyTable;
