/* eslint-disable import/prefer-default-export */
import config from "../../configs/configCards";
import {
  addPrefix,
  addSuffix,
  formatToMoney,
} from "../../helpers/commonHelpers";

/**
 * @param {object} state - state.state.name
 * @param {string} name - state.state.name
 * @returns {string} returned string
 */
export const getFullDescription = (state, name) => {
  return addPrefix(config[name].prefix, addSuffix(state, config[name].suffix));
};

/**
 * @param {integer|float} val - state.state
 * @returns {string} returned string
 */
export const getPerMonth = (val) => {
  return addSuffix(formatToMoney(val), " per month");
};
