/* eslint-disable no-undef */
import "./Post.scss";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import gfm from "remark-gfm";
import headingid from "remark-heading-id";

import configBlog from "../../configs/configBlog";
import { getImage } from "../Blog/helper";
import AuthorDetails from "../BlogAuthor/AuthorDetails";
import AuthorImg from "../BlogAuthor/AuthorImg";
import { getDate } from "../BlogPostTitle/helper";
// import GetMeta from "../GetMeta/GetMeta";

const Post = (props) => {
  const { file_name } = props;
  const is_facebook_link = file_name.split("?");
  const new_file_name = is_facebook_link[0];
  const separator =
    new_file_name.lastIndexOf("#") === -1
      ? 100
      : new_file_name.lastIndexOf("#");
  const num_post = decodeURI(new_file_name.substr(0, separator));
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const file_destination = `/posts/${num_post}.md`;
  // const wrong_file_destination = `/posts/wrongName.md`;
  const [markdown, setMarkdown] = useState("");
  const getMarkdown = (file) => {
    axios.get(file).then((response) => {
      setMarkdown(response.data);
      setLoading(false);
    });
  };
  getMarkdown(file_destination);
  return (
    <>
      {loading && (
        <div className="spinner-bg">
          <RingLoader loading={loading} size={150} />
        </div>
      )}
      <div className="Post wrapper-div">
        <Button
          className="backBtn"
          onClick={() => history.push("/blog")}
          type="link"
          icon={<ArrowLeftOutlined />}
          aria-label="back"
        >
          <section className="waves-wrapper">
            {/* For animations: className="waves" */}
            <div data-word="Back to All Posts">Back to All Posts</div>
          </section>
        </Button>
        {/* TODO <GetMeta /> */}
        <hr />
        <h1 className="title-h1">{configBlog.data_rows[num_post].title}</h1>
        <hr />
        <div className="front-image">{getImage(num_post, 1)}</div>
        <div className="markdown-text">
          <ReactMarkdown plugins={[gfm, headingid]} source={markdown} />
        </div>
        <hr />
        <div className="signature">
          <i>Created at: </i>
          {getDate(configBlog.data_rows[num_post].created)}
          <i> by </i>
          <Popover
            content={
              <AuthorDetails
                id_author={configBlog.data_rows[num_post].author}
              />
            }
            title={
              configBlog.authors[configBlog.data_rows[num_post].author].name
            }
            overlayClassName="author-details"
          >
            <AuthorImg file_name={new_file_name} />
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Post;

Post.propTypes = {
  file_name: PropTypes.string.isRequired,
};
