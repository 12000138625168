import "./SocialButtons.scss";

import React from "react";

const SocialButtons = () => {
  return (
    <div className="social-buttons">
      <div className="one-button">
        <a
          href="https://www.facebook.com/my.mortgagio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../../../../images/social/facebook.png"
            alt="Facebook"
            title="Facebook"
          />
        </a>
      </div>
      <div className="one-button">
        <a
          href="https://www.instagram.com/mortgagio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../../../../images/social/instagram.png"
            alt="Instagram"
            title="Instagram"
          />
        </a>
      </div>
    </div>
  );
};

export default SocialButtons;
