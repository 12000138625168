/* eslint-disable import/prefer-default-export */
import JsPDF from "jspdf";

import configChart from "../../configs/configChart";
import configPayments from "../../configs/configPayments";
import configTextForElements from "../../configs/configTextForElements";
import {
  getColumnsForPdf,
  getPaymentSheduleForPdf,
} from "../../helpers/paymentHelpers";
import getCashNeeded from "../../logicCashNeeded";
import store from "../../store";
import { formatLocationData } from "../Mortgage/helpers";
import dataimage from "./dataimage";
import { getCashNeededTable, getSummaryTable } from "./helper";

const { pdfBlock } = configTextForElements;
const { logo } = dataimage;

const saveBodyPdf = (data) => {
  const data_from_state = store.getState().state;

  const data_state = data.rent ? data : data_from_state;

  const value_cash_needed = data_state.cash_needed
    ? data_state.cash_needed
    : getCashNeeded(data_state);
  const state_result = data_state.RESULT;
  const location_state = data_state.location;

  // const copy_state = getClearData(data_state);
  // const link_to_page = copyLinkMortgage(copy_state);
  const { name_cash_needed_fields } = configChart;
  const { paymentSummary } = configPayments;

  const { payment_schedule } = state_result;

  const doc = new JsPDF("p", "mm");
  // const firstLink = { rectX: 15, rectY: 180, rectW: 180, rectH: 45 }; // ad banner
  const logoLink = { rectX: 100, rectY: 0, rectW: 60, rectH: 24 };

  const columns_cash_needed = [["Description", "Value"]]; // Cash Needed Table start - first page
  const table_cn_body = getCashNeededTable(
    value_cash_needed,
    name_cash_needed_fields
  );

  doc.autoTable({
    didDrawPage() {
      doc.setTextColor(150);
      doc.setFontSize(14);
      // add header link- text
      doc.textWithLink(pdfBlock.headerText, 40, 14, {
        url: pdfBlock.headerText,
      });
      // add header link- banner
      doc.link(logoLink.rectX, logoLink.rectY, logoLink.rectW, logoLink.rectH, {
        url: pdfBlock.headerText,
      });
      doc.addImage(
        logo[0],
        "PNG",
        logoLink.rectX,
        logoLink.rectY,
        logoLink.rectW,
        logoLink.rectH
      );
      // add table's caption + location
      doc.setTextColor(50, 50, 50);
      doc.setFontSize(14);
      doc.text(`Cash needed (${formatLocationData(location_state)}):`, 20, 35);

      // add summary table's caption
      doc.setTextColor(50, 50, 50);
      doc.setFontSize(14);
      doc.text("Summary data:", 20, 105);
      doc.setTextColor(0, 0, 255);
      /* doc.textWithLink(pdfBlock.link, 20, 190, {
        url: link_to_page
      }); */
    },
    head: columns_cash_needed,
    body: table_cn_body,
    theme: "grid",
    startY: 40,
    columnStyles: {
      1: {
        cellWidth: 70,
      },
    },
  }); // Cash Needed Table end

  // Summary Table start - first page
  const table_summary_body = getSummaryTable(data_state, paymentSummary);
  doc.autoTable({
    head: columns_cash_needed,
    body: table_summary_body,
    theme: "grid",
    startY: 110,
    columnStyles: {
      1: {
        cellWidth: 70,
      },
    },
  }); // Summary Table end

  doc.addPage("a4", "p"); // add second page

  // Payments Shedule Table start
  const table_shedule_body = getPaymentSheduleForPdf(payment_schedule); // getSummaryTable(summary, paymentSummary);
  const table_shedule_columns = getColumnsForPdf(
    configPayments.generalData.columns,
    data_state.rent_or_buy
  );

  doc.autoTable({
    didDrawPage() {
      doc.setTextColor(150);
      doc.setFontSize(14);
      // add header link- text
      doc.textWithLink(pdfBlock.headerText, 40, 14, {
        url: pdfBlock.headerText,
      });
      // add header link- banner
      doc.link(logoLink.rectX, logoLink.rectY, logoLink.rectW, logoLink.rectH, {
        url: pdfBlock.headerText,
      });
      doc.addImage(
        logo[0],
        "PNG",
        logoLink.rectX,
        logoLink.rectY,
        logoLink.rectW,
        logoLink.rectH
      );
    },
    head: table_shedule_columns,
    body: table_shedule_body,
    theme: "grid",
    margin: { top: 26 },
    startY: 26,
    styles: {
      fontSize: 8,
    },
    columnStyles: {
      0: {
        cellWidth: 25,
      },
    },
  }); // Payments Shedule Table end

  doc.save(pdfBlock.nameFile);
};

export default saveBodyPdf;
