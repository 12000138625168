import "../Mortgage.scss";
import "./ShowCheckbox.scss";

import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { useActualState } from "../../../helpers/getStateValues";

const ShowCheckbox = (props) => {
  const selected_checkboxes = useActualState("rebate");
  const { returnIdCheck, name } = props;
  return (
    <div className="wrapper-checkbox">
      <Checkbox
        checked={selected_checkboxes[name]}
        name={name}
        onChange={(e) => returnIdCheck(e.target)}
      />
    </div>
  );
};

export default ShowCheckbox;

ShowCheckbox.propTypes = {
  returnIdCheck: PropTypes.func.isRequired,
  name: PropTypes.string,
};

ShowCheckbox.defaultProps = {
  name: "",
};
