import _ from "lodash";
import React, { useState } from "react";
import base64 from "react-native-base64";
import { useDispatch } from "react-redux";

import { setCashNeeded, setValue } from "../../actions";
import configCanadaMeta from "../../configs/configMeta/canadaMeta";
import configUSAMeta from "../../configs/configMeta/usaMeta";
import list_of_supported_locations from "../../configs/list_of_supported_locations";
import { isLinkFromFacebook, isLinkFromRealtorCa } from "./helper";

const CheckUrl = () => {
  const dispatch = useDispatch();
  const [marker, setMarker] = useState(true);
  const separator_question = window.location.href.indexOf("?");
  const data_from_url =
    separator_question === -1
      ? ""
      : window.location.href.substr(separator_question + 1);

  const getRegion = (abbr) => {
    const res = list_of_supported_locations.filter(
      (e) => e.code_name.indexOf(abbr) > -1
    );
    return res[0].region;
  };

  /**
   * @param {string} data  - base64-string. Created from state, state.location(rebate)
   * @param {setLocation, setRebate, setValue} callback - dispatch callback - set value state.state.
   * @param marker - execute CheckURL once
   */
  function checkUrl(data) {
    const is_facebook_link = isLinkFromFacebook(data);
    const is_rca_link = isLinkFromRealtorCa(data);
    if (!is_facebook_link && !is_rca_link.rca) {
      const get_decode_data = base64.decode(data);

      let get_object = "";
      try {
        get_object = JSON.parse(get_decode_data);
      } catch (e) {
        console.log("checkUrl - wrong data");
      }

      if (get_object.location) {
        const location_to_state = get_object.location;
        const rebate_to_state = get_object.rebate;
        const data_to_state = _.omit(get_object, "location", "rebate");

        if (marker) {
          Object.entries(data_to_state).forEach(([key, value]) => {
            dispatch(setValue(key, parseFloat(value)));
          });
          dispatch(
            setCashNeeded({ name: "location", value: location_to_state })
          );
          dispatch(setCashNeeded({ name: "rebate", value: rebate_to_state }));
          setMarker(false);
        }
      }
    }
    if (is_rca_link.rca) {
      if (is_rca_link.property) {
        const new_v = is_rca_link.property || 50000;
        dispatch(setValue("property", parseFloat(new_v)));
      }
      if (is_rca_link.cn) {
        const new_location = is_rca_link.city
          ? {
              code_name: is_rca_link.cn,
              city: is_rca_link.city,
              region: getRegion(is_rca_link.cn),
            }
          : { code_name: is_rca_link.cn, region: getRegion(is_rca_link.cn) };
        dispatch(setValue("location", new_location));
        dispatch(
          setCashNeeded({
            name: "location",
            value: new_location,
          })
        );
      }
      if (is_rca_link.location) {
        dispatch(setValue("location", {}));
        dispatch(
          setCashNeeded({
            name: "location",
            value: {},
          })
        );
      }
    }
  }

  const urlWithProvinces = () => {
    //  if url looks as `https://mortgagio.com/USA/Indiana` set state.location
    const url_location = window.location.href;
    const array_url = url_location.split("/").slice(3);
    const is_canada = array_url.filter((cntry) => cntry === "Canada");
    const is_usa = array_url.filter((cntry) => cntry === "USA");
    let current_country = 0;
    if (is_canada.length > 0 && is_usa.length === 0) current_country = 1;
    if (is_canada.length === 0 && is_usa.length > 0) current_country = 2;

    if (current_country > 0) {
      const right_config =
        current_country === 1 ? configCanadaMeta : configUSAMeta;
      const new_location = array_url.filter((item) => right_config[item]);

      // right_config[new_location[new_location.length - 1]]
      // get last name from http://mortgagio.com/USA/Indiana/Ohio/Tennessee
      if (right_config[new_location[new_location.length - 1]]) {
        const full_location =
          right_config[new_location[new_location.length - 1]];
        full_location.country = right_config.country;
        dispatch(
          setCashNeeded({
            name: "location",
            value: full_location,
          })
        );
      } else {
        //  if url has incorrect name  like `https://mortgagio.com/USA/IndianaPol` set state.location to {}
        dispatch(setCashNeeded({ name: "location", value: {} }));
      }
    } else {
      // dispatch(setCashNeeded({ name: "location", value: {} }));
    }
  };

  return (
    <>
      {data_from_url && checkUrl(data_from_url)}
      {!data_from_url && urlWithProvinces()}
    </>
  );
};

export default CheckUrl;
