import "../InsurancePage/InsurancePage.scss";

import React from "react";

import configInsurance from "../../configs/configInsurance";

const InsurancePageFull = () => {
  const { table_columns, table_data } = configInsurance;
  return (
    <div className="insurance-page-full">
      <table className="table">
        <thead>
          <tr>
            {table_columns.map((obj, index) => {
              const i = index;
              return <th key={i}>{obj.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {table_data.map((obj, index) => {
            const i = index;
            return (
              <tr key={i}>
                <td>{obj.downpayment}</td>
                <td>{obj.insurance}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InsurancePageFull;
