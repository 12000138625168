/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
import {
  SET_ADD_VALUE,
  SET_CASH_NEEDED,
  SET_GROUP,
  SET_VALUE,
  UPLOAD_MORTGAGE,
} from "../actions/types";
import getLogic from "../logic";
import getCashNeeded from "../logicCashNeeded";

const isPercent = true;
export const initialStateWithLimits = {
  // todo how to make it beautiful looking?
  rent: { val: 2250, min: 100, max: 15000, step: 50 },
  rent_annual_increase: {
    val: 2.2,
    min: 0,
    max: 5,
    step: 0.1,
    isPercent,
  },
  property: { val: 429000, min: 50000, max: 4000000, step: 10000 },
  down_payment: { val: 5, min: 0, max: 50, step: 0.1, isPercent },
  amortization: { val: 25, min: 5, max: 30, step: 1 },
  mortgage_rate: { val: 1.69, min: 0.01, max: 12, step: 0.01 },
  amount_annual_taxes: {
    val: 0.61477,
    min: 0,
    max: 5,
    step: 0.05,
    isPercent,
  },
  annual_heating_costs: { val: 500, min: 0, max: 25000, step: 10 },
  another_monthly_expenses: { val: 250, min: 0, max: 25000, step: 10 },
  buying_home: { val: 2, min: 0, max: 10, step: 0.1, isPercent },
  selling_home: { val: 5, min: 0, max: 10, step: 0.1, isPercent },
  maintenance: { val: 1, min: 0, max: 5, step: 0.1, isPercent },
  owner_insurance: { val: 0.1, min: 0, max: 2, step: 0.1, isPercent },
  renters_insurance: { val: 50, min: 0, max: 4000, step: 50 },
  rate_of_growth: { val: 2, min: 0, max: 20, step: 0.1, isPercent },
  return_investment: { val: 4, min: 0, max: 20, step: 0.1, isPercent },
  rent_or_buy: 1,
  collapses: { cash_needed: [], calculator_1: ["1"], calculator_2: ["2"] },
  location: { country: "Canada" },
  rebate: { first_time: false, newly_built: false },
  drawer: false,
  cash_needed: {},
  charts: ["balance"],
  switchers: {
    payments_wrapper: false,
    payments_items: false,
    type_charts: false,
    type_rates: false,
  },
  RESULT: {},
};

// get initialState from more general initialStateWithLimits
// we are using only values in initialState cause min and max are constants
// todo use MIN MAX STEP instead of min max step
const initialState = Object.keys(initialStateWithLimits)
  .map((key) => {
    const value = initialStateWithLimits[key];
    const newValue = value.val ? value.val : value;
    return { key, newValue };
  })
  .reduce((accumulator, currentValue) => {
    accumulator[currentValue.key] = currentValue.newValue;
    return accumulator;
  }, {});

initialState.RESULT = getLogic(initialState);
initialState.cash_needed = getCashNeeded(initialState);

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_VALUE:
      const { name, value } = action.payload;
      let val =
        typeof action.payload.value === "boolean"
          ? value
          : parseFloat(parseFloat(action.payload.value).toFixed(2));
      const newState = { ...state };
      // console.log(typeof(initialStateWithLimits[name]))
      const { min, max } =
        typeof initialStateWithLimits[name] === "object"
          ? initialStateWithLimits[name]
          : false;
      if (min && min > value) {
        val = min;
      }
      if (max && max < value) {
        val = max;
      }
      newState[name] = val;
      const copy_state = { ...newState };
      newState.RESULT = getLogic(copy_state);
      newState.cash_needed = getCashNeeded(copy_state);
      return newState;

    case SET_GROUP:
      // console.log("SET_GROUP: ", action.payload);
      const new_state_group = { ...state };
      const group_name = action.payload.group;
      const el_name = action.payload.name;
      const el_value = action.payload.value;
      new_state_group[group_name][el_name] = el_value;
      return new_state_group;

    case SET_ADD_VALUE:
      // console.log("SET_ADD_VALUE: ", action.payload);
      const state_add_value = { ...state };
      const element_name = action.payload.name;
      const element_value = action.payload.value;
      state_add_value[element_name] = element_value;
      return state_add_value;

    case SET_CASH_NEEDED:
      const state_cash_needed = { ...state };
      const cn_name = action.payload.name;
      const cn_value = action.payload.value;
      state_cash_needed[cn_name] = cn_value;
      state_cash_needed.RESULT = getLogic(state_cash_needed);
      state_cash_needed.cash_needed = getCashNeeded(state_cash_needed);
      return state_cash_needed;

    case UPLOAD_MORTGAGE: // todo
      const new_state = { ...initialState };
      // console.log("UPLOAD_MORTGAGE: ", action.payload.obj);
      const upload_mortgage = action.payload.obj;
      Object.keys(upload_mortgage).forEach(function rk(key) {
        new_state[key] = upload_mortgage[key];
      });
      return new_state;
    default:
      return state;
  }
};
