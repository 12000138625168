const configCanadaMeta = {
  title: "Mortgage Calculator, Rent or Buy comparison.",
  description_1: "The best Mortgage Calculator for ",
  description_2: "Helps you make right decision.",
  keywords: "mortgage, calculator, rent, best rate",
  common_phrase: "The Best For ",
  country: "Canada",
  Montreal: {
    city: "Montreal",
    code_name: "QB",
    region: "Quebec",
  },
  Toronto: {
    city: "Toronto",
    code_name: "ON",
    region: "Ontario",
  },
  Vancouver: {
    city: "Vancouver",
    code_name: "BC",
    region: "British Columbia",
  },
  Alberta: { code_name: "AB", region: "Alberta", country: "Canada" },
  BritishColumbia: {
    code_name: "BC",
    region: "British Columbia",
  },
  Manitoba: { code_name: "MB", region: "Manitoba", country: "Canada" },
  NewBrunswick: {
    code_name: "NB",
    region: "New Brunswick",
  },
  NewfoundlandAndLabrador: {
    code_name: "NL",
    region: "Newfoundland and Labrador",
  },
  NovaScotia: { code_name: "NS", region: "Nova Scotia", country: "Canada" },
  Ontario: { code_name: "ON", region: "Ontario", country: "Canada" },
  PrinceEdwardIsland: {
    code_name: "PE",
    region: "Prince Edward Island",
  },
  Saskatchewan: {
    code_name: "SK",
    region: "Saskatchewan",
  },
  Quebec: { code_name: "QB", region: "Quebec" },
};

export default configCanadaMeta;
