/**
 * @typedef {Object} MLocation
 * @property {string} country - country
 * @property {string} code_name - country
 * @property {string} region - region
 * @property {string=} city - city
 */

import axios from "axios";
import _ from "lodash";
import { useSelector } from "react-redux";

import { setCashNeeded } from "../../actions";
import configTokens from "../../configs/configTokens";
import store from "../../store";
import { getSupportedLocation } from "./helper";

const { ipinfoIo } = configTokens;
const LOCATION_CHECK_URL = `https://ipinfo.io?token=${ipinfoIo}`;
export const NO_LOCATION_URL = "Location is not selected";

let asked = false;
function initLocalization() {
  const { location } = store.getState().state;
  if (_.isEmpty(location) && !asked) {
    asked = true;
    axios.get(LOCATION_CHECK_URL).then((response) => {
      if (response) {
        const data = _.get(response, "data", {});
        const { country, region, city } = data;
        /**
         * @type {MLocation}
         */
        const new_location = {
          country,
          region,
          city,
        };

        const supported_location = getSupportedLocation(new_location);
        store.dispatch(
          setCashNeeded({ name: "location", value: supported_location })
        );
      }
    });
  } else {
    // console.log("location was already called");
  }
}

/**
 * @returns {MLocation}
 */
export function useUserLocation() {
  const location = useSelector((state) => state.state.location);
  initLocalization();

  return location;
}
