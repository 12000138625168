import "./SelectRegion.scss";

import { AimOutlined } from "@ant-design/icons";
import { Button, Input, Switch } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useDispatch } from "react-redux";

import { setCashNeeded } from "../../actions";
import list_of_supported_locations from "../../configs/list_of_supported_locations";
import list_of_supported_locations_usa from "../../configs/list_of_supported_locations_usa";
import { getCountryFlag, getLocationLabel } from "./helper";
import { useUserLocation } from "./selectRegionService";

const TableWithRegions = ({ onSelect }) => {
  const [search, setSearch] = useState("");
  const location = useUserLocation();
  const selected_country = location?.country === "USA";
  const [switcher_country, setSwitcherCountry] = useState(selected_country);

  const dispatch = useDispatch();
  function changeSearch(event) {
    setSearch(event.target.value);
  }

  function handlerClick(loc, kind_country) {
    const country_name = kind_country ? "USA" : "Canada";
    const full_location = Object.assign(loc, { country: country_name });
    dispatch(setCashNeeded({ name: "location", value: full_location }));
    if (_.isFunction(onSelect)) {
      onSelect(loc);
    }
    ReactPixel.trackCustom("Change Location", {
      select_location: loc,
    });
  }
  const current_country_states =
    switcher_country === true
      ? list_of_supported_locations_usa
      : list_of_supported_locations;
  // todo can be better
  const filtered_list = current_country_states.filter((l) => {
    const values = Object.values(l);
    return !!values.find(
      (v) => v && v.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  });

  const setSwitcher = (bool_val) => {
    setSwitcherCountry(bool_val);
  };

  return (
    <>
      <div className="select-country">
        <Button type="text" onClick={() => setSwitcher(false)}>
          {getCountryFlag("Canada")}
        </Button>
        <Switch
          className="switch-view"
          defaultChecked={switcher_country}
          checked={switcher_country}
          onChange={(e) => setSwitcherCountry(e)}
        />
        <Button type="text" onClick={() => setSwitcher(true)}>
          {getCountryFlag("USA")}
        </Button>
      </div>
      <Input
        id="search-region"
        autoFocus
        size="large"
        onChange={changeSearch}
        value={search}
        prefix={<AimOutlined />}
      />
      <div className="table-wrapper">
        <table>
          <tbody>
            {filtered_list.map((list_states) => {
              const label = getLocationLabel(list_states);
              const label2 = label.split(",");
              return (
                <tr
                  key={label}
                  onClick={() => handlerClick(list_states, switcher_country)}
                >
                  <td className="td-list-modal">{label2[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableWithRegions;

TableWithRegions.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
