import "./CashNeeded.scss";

import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { NavLink } from "react-router-dom";

import { formatToMoney } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import { isCanada } from "../../helpers/helper";
import { useUserLocation } from "../SelectRegion/selectRegionService";
import { locationToUrl } from "./helpers";

const CashNeededTable = () => {
  const location = useUserLocation();
  const state_cash_needed = useActualState("cash_needed");

  return (
    <table className="list-mortgages ant-table-content">
      <tbody className="ant-table-tbody">
        <tr className="ant-table-row">
          <td className="ant-table-cell">Down payment</td>
          <td className="ant-table-cell">
            {formatToMoney(state_cash_needed.down_payment_cash)}
          </td>
        </tr>
        {isCanada() && (
          <tr className="ant-table-row">
            <td className="ant-table-cell">PST on mortgage insurance</td>
            <td className="ant-table-cell">
              {formatToMoney(state_cash_needed.pst_insurance)}
            </td>
          </tr>
        )}
        {isCanada() && (
          <tr className="ant-table-row">
            <td className="ant-table-cell">
              Land Transfer Tax
              <div className="question">
                <NavLink to={locationToUrl(location)} exact>
                  <QuestionCircleOutlined />
                </NavLink>
              </div>
            </td>

            <td className="ant-table-cell">
              {location.code_name === "NS" && "~"}
              {formatToMoney(state_cash_needed.land_transfer_tax)}
            </td>
          </tr>
        )}
        <tr className="ant-table-row">
          <td className="ant-table-cell">Lawyer fees</td>
          <td className="ant-table-cell">
            ~ {formatToMoney(state_cash_needed.lawyer_fees)}
          </td>
        </tr>
        <tr className="ant-table-row">
          <td className="ant-table-cell">Title insurance</td>
          <td className="ant-table-cell">
            ~ {formatToMoney(state_cash_needed.title_insurance)}
          </td>
        </tr>
        <tr className="ant-table-row">
          <td className="ant-table-cell">Total Cash Required</td>
          <td className="ant-table-cell">
            {formatToMoney(state_cash_needed.full_cash_needed)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CashNeededTable;
