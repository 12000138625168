const configBlog = {
  data_columns: [
    { field: "img", label: "Img", sort: "disabled" },
    { field: "description", label: "Description", sort: "disabled" },
  ],
  authors: {
    1: {
      name: "Luke Nelson",
      age: 32,
      position: "Real Estate Agent",
      location: "Vancouver, BC",
    },
    2: {
      name: "Chloe Peterson",
      age: 36,
      position: "Mortgage Advisor",
      location: "Toronto, ON",
    },
    3: {
      name: "Vincent Moore",
      age: 51,
      position: "Mortgage Broker",
      location: "Montreal, QB",
    },
  },
  categories: {
    1: { name: "credit cards", color: "#f50" },
    2: { name: "mortgages", color: "#2db7f5" },
    3: { name: "personal finance", color: "#108ee9" },
  },
  data_rows: {
    "Rent-or-Buy-a-home": {
      title: "Rent or Buy a home",
      categories: [2],
      author: 1,
      created: "02/24/2021",
    },
    "TFSA-Limits-2021": {
      title: "TFSA Limit 2021",
      categories: [3],
      author: 2,
      created: "02/08/2021",
    },
    "Toronto-2021-Renting-Could-Be-Better-Than-Buying": {
      title: "Toronto 2021 renting could be better than buying",
      categories: [2],
      author: 2,
      created: "01/20/2021",
    },
    "rent-or-buy": {
      title: "Rent or Buy: what is better for you?",
      categories: [2],
      author: 1,
      created: "01/14/2021",
    },
    /* 20210104: {
      title: "Post width images and different data",
      categories: [1, 2, 3],
      author: 3,
    },
    20210403: {
      title:
        "Post width lists and different data. Very long title and very long description. We want to see how it looks",
      categories: [1, 3],
      author: 2,
    }, */
  },
};

export default configBlog;
