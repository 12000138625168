const DEFAULT_PRICE = 500000;
const DEFAULT_DOWN_PAYMENT = 100000;
const DEFAULT_INTERESR_RATE = 0.05;
const DEFAULT_MONTHS = 360;
const DEFAULT_ADDITIONAL_PRINCIPAL_PAYMENT = 0;
const DEFAULT_RATE_OF_GROWTH = 0;

// TODO tests + refactoring
class MortgageCalculator {
  constructor() {
    this.total_price = DEFAULT_PRICE;
    this.down_payment = DEFAULT_DOWN_PAYMENT;
    this.interest_rate = DEFAULT_INTERESR_RATE;
    this.months = DEFAULT_MONTHS;
    this.additional_principal_payment = DEFAULT_ADDITIONAL_PRINCIPAL_PAYMENT;
    this.rate_of_growth = DEFAULT_RATE_OF_GROWTH;
  }

  calculatePayment() {
    const loan_amount = this.total_price - this.down_payment;
    const payment_schedule = MortgageCalculator.calculatePaymentSchedule(
      loan_amount,
      this.interest_rate,
      this.months,
      this.additional_principal_payment
    );
    const pi_payment = payment_schedule.length
      ? payment_schedule[0].total_payment
      : 0;
    const down_payment_percentage = this.down_payment / this.total_price;
    let mortgage_insurance = 0;
    if (
      this.mortgage_insurance_enabled &&
      down_payment_percentage < this.mortgage_insurance_threshold
    ) {
      mortgage_insurance = (loan_amount * this.mortgage_insurance_rate) / 12;
    }

    return {
      loan_amount,
      principal_and_interest: pi_payment,
      total: pi_payment,
      // term_months: this.months,
      payment_schedule,
      mortgage_insurance,
    };
  }

  static calculatePaymentSchedule(
    loan_amount,
    annual_rate,
    term_months,
    additional_principal_payments = 100
  ) {
    const monthly_rate = annual_rate / 12;
    const monthly_payment = MortgageCalculator.calculateMonthlyPIPayment(
      loan_amount,
      annual_rate,
      term_months
    );
    let principal = MortgageCalculator.roundPenny(loan_amount);
    const payments = [];
    let total_interest = 0;
    let total_payments = 0;
    let i = 0;
    while (principal > 0 && i < term_months) {
      const interest_payment = MortgageCalculator.roundPenny(
        principal * monthly_rate
      );
      let principal_payment = MortgageCalculator.roundPenny(
        monthly_payment - interest_payment + additional_principal_payments
      );
      if (principal > principal_payment) {
        principal = MortgageCalculator.roundPenny(
          principal - principal_payment
        );
      } else {
        principal_payment = principal;
        principal = 0;
      }
      const total_payment = interest_payment + principal_payment;
      total_interest += interest_payment;
      total_payments += total_payment;
      payments[i] = {
        count: i + 1,
        interest_payment,
        total_interest,
        principal_payment,
        total_payment,
        total_payments,
        balance: principal,
      };
      i += 1;
    }

    return payments;
  }

  static calculateMonthlyPIPayment(loan_amount, annual_rate, term_months) {
    const monthly_rate = annual_rate / 12;
    const payment =
      (monthly_rate * loan_amount * (1 + monthly_rate) ** term_months) /
      ((1 + monthly_rate) ** term_months - 1);
    return this.nextPenny(payment);
  }

  static roundPenny(value) {
    return Math.round(value * 100) / 100;
  }

  static nextPenny(value) {
    return Math.ceil(value * 100) / 100;
  }
}

export default function getMortgageCalculator() {
  const mortgageCalculator = new MortgageCalculator();
  mortgageCalculator.setValue = (name, value) => {
    mortgageCalculator[name] = value;
    return mortgageCalculator;
  };
  return mortgageCalculator;
}
