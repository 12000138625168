const configTextForElements = {
  siteName: "http://mortgagio.com",
  switchBlock: {
    values: ["light", "dark"],
  },
  buttons: {
    commonButtons: {
      helper: { text: "helper" },
      mortgage: { text: "mortgage" },
      up: { text: "UP" },
    },
  },
  divElements: {
    titles: {
      title1: { text: "General data" },
      title2: { text: "Mortgage details" },
      title3: { text: "Taxes and basic costs" },
      title4: { text: "Closing costs" },
      title5: { text: "Maintenance and Taxes" },
      title6: { text: "Property rate of growth" }, // "Market trends"
    },
    summaryBlock: {
      div0: { text: "monthly payments!" },
      div1: { text: "rent" },
      div2: { text: "buy" },
      div3: { text: "Monthly payment" },
      div4: { text: "Monthly property expenses" },
      div5: { text: "Monthly  savings" },
      div6: { text: "total" },
      divEmpty: { text: "" },
    },
  },
  pdfBlock: {
    btn: "Create PDF",
    nameFile: "mortrgagio.pdf",
    headerText: "https://mortgagio.com/",
    link: "Link to site with current values",
  },
  copyLinkBlock: {
    btn: "Copy link",
    title: "Success!",
  },
  wrongMessage: "Probably, You use wrong data.",
  drawerBlock: {
    title1: { text: "Copy Link" },
    title2: { text: "Change Theme" },
    title3: { text: "Select Region" },
    title4: { text: "Saved mortgages" },
    title5: { text: "for comparison, please select 2 items" },
  },
  messsageText: {
    addMortgage: "Mortgage saved successfully.",
    uploadMortgage: "Mortgage upload successfully.",
    deleteMortgage: "Mortgage deleted successfully.",
    errorAddMortgage:
      "Mortgage can't be saved. You already have 10 saved mortgages.",
    copyLink:
      "Link was copied to Clipboard. Use Ctrl+V to share Link in your favorite messenger.",
    copyLinkError: "Link wasn't copied to Clipboard.",
  },
};

export default configTextForElements;
