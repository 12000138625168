/* eslint-disable no-undef */
import "./RatesDialog.scss";

import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";

import store from "../../store";
import PaymentsSwitchers from "../PaymentsSwitchers/PaymentsSwitchers";
import RatesList from "../RatesList/RatesList";
import { getLocationLabel } from "../SelectRegion/helper";

const RatesDialog = (props) => {
  const { loc } = props;
  const visual = { left: "Fixed", right: "Variable" };

  return (
    <div className="rates">
      <div className="rates-title">
        Best Mortgage Rates
        {loc.code_name && <>&nbsp;for {getLocationLabel(loc)}</>}
      </div>
      <Provider store={store}>
        <PaymentsSwitchers name="type_rates" visual={visual} />
        <RatesList />
      </Provider>
    </div>
  );
};

export default RatesDialog;

RatesDialog.propTypes = {
  loc: PropTypes.objectOf(PropTypes.any).isRequired,
};
