import { CopyTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import ReactPixel from "react-facebook-pixel";

import { getClearData } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import { getHumanMessage, getMessage } from "../Mortgage/helpers";
import { copyURL } from "./helper";

const CopyLink = () => {
  const data = useActualState();
  const copy_url_to_clipboard = getClearData(data);

  const copyToClipBoard = async (copyMe) => {
    console.log("copyToClipBoard");
    try {
      await navigator.clipboard.writeText(copyMe);
      getMessage(getHumanMessage("copyLink"), 2, "success");
      ReactPixel.trackCustom("Click 'Copy Link'", {
        result: "success",
      });
    } catch (err) {
      getMessage(getHumanMessage("copyLinkError"), 2, "error");
      ReactPixel.trackCustom("Click 'Copy Link'", {
        result: "error",
      });
    }
  };

  return (
    <Button
      type="link"
      icon={<CopyTwoTone />}
      onClick={() => copyToClipBoard(copyURL(copy_url_to_clipboard))}
    >
      Copy Link
    </Button>
  );
};

export default CopyLink;
