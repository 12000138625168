import returnMoneyValue from "./components/CardComponent/returnMoneyValue";
import { getCmhcInsurance } from "./components/Insurance/helpers";
import analizeForInvestment from "./lib/investmentComparasion";
import getMortgageCalculator from "./lib/mortgage";

/**
 * get input data {object} with necessary params for library `MortgageCalculator`.
 * Return result {object} from `MortgageCalculator`. Data save to state.
 * @param {object} data
 */
export default function getLogic(data) {
  const { property, down_payment } = data;

  const { insurance_value } = getCmhcInsurance(property, down_payment);
  const total_price = property + insurance_value;
  const down_payment_v = returnMoneyValue(down_payment, total_price);
  const interest_rate = data.mortgage_rate / 100;
  const months = data.amortization * 12;
  const tax_rate = data.amount_annual_taxes / 100;
  const insurance_rate = data.owner_insurance / 100;

  const annual_maintenance = total_price * (data.maintenance / 100);
  const annual_owner_insurance = total_price * (data.owner_insurance / 100);
  const { annual_heating_costs, another_monthly_expenses } = data;
  const annual_tax = tax_rate * total_price;

  let payment = "";
  payment = getMortgageCalculator()
    .setValue("total_price", total_price)
    .setValue("down_payment", down_payment_v)
    .setValue("interest_rate", interest_rate)
    .setValue("months", months)
    .setValue("insurance_rate", insurance_rate)
    .calculatePayment();

  // tax + maint + insurance + heating
  const m_monthly_expenses =
    another_monthly_expenses +
    (annual_tax +
      annual_maintenance +
      annual_owner_insurance +
      annual_heating_costs) /
      12;
  payment.m_monthly_expenses = m_monthly_expenses;

  const r_monthly_expenses = data.renters_insurance;
  payment.r_monthly_expenses = r_monthly_expenses;
  payment.total_price = total_price - down_payment_v;

  analizeForInvestment(data, payment);
  return payment;
}
