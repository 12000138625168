const configRebateMeta = {
  title: "Canadian Provincial Land Transfer Taxes Rebate",
  description: "Canadian Provincial Land Transfer Taxes Rebate",
  keywords: "Land Transfer Taxes Rebate",
  ON: {
    title: "Rebate Ontario",
    description: "Rebate Ontario",
    keywords: "Rebate Ontario",
  },
  Toronto: {
    title: "Rebate Toronto",
    description: "Rebate Toronto",
    keywords: "Rebate Toronto",
  },
  BC: {
    title: "Rebate British Columbia, Vancouver",
    description: "Rebate British Columbia, Vancouver",
    keywords: "Rebate British Columbia, Vancouver",
  },
  PE: {
    title: "Rebate Prince Edward Island",
    description: "Rebate Prince Edward Island",
    keywords: "Rebate Prince Edward Island",
  },
};

export default configRebateMeta;
