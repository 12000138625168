import "./CardComponent.scss";

import { Button } from "antd";
import Text from "antd/lib/typography/Text";
import PropTypes from "prop-types";
import React from "react";

const ShowRange = (props) => {
  const {
    mobile,
    name,
    prefix,
    suffix,
    min,
    onChange,
    tipFormatter,
    show20,
  } = props;

  let show_min = tipFormatter(min, prefix, suffix);
  if (show20) {
    show_min = "20%";
  }

  /* 
      display: flex;
    justify-content: center;
    justify-items: center;
    align-items: baseline;
    margin: 10px 0; */
  return (
    <div style={{ marginLeft: 5 }}>
      {mobile && suffix === "%" && name !== "mortgage_rate" && (
        <Text type="secondary" className="mobile-input-min-max">
          Min:{" "}
          <Button
            type="link"
            style={{ marginLeft: 10, marginRight: 10, fontWeight: 100 }}
            onClick={() => onChange(min)}
            aria-label="show min"
          >
            {show_min}
          </Button>
          {name === "down_payment" && <span>|</span>}
          {name === "down_payment" && (
            <Button
              type="link"
              style={{ marginLeft: 10, marginRight: 20, fontWeight: 100 }}
              onClick={() => onChange(20)}
              aria-label="show 20"
            >
              20%
            </Button>
          )}
        </Text>
      )}

      {!mobile && name === "down_payment" && suffix === "%" && min !== 20 && (
        <>
          <Button
            type="link"
            style={{ marginLeft: 45, marginTop: -10 }}
            onClick={() => onChange(20)}
            aria-label="show 20"
          >
            20%
          </Button>
        </>
      )}
    </div>
  );
};

export default ShowRange;

ShowRange.propTypes = {
  mobile: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tipFormatter: PropTypes.func.isRequired,
  show20: PropTypes.bool,
};

ShowRange.defaultProps = {
  prefix: "",
  suffix: "",
  show20: false,
};
