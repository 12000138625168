const configChart = {
  title: "Mortgage Payment Information",
  name_fields: [
    { code: "balance", title: "Balance" },
    { code: "interest_payment", title: "Interest Payment" },
    { code: "principal_payment", title: "Principal Payment" },
    { code: "total_payment", title: "Total Payment" },
    { code: "total_payments", title: "Total Payments" },
    { code: "total_interest", title: "Total Interest" },
    { code: "renter_net_worth", title: "Renter's Net Worth" },
    { code: "landlord_net_worth", title: "Owner's Net Worth" },
    { code: "taxes_and_maintenance", title: "Taxes And Maintenance" },
    { code: "total_monthly_payment", title: "Total Monly Payment" },
    { code: "total_selling_expenses", title: "Total Selling Expenses" },
    { code: "mortgage_1", title: "Mortgage 1" },
    { code: "mortgage_2", title: "Mortgage 2" },
  ],
  name_cash_needed_fields: [
    { code: "down_payment_cash", title: "Down payment" },
    { code: "pst_insurance", title: "PST on mortgage insurance" },
    { code: "land_transfer_tax", title: "Land Transfer Tax" },
    { code: "lawyer_fees", title: "Lawyer fees" },
    { code: "title_insurance", title: "Title insurance" },
    { code: "full_cash_needed", title: "Total Cash Required" },
  ],
  rechart_colors: [
    "#8884d8",
    "#f5839b",
    "#82ca9d",
    "#e0b30d",
    "#a6e332",
    "#409bf0",
    "#fd04bc",
    "#0006c1",
  ],
  rechart_graphs: {
    0: {
      value: "interest_payment",
      name: "Interest Payment",
    },
    1: {
      value: "principal_payment",
      name: "Principal Payment",
    },
    2: {
      value: "renter_net_worth",
      name: "Renter's Net Worth",
    },
    3: {
      value: "landlord_net_worth",
      name: "Owner's Net Worth",
    },
    4: {
      value: "total_interest",
      name: "Total Interest",
    },
    5: {
      value: "total_payments",
      name: "Total Payments",
    },
    6: { value: "balance", name: "Balance" },
    7: { value: "total_selling_expenses", name: "Total Selling Expenses" },
  },
  chart_colors: [
    {
      backgroundColor: "rgba(43, 151, 197,0.2)",
      borderColor: "rgba(43, 151, 197,1)",
      hoverBackgroundColor: "rgba(43, 151, 197,0.4)",
      hoverBorderColor: "rgba(43, 151, 197,1)",
    },
    {
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
    },
    {
      backgroundColor: "rgba(95,237,91,0.2)",
      borderColor: "rgba(95,237,91,1)",
      hoverBackgroundColor: "rgba(95,237,91,0.4)",
      hoverBorderColor: "rgba(95,237,91,1)",
    },
    {
      backgroundColor: "rgba(191,91,237,0.2)",
      borderColor: "rgba(191,91,237,1)",
      hoverBackgroundColor: "rgba(191,91,237,0.4)",
      hoverBorderColor: "rgba(191,91,237,1)",
    },
  ],
};

export default configChart;
