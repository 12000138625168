/* eslint-disable import/prefer-default-export */
import configRebate from "../../configs/configRebate";

const { places } = configRebate;

export const locationToUrl = (loc) => {
  let name_city = "";
  if (loc.city && loc.city === "Vancouver") name_city = "Vancouver";
  if (loc.city && loc.city !== "Vancouver")
    name_city = places[loc.city].url_name;

  const part_page = "/rebate";
  const url_region = `/${places[loc.code_name].url_name}`;
  const url_city = loc.city ? `/${name_city}` : "";
  const result = `${part_page}${url_region}${url_city}`;

  return result;
};
