/* eslint-disable import/prefer-default-export */
import { message } from "antd";
import React from "react";

import configTextForElements from "../../configs/configTextForElements";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import { file_flag } from "../SelectRegion/helper";

/**
 * @param {object} data - element from state.state
 * @returns {object} returned formatted data
 */
export function saveItemMortgage(data) {
  const output = {};
  Object.keys(data).forEach((key) => {
    // if (typeof data[key] === "number" && data[key] !== null) {
    output[key] = data[key];
    // }
  });
  return output;
}

/**
 * @param {object} item - element from state.mortgage_items
 * @returns {string}  - name of Mortgage
 */
export function getMortgageName(item) {
  const { property, mortgage_rate, amortization } = item;
  const amount = formatToMoney(property);
  const interest = addSuffix(mortgage_rate, "%");
  const amortization_v = addSuffix(amortization, "years");
  return `${amount} - ${interest} - ${amortization_v}`;
}

/**
 * get Mortgages's description
 * @param {object} item
 */
export function getDescription(item) {
  const { description } = item;
  return description;
}

/**
 * подсветка в DrawerComponent только что сохраненного mortgage.
 * @param {*} timestamp
 */
export function getNewRow(timestamp) {
  const current = new Date().getTime() / 1000;
  const class_name = current - timestamp < 10 ? "new-row" : "";
  return class_name;
}

export function getHumanMessage(codeText) {
  return configTextForElements.messsageText[codeText];
}

export function getMessage(text, seconds, type) {
  switch (type) {
    case "success":
      message.success(text, seconds);
      break;
    case "error":
      message.error(text, seconds);
      break;
    default:
      message.info(text, seconds);
      break;
  }
}

export function formatLocationData(obj) {
  let output = "Unknown location";

  if (obj.code_name) {
    const city = obj.city ? `${obj.city}, ` : "";
    output = `${city}${obj.region}(${obj.code_name})`;
  }

  return output;
}

export const getFlagLocation = (dt) => {
  const name_country = dt?.location?.country
    ? dt?.location?.country?.toLocaleLowerCase()
    : "nolocation";
  return (
    <img
      alt=""
      className="location-img"
      src={`${file_flag}${name_country}.png`}
    />
  );
};
