import _ from "lodash";

/**
 * @param {integer|float} num
 * @returns {string} returned formatted string
 * e.g.: 123456.78 => $123,456
 */
export function formatToMoney(num) {
  const tmpNum = num || 0;
  return `$${tmpNum.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  })}`;
}

/**
 * @param {integer|float|string} value
 * @param {string} suff
 * @returns {string} returned formatted string
 * e.g.: (123.45, "%") => 123.45%
 */
export function addSuffix(value, suff) {
  return `${value}${suff || ""}`;
}

/**
 * @param {integer|float|string} value
 * @param {string} pref
 * @returns {string} returned formatted string
 * e.g.: ("$", 123.45) => $123.45
 */
export function addPrefix(pref, value) {
  return `${pref || ""}${value}`;
}

// if resolution >767 return 1, else return 0
export function isMobile() {
  const resolution = window.innerWidth;
  const output = resolution > 767;
  return !output;
}

export function chooseFormat(name, value) {
  let output = formatToMoney(value);
  if (name === "mortgage_rate" || name === "down_payment_proc")
    output = addSuffix(value, "%");
  return output;
}

export const getClearData = (data) => {
  const result = _.omit(
    data,
    "RESULT",
    "collapses",
    "drawer",
    "charts",
    "cash_needed",
    "switchers"
  );
  return result;
};

// for rebate & landTranferTax
export const getLastPartUrl = (str) => {
  const len = str.length;
  const separator = str.lastIndexOf("/");

  function getNewString(old_str) {
    const new_str = old_str.substr(0, old_str.length - 1);
    const sep = new_str.lastIndexOf("/");
    return { start: sep + 1, end: new_str.length };
  }

  const find_region =
    len - separator === 1
      ? getNewString(str)
      : { start: separator + 1, end: len };

  const result = str.substring(find_region.start, find_region.end);
  return result;
};

export const getCodeRegion = (config, str, component) => {
  const region = str === "Vancouver" ? "BritishColumbia" : str;
  const actual_region = component === "rebate" ? region : str;
  const obj = Object.entries(config).filter(
    ([key, value]) =>
      value.url_name.toLowerCase() === actual_region.toLowerCase() && key
  );
  const result =
    obj.length !== 0 ? { ...obj[0][1], code_name: obj[0][0] } : { empty: true };
  return result;
};
