const config = {
  panelNumber: {
    2: {
      // text:
      // "Imagine a scenario where you are buying a house and indicate the characteristics of your mortgage."
    },
    3: {
      text: "Buying a home comes with some expenses that should be expected.",
    },
    4: {
      text:
        "Closing costs are the initial charge you have to pay when you buy or sell a home.",
    },
    5: {
      text: "Buying a home comes with some expenses that should be expected.",
      // text:
      // "When buying a house, you have to think about its maintenance. It’s best to set an amount aside for maintenance related costs."
    },
    6: {
      text:
        "The value of a property changes over time, following market trends.",
    },
    7: {
      text:
        "Imagine that you are not taking mortgage but renting instead and putting all saved money somewehere with that interest rate.",
    },
    8: {
      text:
        "The S&P 500 Index the average annual return since its inception in 1926 through 2018 is approximately 10%–11%.",
    },
  },
  rent: {
    title: "Rent",
    text: "Indicate the amount of your current or projected monthly rent.",
    prefix: "$",
  },
  rent_annual_increase: {
    title: "Yearly rent increase",
    suffix: "%",
  },
  property: {
    title: "Property Value",
    text: "Indicate the value of the property you are looking to buy.",
    prefix: "$",
  },
  down_payment: {
    title: "Down Payment",
    /* "Indicate how much you could pay for your down payment.  */
    text:
      "Usually loans with a down payment of less than 20% of property value will require additional loan-insurance.",
    suffix: "%",
    percentOf: "property",
    percentOf_prefix: "$",
  },
  amortization: {
    title: "Amortization period",
    suffix: " years",
  },
  mortgage_rate: {
    title: "Mortgage Rate",
    suffix: "%",
  },
  amount_annual_taxes: {
    title: "Amount of annual municipal taxes",
    text:
      "These amounts are determined by your municipality, according to the value of the property.",
    suffix: "%",
    percentOf: "property",
    percentOf_prefix: "$",
  },
  annual_heating_costs: {
    title: "Annual heating costs",
    text: "",
    prefix: "$",
  },
  another_monthly_expenses: {
    title: "Condo fee or other MONTHLY expenses",
    text: "",
    prefix: "$",
  },
  buying_home: {
    title: "Costs of buying a home",
    suffix: "%",
    percentOf: "property",
    percentOf_prefix: "$",
  },
  selling_home: {
    title: "Costs of selling a home",
    suffix: "%",
    percentOf: "property",
    percentOf_prefix: "$",
  },
  maintenance: {
    title: "Maintenance and renovation",
    suffix: "%",
  },
  owner_insurance: {
    title: "Annual homeowner's insurance",
    suffix: "%",
    percentOf: "property",
    percentOf_prefix: "$",
  },
  renters_insurance: {
    title: "Tenant insurance",
    prefix: "$",
  },
  rentMonthlyCostsValue: {
    title: "Rent monthly heating costs",
    prefix: "$",
  },
  rate_of_growth: {
    title: "Property rate of growth",
    suffix: "%",
  },
  return_investment: {
    title: "Rate of return on investment",
    suffix: "%",
  },
  landTransferTax: {
    Montreal: [
      [50900, 0.5],
      [254400, 1],
      [508700, 1.5],
      [1017400, 2],
      [Infinity, 2.5],
    ],
    Toronto: [
      [55000, 0.5],
      [250000, 1],
      [400000, 1.5],
      [2000000, 2],
      [Infinity, 2.5],
    ],
    AB: [[Infinity, 0.02]],
    SK: [[Infinity, 0.3]],
    NL: [[Infinity, 0.4]],
    NS: [[Infinity, 1]],
    BC: [
      [200000, 1],
      [2000000, 2],
      [3000000, 3],
      [9999999, 2],
    ],
    MB: [
      [30000, 0],
      [90000, 0.5],
      [150000, 1],
      [200000, 1.5],
      [Infinity, 2],
    ],
    ON: [
      [55000, 0.5],
      [250000, 1],
      [400000, 1.5],
      [2000000, 2],
      [Infinity, 2.5],
    ],
    QB: [
      [50000, 0.5],
      [250000, 1],
      [Infinity, 1.5],
    ],
    NB: [[Infinity, 1]],
    PE: [
      [30000, 0],
      [Infinity, 1],
    ],
  },
  lawyerFees: 1000,
  titleInsurance: 50,
  headerTipsCashNeeded: [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ],
};

export default config;
