import "./RebatePageFull.scss";

import React from "react";

import configRebate from "../../configs/configRebate";
import { formatToMoney } from "../../helpers/commonHelpers";

const RebatePageFull = () => {
  const { description, places } = configRebate;

  return (
    <div className="all-conditions">
      {Object.entries(places).map((item, index) => {
        const k = index;
        const dt = item[1];
        return (
          <div key={`div_${k}`}>
            <h3>{dt.name}</h3>
            <table className="table">
              <tbody>
                <tr>
                  <td>{description.maximum}</td>
                  <td>
                    {formatToMoney(dt.maximum)}{" "}
                    <sup>{dt.tip ? dt.tip.num : ""}</sup>
                  </td>
                </tr>
                <tr>
                  <td>{description.cover}</td>
                  <td>{formatToMoney(dt.cover)}</td>
                </tr>
                <tr>
                  <td>{description.refund}</td>
                  <td>{dt.refund}</td>
                </tr>
              </tbody>
            </table>
            {dt.tip && (
              <div>
                {dt.tip.num} - {dt.tip.txt}
              </div>
            )}
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default RebatePageFull;
