import "./Payments.scss";

import { Divider } from "antd";
import React from "react";

import configPayment from "../../configs/configPayments";
import { isMobile } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import {
  getColumns,
  getHumanDate,
  getPaymentShedule,
} from "../../helpers/paymentHelpers";
import CreatePdf from "../CreatePdf/CreatePdf";
import GetMeta from "../GetMeta/GetMeta";
import PaymentsList from "../PaymentsList/PaymentsList";
import PaymentsSwitchers from "../PaymentsSwitchers/PaymentsSwitchers";
import PaymentTable from "../PaymentTable/PaymentTable";
import PaymentSummary from "./PaymentSummary";

const Payments = () => {
  const { generalData } = configPayment;
  const payment = useActualState("RESULT");
  const { payment_schedule } = payment;
  const rent_or_buy = useActualState("rent_or_buy");
  const kind_of_view = useActualState("switchers").payments_wrapper;
  const interval = useActualState("switchers").payments_items;

  const data = {
    columns: getColumns(generalData.columns, rent_or_buy, interval),
    rows: getPaymentShedule(payment_schedule),
  };

  function getRowsPayments(scope_rows, interval_value) {
    const frequency = interval_value ? 12 : 1;
    const get_rows = [];
    scope_rows.rows.forEach((el, index) => {
      const elem = el;
      const i = index;

      if (i % frequency === 0) {
        let { month } = el;
        month = `#${i / frequency + 1}: ${getHumanDate(month)}`;
        elem.month = month;
        get_rows.push(elem);
      }
    });
    return { columns: scope_rows.columns, rows: get_rows };
  }

  const visual = { left: "Cards", right: "Table" };

  return (
    <div className="Payments">
      <GetMeta page="payments" />
      <div className="wrapper-div">
        <PaymentSummary />
        <CreatePdf />
        <br />
        <PaymentsSwitchers name="payments_wrapper" visual={visual} />
        {isMobile() && <PaymentsList data={getRowsPayments(data, interval)} />}
        {!isMobile() && !kind_of_view && (
          <PaymentsList data={getRowsPayments(data, interval)} />
        )}
        {!isMobile() && kind_of_view && (
          <PaymentTable data={getRowsPayments(data, interval)} />
        )}
        <Divider />
      </div>
    </div>
  );
};

export default Payments;
