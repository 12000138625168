import "./Rebate.scss";

import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { setCashNeeded } from "../../actions";
import { useActualState } from "../../helpers/getStateValues";
import ShowCheckbox from "../Mortgage/ShowCheckbox/ShowCheckbox";
import { useUserLocation } from "../SelectRegion/selectRegionService";
import { locationToUrl } from "./helpers";

const Rebate = () => {
  const dispatch = useDispatch();
  const location = useUserLocation();
  const selected_checkboxes = useActualState("rebate");

  function editSelectCheckBox(el) {
    const name_property = el.name;
    const check_value = el.checked;
    const selected_items = { ...selected_checkboxes };
    selected_items[name_property] = check_value;
    dispatch(setCashNeeded({ name: "rebate", value: selected_items }));
  }

  return (
    <div className="Rebate">
      <table>
        <tbody>
          <tr>
            <td>
              {" "}
              <div className="question">
                <NavLink to={locationToUrl(location)} exact>
                  <QuestionCircleOutlined />
                </NavLink>
              </div>
            </td>
            <td>I am a first-time home buyer</td>
            <td>
              <ShowCheckbox
                name="first_time"
                returnIdCheck={editSelectCheckBox}
              />
            </td>
          </tr>
          {/* <tr>
            <td>&nbsp;</td>
            <td>I am buying a newly built home</td>
            <td>
              <ShowCheckbox
                name="newly_built"
                returnIdCheck={editSelectCheckBox}
              />
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default Rebate;
