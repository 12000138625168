import "./PaymentOneRow.scss";

import { EllipsisOutlined } from "@ant-design/icons";
import { Card, Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import ModalWindow from "./ModalWindow";

export const PaymentOneRow = (props) => {
  const { data, names } = props;
  const state_values = useSelector((state) => state.state);

  function getInfo(obj) {
    Modal.info({
      title: `${obj.month}`,
      content: <ModalWindow data={obj} comparison={state_values.rent_or_buy} />,
      onOk() {},
    });
  }

  const gridStyle = {
    width: "25%",
    textAlign: "center",
    padding: "5px",
  };

  return (
    <Card
      title={data.month}
      hoverable
      extra={<EllipsisOutlined />}
      onClick={() => getInfo(data)}
    >
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[1].label}</p>
        <p>{data.interest_payment}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[3].label}</p>
        <p>{data.principal_payment}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[5].label}</p>
        <p>{data.total_payments}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[6].label}</p>
        <p>{data.balance}</p>
      </Card.Grid>
    </Card>
  );
};

export default PaymentOneRow;

PaymentOneRow.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
};
