import "./ChartPayments.scss";

import { Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ResizeDetector from "react-resize-detector";

import { setAddValue } from "../../actions";
import configChart from "../../configs/configChart";
import { useActualState } from "../../helpers/getStateValues";
import ChartRechart from "../ChartRechart/ChartRechart";
import {
  getDataToRechart,
  getTypeChart,
  scheduleForYears,
} from "../ChartRechart/helpers";
import PaymentsSwitchers from "../PaymentsSwitchers/PaymentsSwitchers";

const ChartPayments = () => {
  const dispatch = useDispatch();
  const parentRef = React.createRef();
  const state = useActualState();
  const payment = useActualState("RESULT");
  const ch = useActualState("switchers");
  const { type_charts } = ch;
  const chart_values = useActualState("charts");
  const [width_rechart, setWidthRechart] = useState(0);

  const { Option } = Select;
  const { rechart_graphs } = configChart;

  console.log("state ", state);
  const visual = { left: "Line", right: "Bar" };
  const data_to_rechart = getDataToRechart(
    scheduleForYears(payment.payment_schedule),
    chart_values,
    getTypeChart(type_charts)
  );

  function handleChange(value) {
    const val = value[0] ? value : ["balance"];
    dispatch(setAddValue({ name: "charts", value: val }));
  }

  function onResize(width, height) {
    console.log("onResize", width, height);
    setWidthRechart(width);
  }

  return (
    <div className="Charts">
      <div className="wrapper-charts">
        <div className="chart-options">
          <PaymentsSwitchers name="type_charts" visual={visual} />
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="select data"
            defaultValue={chart_values}
            value={chart_values}
            onChange={handleChange}
            optionLabelProp="label"
          >
            {Object.entries(rechart_graphs).map(([key, value]) => {
              return (
                <Option key={key} value={value.value} label={value.name}>
                  <div key={`d-${key}`} className="label">
                    {value.name}
                  </div>
                </Option>
              );
            })}
          </Select>
        </div>
        <div ref={parentRef} className="wrapper-rechart">
          <ChartRechart width={width_rechart} data={data_to_rechart} />
        </div>
      </div>

      <ResizeDetector
        handleWidth
        handleHeight
        onResize={onResize}
        targetDomEl={parentRef.current}
      />
    </div>
  );
};

export default ChartPayments;
