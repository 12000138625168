/* eslint-disable import/prefer-default-export */
import { Tag } from "antd";
import React from "react";

const getOneCategory = (num, list2) => {
  return (
    <Tag color={list2[num].color} key={num}>
      {list2[num].name}
    </Tag>
  );
};

export const getListCategories = (arr_num, list) => {
  const output = arr_num.map((item) => {
    return getOneCategory(item, list);
  });
  return output;
};

export const getDate = (input_number) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // console.log("date ", input_number);
  const date = new Date(input_number);
  return date.toLocaleDateString("en-US", options);
};
