import "./App.scss";

import { UpCircleTwoTone } from "@ant-design/icons";
import { BackTop, Layout } from "antd";
import React from "react";
import ReactPixel from "react-facebook-pixel";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import configPixel from "../configs/configPixel";
import Blog from "./Blog/Blog";
import Charts from "./Charts/Charts";
import CheckUrl from "./CheckUrl/CheckUrl";
import Comparison from "./Comparison/Comparison";
import CountryCanada from "./ForSeo/CountryCanada";
import CountryUSA from "./ForSeo/CountryUSA";
import GetMeta from "./GetMeta/GetMeta";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import HomePage from "./HomePage/HomePage";
import InsurancePage from "./InsurancePage/InsurancePage";
import LandTransferTax from "./LandTransferTax/LandTransferTax";
import Payments from "./Payments/Payments";
import RatesPage from "./RatesPage/RatesPage";
import RebatePage from "./RebatePage/RebatePage";
import RightPart from "./RightPart/RightPart";
import ScrollToTop from "./ScrollToTop";
import SocialButtons from "./SocialButtons/SocialButtons";

const { Header, Content } = Layout;

const App = () => {
  const { pixel_id, advanced_matching, options_pixel } = configPixel;
  if (process.env.NODE_ENV !== "development") {
    ReactPixel.init(pixel_id, advanced_matching, options_pixel);
    ReactPixel.pageView();
  }
  return (
    <div className="App">
      <GetMeta page="home" />
      {/* check - get data from state or URL */}
      <CheckUrl />

      <BrowserRouter>
        {/* scroll to top after each route change */}
        <ScrollToTop>
          <Layout className="container">
            <Header className="header">
              <HeaderMenu />
            </Header>
            <Content className="site-layout">
              <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/blog" exact component={Blog} />
                <Route path="/blog/:data" exact component={Blog} />
                <Route path="/charts" exact component={Charts} />
                <Route path="/comparison" exact component={Comparison} />
                <Route path="/comparison/:data" exact component={Comparison} />
                <Route path="/rates/mortgage" exact component={RatesPage} />
                <Route path="/insurance" exact component={InsurancePage} />
                <Route path="/insurance/:data" component={InsurancePage} />
                <Route
                  path="/land-transfer-tax"
                  exact
                  component={LandTransferTax}
                />
                <Route
                  path="/land-transfer-tax/:data"
                  component={LandTransferTax}
                />
                <Route path="/overview" exact component={RightPart} />
                <Route path="/payments" exact component={Payments} />
                <Route path="/rebate" exact component={RebatePage} />
                <Route path="/rebate/:data" component={RebatePage} />
                <Route path="/Canada" component={CountryCanada} />
                <Route path="/USA" component={CountryUSA} />
                {/* Redirect all 404's to home */}
                <Redirect to="/" />
              </Switch>
              <SocialButtons />
            </Content>
          </Layout>
        </ScrollToTop>
      </BrowserRouter>

      <BackTop visibilityHeight={100}>
        <UpCircleTwoTone />
      </BackTop>
    </div>
  );
};

export default App;
