import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

import packageJson from "../../../package.json";
import configMeta from "../../configs/configMeta";

const GetMeta = (props) => {
  const { page, region } = props;
  const version = `${packageJson.version}`;
  const config_meta_page = configMeta?.[page] || {};
  if (page && region) {
    // todo looks to complex anyway

    const region_data = config_meta_page[region.code_name];

    let title = region_data?.title || config_meta_page.title;
    let description = region_data?.description || config_meta_page.description;
    let keywords = region_data?.keywords || config_meta_page.keywords;

    if (page === "canada" || page === "usa") {
      const config_meta_page_country = configMeta[page] || {};
      title = config_meta_page_country[region]
        ? `${config_meta_page_country.common_phrase} ${config_meta_page_country[region].region}.`
        : config_meta_page_country.title;
      description = config_meta_page_country[region]
        ? `${config_meta_page_country.description_1} ${config_meta_page_country[region].region}. ${config_meta_page_country.description_2}`
        : `${config_meta_page_country.description_1} ${config_meta_page_country.country}. ${config_meta_page_country.description_2}`;
      keywords = config_meta_page_country[region]
        ? `${config_meta_page_country.keywords}, ${config_meta_page_country[region].region}`
        : `${config_meta_page_country.keywords}, ${config_meta_page_country.country}`;
    }

    return (
      <Helmet>
        <title>{`${configMeta.title}. ${title}`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="built" content={version} />
      </Helmet>
    );
  }
  return (
    <Helmet>
      <title>{config_meta_page.title}</title>
      <meta name="description" content={config_meta_page.description} />
      <meta name="keywords" content={config_meta_page.keywords} />
      <meta name="built" content={version} />
    </Helmet>
  );
};

export default GetMeta;

GetMeta.propTypes = {
  page: PropTypes.string.isRequired,
  region: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
};

GetMeta.defaultProps = {
  region: {},
};
