import "./LandTransferTax.scss";

import React from "react";

import configLandTransferTax from "../../configs/configLandTransferTax";
import { getCodeRegion, getLastPartUrl } from "../../helpers/commonHelpers";
import GetMeta from "../GetMeta/GetMeta";
import HomePage from "../HomePage/HomePage";
import AllRegions from "./AllRegions";
import InfoLandTransferTax from "./InfoLandTransferTax";

const LandTransferTax = () => {
  const { places } = configLandTransferTax;
  const wlh = window.location.href;
  const is_page_ltt = wlh.includes("land-transfer-tax");
  const last_part_url = getLastPartUrl(wlh);

  const code_region = getCodeRegion(places, last_part_url, "land-transfer-tax");
  const show_full_page = last_part_url === "land-transfer-tax";

  return (
    <div className="LandTransferTax">
      <GetMeta page="landTransferTax" region={code_region} />
      {is_page_ltt && (show_full_page || code_region.empty) && <AllRegions />}
      {is_page_ltt && !show_full_page && !code_region.empty && (
        <>
          <HomePage />
          <InfoLandTransferTax data={code_region} />
        </>
      )}
    </div>
  );
};

export default LandTransferTax;
