import { ADD_MORTGAGE } from "./types";

export default function addMortgage(obj) {
  return {
    type: ADD_MORTGAGE,
    payload: {
      obj,
    },
  };
}
