const configUSAMeta = {
  title: "Mortgage Calculator, Rent or Buy comparison.",
  description_1: "The best Mortgage Calculator for ",
  description_2: "Helps you make right decision.",
  keywords: "mortgage, calculator, rent, best rate",
  common_phrase: "The Best For ",
  country: "USA",
  Alabama: { code_name: "AL", region: "Alabama" },
  Alaska: { code_name: "AK", region: "Alaska" },
  Arizona: { code_name: "AZ", region: "Arizona" },
  Arkansas: { code_name: "AR", region: "Arkansas" },
  California: { code_name: "CA", region: "California" },
  Colorado: { code_name: "CO", region: "Colorado" },
  Connecticut: { code_name: "CT", region: "Connecticut" },
  Delaware: { code_name: "DE", region: "Delaware" },
  DistrictOfColumbia: {
    code_name: "DC",
    region: "District of Columbia",
  },
  Florida: { code_name: "FL", region: "Florida" },
  Georgia: { code_name: "GA", region: "Georgia" },
  Hawaii: { code_name: "HI", region: "Hawaii" },
  Idaho: { code_name: "ID", region: "Idaho" },
  Illinois: { code_name: "IL", region: "Illinois" },
  Indiana: { code_name: "IN", region: "Indiana" },
  Iowa: { code_name: "IA", region: "Iowa" },
  Kansas: { code_name: "KS", region: "Kansas" },
  Kentucky: { code_name: "KY", region: "Kentucky" },
  Louisiana: { code_name: "LA", region: "Louisiana" },
  Maine: { code_name: "ME", region: "Maine" },
  Maryland: { code_name: "MD", region: "Maryland" },
  Massachusetts: { code_name: "MA", region: "Massachusetts" },
  Michigan: { code_name: "MI", region: "Michigan" },
  Minnesota: { code_name: "MN", region: "Minnesota" },
  Mississippi: { code_name: "MS", region: "Mississippi" },
  Missouri: { code_name: "MO", region: "Missouri" },
  Montana: { code_name: "MT", region: "Montana" },
  Nebraska: { code_name: "NE", region: "Nebraska" },
  Nevada: { code_name: "NV", region: "Nevada" },
  NewHampshire: { code_name: "NH", region: "New Hampshire" },
  NewJersey: { code_name: "NJ", region: "New Jersey" },
  NewMexico: { code_name: "NM", region: "New Mexico" },
  NewYork: { code_name: "NY", region: "New York" },
  NorthCarolina: { code_name: "NC", region: "North Carolina" },
  NorthDakota: { code_name: "ND", region: "North Dakota" },
  Ohio: { code_name: "OH", region: "Ohio" },
  Oklahoma: { code_name: "OK", region: "Oklahoma" },
  Oregon: { code_name: "OR", region: "Oregon" },
  Pennsylvania: { code_name: "PA", region: "Pennsylvania" },
  RhodeIsland: { code_name: "RI", region: "RhodeIsland" },
  SouthCarolina: { code_name: "SC", region: "South Carolina" },
  SouthDakota: { code_name: "SD", region: "South Dakota" },
  Tennessee: { code_name: "TN", region: "Tennessee" },
  Texas: { code_name: "TX", region: "Texas" },
  Utah: { code_name: "UT", region: "Utah" },
  Vermont: { code_name: "VT", region: "Vermont" },
  Virginia: { code_name: "VA", region: "Virginia" },
  Washington: { code_name: "WA", region: "Washington" },
  WestVirginia: { code_name: "WV", region: "West Virginia" },
  Wisconsin: { code_name: "WI", region: "Wisconsin" },
  Wyoming: { code_name: "WY", region: "Wyoming" },
};

export default configUSAMeta;
