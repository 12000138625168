const configRates = {
  rates: {
    fixed: [
      {
        name: "Meridian Credit Union",
        rate: 1.6,
      },
      {
        name: "Alterna Saving",
        rate: 1.64,
      },
      {
        name: "Motusbank",
        rate: 1.74,
      },
      {
        name: "Duca",
        rate: 1.74,
      },
      {
        name: "Equitable Bank",
        rate: 1.79,
      },
    ],
    variable: [
      {
        name: "Meridian Credit Union",
        rate: 1.35,
      },
      {
        name: "Motusbank",
        rate: 1.7,
      },
      {
        name: "Equitable Bank",
        rate: 1.7,
      },
      {
        name: "Scotia Bank",
        rate: 1.85,
      },
    ],
  },
};

export default configRates;
