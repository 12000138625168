import "./BlogAuthor.scss";

import { Popover } from "antd";
import PropTypes from "prop-types";
import React from "react";

import configBlog from "../../configs/configBlog";
import AuthorDetails from "./AuthorDetails";

const AuthorImg = (props) => {
  const { file_name } = props;
  const separator =
    file_name.lastIndexOf("#") === -1 ? 100 : file_name.lastIndexOf("#");
  const num_post = file_name.substr(0, separator);

  return (
    <div className="author-mini">
      <Popover
        content={
          <AuthorDetails id_author={configBlog.data_rows[num_post].author} />
        }
        title={configBlog.authors[configBlog.data_rows[num_post].author].name}
        overlayClassName="author-details"
      >
        <img
          src={`/images/authors/${configBlog.data_rows[num_post].author}.jpg`}
          alt={configBlog.authors[configBlog.data_rows[num_post].author].name}
          title={configBlog.authors[configBlog.data_rows[num_post].author].name}
        />
      </Popover>
    </div>
  );
};

export default AuthorImg;

AuthorImg.propTypes = {
  file_name: PropTypes.string.isRequired,
};
