const configPostMeta = {
  20210102: {
    title: "Mortgage Post - 20210102",
    description: "20210102 - The best Mortgage Calculator. Post...",
    keywords:
      "20210102, Post, posts, mortgage, calculator, rent, buy, payments, best rate",
  },
  20210104: {
    title: "Mortgage Post - 20210104",
    description: "20210104 - The best Mortgage Calculator. Post...",
    keywords:
      "20210104, Post, posts, mortgage, calculator, rent, buy, payments, best rate",
  },
  20210403: {
    title: "Mortgage Post - 20210403",
    description: "20210403 - The best Mortgage Calculator. Post...",
    keywords:
      "20210403, Post, posts, mortgage, calculator, rent, buy, payments, best rate",
  },
};

export default configPostMeta;
