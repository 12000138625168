/* eslint-disable import/prefer-default-export */
import React from "react";

export const getImage = (input_number, is_post) => {
  const img = is_post ? "title" : "title_blog";

  return (
    <img
      src={`/posts/images/${input_number}/${img}.jpg`}
      alt=""
      className="blog-img"
    />
  );
};
