import "./ChartRechart.scss";

import PropTypes from "prop-types";
import React from "react";

import { isMobile } from "../../helpers/commonHelpers";
import ChartBar from "./charts/ChartBar";
import ChartLine from "./charts/ChartLine";
import ChartPie from "./charts/ChartPie";

const ChartRechart = (props) => {
  const { data, width } = props;
  const width_to_pie = isMobile() ? width / 1 : width / 2;
  const list_of_charts = {
    Line: <ChartLine data={data} width={width} />,
    Bar: <ChartBar data={data} width={width} />,
    Pie: <ChartPie data={data.data} width={width_to_pie} />,
  };

  return <div className="wrapper-rechart">{list_of_charts[data.type]}</div>;
};

export default ChartRechart;

ChartRechart.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
