const configInsuranceMeta = {
  title: "Mortgage Insurance",
  description: "The best Mortgage Calculator. Insurance... ",
  keywords: "insurance, mortgage, calculator, rent, best rate",
  Toronto: {
    title: "Mortgage Insurance Toronto",
    description: "Mortgage Insurance Toronto",
    keywords: "Mortgage Insurance Toronto",
  },
};

export default configInsuranceMeta;
