/**
 * @typedef {Object} SupportedLocation
 * @property {string} code_name - code_name
 * @property {string} region - region
 * @property {string} city - city
 */
const list_of_supported_locations = [
  { city: "Montreal", code_name: "QB", region: "Quebec" },
  { city: "Toronto", code_name: "ON", region: "Ontario" },
  {
    city: "Vancouver",
    code_name: "BC",
    region: "British Columbia",
  },
  { code_name: "AB", region: "Alberta" },
  { code_name: "BC", region: "British Columbia" },
  { code_name: "MB", region: "Manitoba" },
  { code_name: "NB", region: "New Brunswick" },
  { code_name: "NL", region: "Newfoundland and Labrador" },
  { code_name: "NS", region: "Nova Scotia" },
  { code_name: "ON", region: "Ontario" },
  { code_name: "PE", region: "Prince Edward Island" },
  { code_name: "SK", region: "Saskatchewan" },
  { code_name: "QB", region: "Quebec" },
];

export default list_of_supported_locations;
