const configLandTransferTax = {
  places: {
    Montreal: {
      region: "Montreal",
      url_name: "Montreal",
      txt:
        "<p>Like Toronto, Montrealers also have a <a href='https://ville.montreal.qc.ca/portal/page?_pageid=44,80257&amp;_dad=portal&amp;_schema=PORTAL' target='_blank' rel='nofollow'>separate municipal land transfer tax</a> to pay in addition to the provincial land transfer tax. But unlike Toronto, Montreal's tax calculations are a bit different from its province's.&nbsp;</p><ul><li>0.5% on the first $50,900</li><li>1% on the portion between $50,901 to $254,400</li><li>1.5% on the portion between $254,401 to $508,700</li><li>2% on the portion between $508,701 to $1,017,400</li><li>2.5% on amounts exceeding $1,017,400</li></ul>",
    },
    Toronto: {
      region: "Toronto",
      url_name: "Toronto",
      txt:
        "<p>In addition to the Ontario Land Transfer Tax, buyers in Toronto will have another transfer tax to pay – known as the <a href='https://www.toronto.ca/services-payments/property-taxes-utilities/municipal-land-transfer-tax-mltt/municipal-land-transfer-tax-mltt-rates-and-fees/' target='_blank' rel='nofollow'>Municipal Land Transfer Tax (MLTT)</a> – which has the same rates as the province of Ontario. So, essentially, the land transfer tax would be double when buying in Toronto.</p>",
    },
    Vancouver: {
      region: "Vancouver",
      url_name: "Vancouver",
      txt: "<p>Vancouver as British Columbia</p>",
    },
    AB: {
      region: "Alberta",
      url_name: "Alberta",
      txt:
        "<p>The province of Alberta doesn't charge a land transfer tax to homebuyers. Instead, it charges separate <a href='http://www.servicealberta.gov.ab.ca/pdf/ltmanual/TRANSFER_OF_LAND.pdf' target='_blank' rel='nofollow'>land and mortgage registration fees</a> of $50, plus $1 for every $5,000 of the property value. If there's a home loan involved, buyers would be charged another $50, plus an additional $1 for every $5,000 of the loan amount.</p>",
    },
    BC: {
      region: "British Columbia",
      url_name: "BritishColumbia",
      txt:
        "<p>The <a href='https://www2.gov.bc.ca/gov/content/taxes/property-taxes/property-transfer-tax' target='_blank' rel='nofollow'>land transfer tax rate in British Columbia</a> is:</p><ul><li>1% on the first $200,000</li><li>2% on the portion between $200,000 up to and including $2,000,000</li><li>3% on the portion greater than $2,000,000</li><li>2% on the portion greater than $3,000,000 if the property is residential</li></ul>",
    },
    MB: {
      region: "Manitoba",
      url_name: "Manitoba",
      txt:
        "<p>In Manitoba, the <a href='https://www.gov.mb.ca/finance/other/landtransfertax.html' target='_blank' rel='nofollow'>land transfer tax fees</a> are charged as follows:</p><ul><li>0% On the first $30,000</li><li>0.5% on the portion between $30,001 to $90,000</li><li>1% on the portion between $90,001 to $150,000</li><li>1.5% on the portion between $150,001 to $200,000</li><li>2% on amounts exceeding $200,000</li></ul>",
    },
    NB: {
      region: "New Brunswick",
      url_name: "NewBrunswick",
      txt:
        "<p>The <a href='http://laws.gnb.ca/en/ShowPdf/cs/R-2.1.pdf' target='_blank' rel='nofollow'>land transfer tax in New Brunswick</a> is relatively straightforward. Currently, the transfer tax payable is 1% of the property value, according to the Real Property Transfer Tax Act.</p>",
    },
    NL: {
      region: "Newfoundland and Labrador",
      url_name: "NewfoundlandAndLabrador",
      txt:
        "<p><a href='https://www.servicenl.gov.nl.ca/forms/files/fees_deed.pdf' target='_blank' rel='nofollow'>Newfoundland and Labrador's land transfer tax</a> is also known as the Registration of Deeds Act, which is calculated as follows:</p>",
    },
    NS: {
      region: "Nova Scotia",
      url_name: "NovaScotia",
      txt:
        "<p><a href='https://novascotia.ca/sns/pdf/ans-property-dtt-rates.pdf' target='_blank' rel='nofollow'>Land transfer taxes in Nova Scotia</a> work a bit differently than other provinces. In Nova Scotia, every municipality establishes its own land transfer tax – or “Deed Transfer Tax” – which ranges from 0.5% to 1.5% of the purchase price of a property:</p><ul><li>Annapolis – 0.5%</li><li>Antigonish – 1% in the municipality of the County of Antigonish, and 1.5% in the Town of Antigonish 1.5%</li><li>Cape Breton – 1.5%</li><li>Colchester – 1.5%&nbsp;</li><li>Cumberland – 1.5% in the Municipality of the County of Cumberland, 1.25% in the Town of Amherst, and 1% in the Town of Oxford</li><li>Digby – 0.5% in the Municipality of the District of Clare, 1% in the Municipality of the District of Digby, and 1.25% in the Town of Digby</li><li>Guysborough – 1% in the Municipality of the District of Guysborough, 1.25% in the Municipality of the District of St. Mary's, and 0.5% in the Town of Mulgrave</li><li>Halifax – 1.5%</li><li>Hants – 1.5%&nbsp;</li><li>Inverness – 1.5%</li><li>Kings – 0% in the Municipality of the County of Kings and Town of Kentville, 1% in the Town of Berwick, and 1.5% in the Town of Wolfville&nbsp;</li><li>Lunenburg – 1.5% in the Municipality of the District of Chester and Town of Bridgewater, 1.25% on the Municipality of the District of Lunenburg&nbsp; and Town of Mahone Bay, and 1% in the Town of Lunenburg</li><li>Pictou – 1%</li><li>Queens – 1%</li><li>Richmond – 1.5%</li><li>Shelburne – 1.5% in the Municipality of the District of Barrington, Municipality of the District of Shelburne, and Town of Lockeport, and 1% in the Town of Clark's Harbour</li><li>Victoria – 1%</li><li>Yarmouth – 1%</li></ul>",
    },
    ON: {
      region: "Ontario",
      url_name: "Ontario",
      txt:
        "<p>In Ontario, <a href='https://www.fin.gov.on.ca/en/bulletins/ltt/2_2005.html' target='_blank' rel='nofollow'>land transfer rates</a> are calculated as follows:</p><ul><li>0.5% on amounts up to and including $55,000</li><li>1% on the portion between $55,001 and $250,000</li><li>1.5% on the portion between $250,001 and $400,000</li><li>2% on the portion between $400,001 and $2 million</li><li>2.5% on the portion exceeding $2 million if the land has one or two single-family homes</li></ul>",
    },
    PE: {
      region: "Prince Edward Island",
      url_name: "PrinceEdwardIsland",
      txt:
        "<p><a href='https://www.princeedwardisland.ca/en/information/finance/real-property-transfer-tax-rate' target='_blank' rel='nofollow'>Prince Edward Island (PEI)</a> has a land transfer tax rate of 1% of the purchase price or property value, whichever is greater. Otherwise, there is no land transfer tax applicable to property values of less than $30,000.</p>",
    },
    SK: {
      region: "Saskatchewan",
      url_name: "Saskatchewan",
      txt:
        "<p>Like Alberta, Saskatchewan doesn't have a land transfer tax, but instead charges a <a href='https://www.isc.ca/SignedInHome/Fees/Pages/LandTitlesFees.aspx' target='_blank' rel='nofollow'>title transfer fee</a> to homebuyers that is calculated as follows:</p><ul><li>$0 for the first $1 to $500</li><li>$25 on the portion between $501 to $8,400</li><li>0.3% on the portion over $8,401</li></ul>",
    },
    QB: {
      region: "Quebec",
      url_name: "Quebec",
      txt:
        "<p>Quebec's land transfer tax calculations are based on the following:</p><ul><li>0.5% on the first $50,000</li><li>1% on the portion between $50,001 to $250,000</li><li>1.5% on the amount exceeding $250,000</li></ul>",
    },
  },
};

export default configLandTransferTax;
