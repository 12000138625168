import "../ChartRechart.scss";

import PropTypes from "prop-types";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import configChart from "../../../configs/configChart";
import CustomizedLegend from "../CustomizedLegend";
import CustomTooltip from "../CustomTooltip";
import { moneyView } from "../helpers";

const ChartBar = (props) => {
  const { data, width } = props;
  const { rechart_colors } = configChart;

  return (
    <div className="chart-rechart">
      <BarChart
        width={width}
        height={width / 2}
        data={data.data}
        margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey="name"
          label={{
            value: "years",
            position: "insideBottomRight",
            offset: 0,
            fill: "#000000",
          }}
        />
        <YAxis
          label={{
            value: "",
            position: "insideTop",
            offset: -20,
            fill: "#000000",
          }}
          tickFormatter={(tick) => {
            return moneyView(tick);
          }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" content={<CustomizedLegend />} />
        {data.codes.map((code, index) => {
          const i = index;
          const color = rechart_colors[i];
          return <Bar dataKey={code} key={code} fill={color} dot={false} />;
        })}
      </BarChart>
    </div>
  );
};

export default ChartBar;

ChartBar.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
