const configRebateMeta = {
  title: "Canada Best Mortgage rates",
  description: "Canada Best Mortgage rates",
  keywords:
    "Canada, Best, Mortgage, rates, mortgage, calculator, rent, buy, payments, best rate",
  // todo
  /*
  ON: {
    title: "Rebate Ontario",
    description: "Rebate Ontario",
    keywords: "Rebate Ontario",
  },
  */
};

export default configRebateMeta;
