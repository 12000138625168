import { Modal } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import GetMeta from "../GetMeta/GetMeta";
import HomePage from "../HomePage/HomePage";
import RatesDialog from "../RatesDialog/RatesDialog";
import { useUserLocation } from "../SelectRegion/selectRegionService";

const RatesPage = () => {
  const history = useHistory();
  const location = useUserLocation();

  useEffect(() => {
    Modal.info({
      content: <RatesDialog loc={location} />,
      okButtonProps: { style: { display: "none" } },
      className: "mortgage-rate",
      closable: true,
      maskClosable: true,
      onCancel: () => {
        history.replace("/");
      },
    });
  }, []);

  return (
    <div className="insurance-page">
      <GetMeta page="rates" />

      <HomePage />
    </div>
  );
};

export default RatesPage;
