const configRates = {
  banks: {
    "Meridian Credit Union": {
      img: "meridian-credit-union",
      link: "https://www.meridiancu.ca/Personal/Meridian-Rates-Fees.aspx",
    },
    "Alterna Saving": {
      img: "alterna-savings-credit-union",
      link: "https://www.alterna.ca/Rates/Mortgages/",
    },
    Motusbank: {
      img: "motusbank",
      link: "https://www.motusbank.ca/Accounts/Mortgages",
    },
    "Equitable Bank": {
      img: "equitable-bank",
      link: "https://www.equitablebank.ca/mortgage-rates",
    },
    Duca: {
      img: "duca",
      link: "https://www.duca.com/personal/mortgages",
    },
    "Scotia Bank": {
      img: "scotiabank",
      link: "https://www.scotiabank.com/ca/en/personal/mortgages.html",
    },
  },
};

export default configRates;
