/* eslint-disable import/prefer-default-export */
import configTFE from "../../configs/configTextForElements";
import { formatToMoney } from "../../helpers/commonHelpers";
// import analizeForInvestment from "../../lib/investmentComparasion";

const { divElements } = configTFE;
const short = divElements.summaryBlock;

/**
 * @param {object} payment  - data from mortgage.Calculator
 * @param {object} data  - data which input user to <Input>s
 * @returns {object} returned values for <Table >.summary-table
 */
export function usedSets(payment, data) {
  const { m_monthly_expenses, r_monthly_expenses } = payment;
  const { rent_or_buy } = data;
  const m_total = payment.principal_and_interest + m_monthly_expenses;
  const r_total = data.rent + r_monthly_expenses;

  const total = Math.max(m_total, r_total);
  const m_saving = total - m_total;
  const r_saving = total - r_total;

  const columns = [
    { title: short.divEmpty.text, dataIndex: "isTitle", key: "isTitle" },
    { title: short.div1.text, dataIndex: "isRent", key: "isRent" },
    { title: short.div2.text, dataIndex: "isBuy", key: "isBuy" },
  ];
  const monthly_payment_clear = payment.principal_and_interest;
  const monthly_total_clear =
    m_monthly_expenses + payment.principal_and_interest;
  const monthly_payment = formatToMoney(payment.principal_and_interest);
  const monthly_expenses = formatToMoney(m_monthly_expenses);
  const monthly_total = formatToMoney(m_total);
  const monthly_r_saving = formatToMoney(r_saving);
  const monthly_m_saving = formatToMoney(m_saving);

  const data_source = [
    {
      isTitle: short.div3.text,
      isRent: formatToMoney(data.rent),
      isBuy: monthly_payment,
    },
    {
      isTitle: short.div4.text,
      isRent: formatToMoney(r_monthly_expenses),
      isBuy: monthly_expenses,
    },
    {
      isTitle: short.div5.text,
      isRent: monthly_r_saving,
      isBuy: monthly_m_saving,
    },
  ];
  return {
    columns,
    data_source,
    monthly_payment,
    monthly_payment_clear,
    monthly_expenses,
    monthly_total,
    monthly_total_clear,
    rent_or_buy,
    r_total,
    m_total,
    monthly_r_saving,
    monthly_m_saving,
  };
}
