import "./CardComponent.scss";

import { Slider } from "antd";
import PropTypes from "prop-types";
import React from "react";

const ShowSlider = (props) => {
  const {
    value,
    prefix,
    suffix,
    min,
    max,
    step,
    onChange,
    onAfterChange,
    tipFormatter,
    show20,
  } = props;

  const marks = {
    [min]: tipFormatter(min, prefix, suffix),
    [max]: tipFormatter(max, prefix, suffix),
  };

  if (show20) {
    marks[20] = "20%";
  }

  return (
    <>
      <Slider
        marks={marks}
        tipFormatter={tipFormatter}
        onAfterChange={() => onAfterChange(value)}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        value={value}
      />
    </>
  );
};

export default ShowSlider;

ShowSlider.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  tipFormatter: PropTypes.func.isRequired,
  show20: PropTypes.bool,
};

ShowSlider.defaultProps = {
  prefix: "",
  suffix: "",
  show20: false,
};
