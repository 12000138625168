/**
 * @param {number} value - value in number
 * @param {number} percent - %
 * @returns {number}  % of value
 */
export default function returnMoneyValue(value, percent) {
  let result = (percent / 100) * value;
  // WTF ???
  if (typeof value === "object")
    result = (percent / 100) * parseFloat(value.target.value);
  return result;
}
