import configBlogMeta from "./configMeta/blogMeta";
import configCanadaMeta from "./configMeta/canadaMeta";
import configChartsMeta from "./configMeta/chartsMeta";
import configComparisonMeta from "./configMeta/comparisonMeta";
import configHomeMeta from "./configMeta/homeMeta";
import configInsuranceMeta from "./configMeta/insuranceMeta";
import configLttMeta from "./configMeta/landTransferTaxMeta";
import configPaymentsMeta from "./configMeta/paymentsMeta";
import configPostMeta from "./configMeta/postMeta";
import configRatesMeta from "./configMeta/ratesMeta";
import configRebateMeta from "./configMeta/rebateMeta";
import configUSAMeta from "./configMeta/usaMeta";

const configMeta = {
  title: "Mortgagio - green light for your Mortgage",
  content: "Mortgage calculator",
  blog: configBlogMeta,
  charts: configChartsMeta,
  comparison: configComparisonMeta,
  insurance: configInsuranceMeta,
  home: configHomeMeta,
  landTransferTax: configLttMeta,
  payments: configPaymentsMeta,
  post: configPostMeta,
  rebate: configRebateMeta,
  rates: configRatesMeta,
  canada: configCanadaMeta,
  usa: configUSAMeta,
};

export default configMeta;
