/* eslint-disable import/prefer-default-export */

import configPayments from "../configs/configPayments";
import { formatToMoney } from "./commonHelpers";

const { arrayMonths } = configPayments;

/**
 * get num and returned humanDate: today + <number> months
 * @param {integer} timestamp  - any number
 * @returns {string} returned formatted string. E.g. Feb-2022
 */
export function getDay(timestamp) {
  const current_date = new Date(timestamp);
  const num_day = current_date.getDate();
  const num_month = current_date.getMonth() % 12;
  const num_year =
    Math.floor((current_date.getMonth() / 12) % 12) +
    current_date.getFullYear();
  const new_date = `${num_day} ${arrayMonths[num_month]} ${num_year}`;
  return new_date;
}

/**
 * get num and returned humanDate: today + <number> months
 * @param {integer} plus_month  - any number
 * @returns {string} returned formatted string. E.g. Feb-2022
 */
export const getHumanDate = (val) => {
  const current_date = new Date();
  const num_month = (current_date.getMonth() + val) % 12;
  const num_year =
    Math.floor((current_date.getMonth() + val) / 12) +
    current_date.getFullYear();
  const new_date = `${arrayMonths[num_month]} ${num_year}`;
  return new_date;
};

export function formatNumberString(value) {
  const output = `#${value}: ${getHumanDate(value)}`;
  return output;
}

export function getOneString(data_object) {
  const output = {
    month: data_object.count,
    interest_payment: formatToMoney(data_object.interest_payment),
    total_interest: formatToMoney(data_object.total_interest),
    principal_payment: formatToMoney(data_object.principal_payment),
    total_payment: formatToMoney(data_object.total_payment),
    total_payments: formatToMoney(data_object.total_payments),
    balance: formatToMoney(data_object.balance),
    landlord_net_worth: formatToMoney(data_object.landlord_net_worth),
    renter_net_worth: formatToMoney(data_object.renter_net_worth),
    count: data_object.count,
  };
  return output;
}

/**
 * колонки для таблицы (с выбранным "Сompare to rent the same property" и без него)
 * полный список колонок в configPayments.js
 * @param {*} input_data
 * @param {*} flag
 */
export function getColumns(input_data, is_rent, is_year) {
  let output = input_data;
  if (!is_rent) {
    output = input_data.filter((el) => {
      return (
        el.field !== "renter_net_worth" && el.field !== "landlord_net_worth"
      );
    });
  }
  const field_month = input_data.filter((el) => {
    return el.field === "month";
  });
  field_month[0].label = is_year ? "Year" : "Month";

  return output;
}

export function getColumnsForPdf(input_data, flag) {
  let output = input_data;
  if (!flag) {
    output = input_data.filter((el) => {
      return (
        el.field !== "renter_net_worth" && el.field !== "landlord_net_worth"
      );
    });
  }
  const result = [[]];
  output.map((item) => {
    result[0] = [...result[0], item.label];
    return item;
  });

  return result;
}

export function getPaymentShedule(data_array) {
  let output = [];
  data_array.reduce((__str, currentItem) => {
    output = [...output, getOneString(currentItem)];
    return output;
  }, []);
  return output;
}

export function getOneStrPdf(data_object) {
  let result = [];
  result = [...result, formatNumberString(data_object.count)];
  result = [...result, formatToMoney(data_object.interest_payment)];
  result = [...result, formatToMoney(data_object.total_interest)];
  result = [...result, formatToMoney(data_object.principal_payment)];
  result = [...result, formatToMoney(data_object.total_payment)];
  result = [...result, formatToMoney(data_object.total_payments)];
  result = [...result, formatToMoney(data_object.balance)];
  result = [...result, formatToMoney(data_object.landlord_net_worth)];
  result = [...result, formatToMoney(data_object.renter_net_worth)];
  return result;
}

export function getPaymentSheduleForPdf(data_array) {
  let output = [];
  data_array.reduce((__str, currentItem) => {
    output = [...output, getOneStrPdf(currentItem)];
    return output;
  }, []);
  return output;
}
