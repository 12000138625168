const configLttMeta = {
  title: "Canadian Provincial Land Transfer Taxes",
  description: "Canadian Provincial Land Transfer Taxes",
  keywords: "Land Transfer Taxes",
  Montreal: {
    title: "Land Transfer Taxes Montreal",
    description: "Land Transfer Taxes Montreal",
    keywords: "Land Transfer Taxes Montreal",
  },
  Toronto: {
    title: "Land Transfer Taxes Toronto",
    description: "Land Transfer Taxes Toronto",
    keywords: "Land Transfer Taxes Toronto",
  },
  Vancouver: {
    title: "Land Transfer Taxes Vancouver",
    description: "Land Transfer Taxes Vancouver",
    keywords: "Land Transfer Taxes Vancouver",
  },
  AB: {
    title: "Land Transfer Taxes Alberta",
    description: "Land Transfer Taxes Alberta",
    keywords: "Land Transfer Taxes Alberta",
  },
  BC: {
    title: "Land Transfer Taxes British Columbia",
    description: "Land Transfer Taxes British Columbia",
    keywords: "Land Transfer Taxes British Columbia",
  },
  MB: {
    title: "Land Transfer Taxes Manitoba",
    description: "Land Transfer Taxes Manitoba",
    keywords: "Land Transfer Taxes Manitoba",
  },
  NB: {
    title: "Land Transfer Taxes New Brunswick",
    description: "Land Transfer Taxes New Brunswick",
    keywords: "Land Transfer Taxes New Brunswick",
  },
  NL: {
    title: "Land Transfer Taxes Newfoundland and Labrador",
    description: "Land Transfer Taxes Newfoundland and Labrador",
    keywords: "Land Transfer Taxes Newfoundland and Labrador",
  },
  NS: {
    title: "Land Transfer Taxes Nova Scotia",
    description: "Land Transfer Taxes Nova Scotia",
    keywords: "Land Transfer Taxes Nova Scotia",
  },
  ON: {
    title: "Land Transfer Taxes Ontario",
    description: "Land Transfer Taxes Ontario",
    keywords: "Land Transfer Taxes Ontario",
  },
  PE: {
    title: "Land Transfer Taxes Prince Edward Island",
    description: "Land Transfer Taxes Prince Edward Island",
    keywords: "Land Transfer Taxes Prince Edward Island",
  },
  SK: {
    title: "Land Transfer Taxes Saskatchewan",
    description: "Land Transfer Taxes Saskatchewan",
    keywords: "Land Transfer Taxes Saskatchewan",
  },
  QB: {
    title: "Land Transfer Taxes Quebec",
    description: "Land Transfer Taxes Quebec",
    keywords: "Land Transfer Taxes Quebec",
  },
};

export default configLttMeta;
