import "./DrawerComponentLeft.scss";

import { Divider, Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAddValue } from "../../actions";
import configTFE from "../../configs/configTextForElements";
import ComparisonTable from "../ComparisonTable/ComparisonTable";

const DrawerComponentLeft = () => {
  const dispatch = useDispatch();
  const { drawerBlock } = configTFE;
  const show_drawer = useSelector((state) => state.state.drawer);

  /**
   * toggle visible for Left Drawer
   * @param {boolean} val - flag
   * @param {toggleDrawer} callback - dispatch callback - set value state.state.drawer.
   */
  function setVisible(val) {
    dispatch(setAddValue({ name: "drawer", value: val }));
  }

  return (
    <div className="DrawerComponentLeft">
      <Drawer
        title={drawerBlock.title4.text}
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={show_drawer}
        getContainer={false}
        style={{ position: "absolute" }}
        width={540}
      >
        <ComparisonTable />
        <Divider />
      </Drawer>
    </div>
  );
};

export default DrawerComponentLeft;
