/* eslint-disable import/prefer-default-export */

import store from "../store";

/**
 * @returns {boolean} true if Canada selected
 */
export function isCanada() {
  const { location } = store.getState().state;
  return location.country === "Canada";
}
