import "./LandTransferTax.scss";

import React from "react";
import ReactHtmlParser from "react-html-parser";

import configLandTransferTax from "../../configs/configLandTransferTax";

const AllRegions = () => {
  const { places } = configLandTransferTax;

  return (
    <div className="all-regions">
      {Object.entries(places).map((item) => {
        const dt = item[1];
        return (
          <>
            <h3>{dt.region}</h3>
            {ReactHtmlParser(dt.txt)}
            <hr />
          </>
        );
      })}
    </div>
  );
};

export default AllRegions;
