/* eslint-disable no-undef */
import "./BlogAuthor.scss";

import PropTypes from "prop-types";
import React from "react";

import configBlog from "../../configs/configBlog";

const AuthorDetails = (props) => {
  const { id_author } = props;
  const author_data = configBlog.authors[id_author];

  return (
    <div className="Author">
      <div className="author-img">
        <img
          src={`/images/authors/${id_author}.jpg`}
          alt={author_data.name}
          title={author_data.name}
        />
      </div>
      <div className="author-dtl">
        <h5>Position: {author_data.position}</h5>
        <h5>Location: {author_data.location}</h5>
        <h5>Age: {author_data.age}</h5>
      </div>
    </div>
  );
};

export default AuthorDetails;

AuthorDetails.propTypes = {
  id_author: PropTypes.number.isRequired,
};
