import "./InsurancePage.scss";

import React from "react";

import configInsurance from "../../configs/configInsurance";
import { getCodeRegion, getLastPartUrl } from "../../helpers/commonHelpers";
import GetMeta from "../GetMeta/GetMeta";
import HomePage from "../HomePage/HomePage";
import InsurancePageFull from "../InsurancePageFull/InsurancePageFull";
import InsurancePageTable from "../InsurancePageTable/InsurancePageTable";

const InsurancePage = () => {
  const { places } = configInsurance;
  const name_page = "insurance";

  const wlh = window.location.href;
  const is_page_insurance = wlh.includes(name_page);
  const last_part_url = getLastPartUrl(wlh);

  const code_region = getCodeRegion(places, last_part_url, name_page);
  const show_full_page = last_part_url === name_page;

  return (
    <div className="insurance-page">
      <GetMeta page="insurance" region={code_region} />
      {is_page_insurance && (show_full_page || code_region.empty) && (
        <>
          <br />
          <InsurancePageFull />
        </>
      )}
      {is_page_insurance && !show_full_page && !code_region.empty && (
        <>
          <HomePage />
          <InsurancePageTable data={code_region} />
        </>
      )}
    </div>
  );
};

export default InsurancePage;
