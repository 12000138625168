const configInsurance = {
  places: {
    Montreal: {
      name: "Montreal",
      url_name: "Montreal",
    },
    Toronto: {
      name: "Toronto",
      url_name: "Toronto",
    },
    Vancouver: {
      name: "Vancouver",
      url_name: "Vancouver",
    },
    AB: {
      name: "Alberta",
      url_name: "Alberta",
    },
    BC: {
      name: "British Columbia",
      url_name: "BritishColumbia",
    },
    MB: {
      name: "Manitoba",
      url_name: "Manitoba",
    },
    NB: {
      name: "New Brunswick",
      url_name: "NewBrunswick",
    },
    NL: {
      name: "Newfoundland and Labrador",
      url_name: "NewfoundlandAndLabrador",
    },
    NS: {
      name: "Nova Scotia",
      url_name: "NovaScotia",
    },
    ON: {
      name: "Ontario",
      url_name: "Ontario",
    },
    PE: {
      name: "Prince Edward Island",
      url_name: "PrinceEdwardIsland",
    },
    SK: {
      name: "Saskatchewan",
      url_name: "Saskatchewan",
    },
    QB: {
      name: "Quebec",
      url_name: "Quebec",
    },
  },
  table_columns: [
    {
      title: "Down payment",
    },
    {
      title: "CMHC insurance premium",
    },
  ],
  table_data: [
    {
      downpayment: "less then 10%",
      insurance: "4 %",
    },
    {
      downpayment: "10% - 14.99%",
      insurance: "3.1 %",
    },
    {
      downpayment: "15% - 19.99%",
      insurance: "2.8 %",
    },
    {
      downpayment: "20% or higher",
      insurance: "0 %",
    },
  ],
};

export default configInsurance;
