import "./Mortgage.scss";

import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { getMortgageName } from "./helpers";

const ShowNameMortgage = (props) => {
  const { id_mortgage } = props;
  const mortgage_items = useSelector((state) => state.mortgage_items);
  const state_state = useSelector((state) => state.state);

  function selectedMortgage(obj1, obj2) {
    let output = "selected";
    Object.entries(obj1).forEach(([key, value]) => {
      if (typeof value === "number") {
        if (obj1[key] !== obj2[key]) output = "";
      }
    });
    return output;
  }

  return (
    <div className={selectedMortgage(mortgage_items[id_mortgage], state_state)}>
      <div>{getMortgageName(mortgage_items[id_mortgage])}</div>
      <span className="comment">
        {mortgage_items[id_mortgage].description[0]}
      </span>
    </div>
  );
};

export default ShowNameMortgage;

ShowNameMortgage.propTypes = {
  id_mortgage: PropTypes.number.isRequired,
};
