import "./RebatePage.scss";

import React from "react";

import configRebate from "../../configs/configRebate";
import { getCodeRegion, getLastPartUrl } from "../../helpers/commonHelpers";
import GetMeta from "../GetMeta/GetMeta";
import HomePage from "../HomePage/HomePage";
import RebatePageFull from "../RebatePageFull/RebatePageFull";
import RebatePageTable from "../RebatePageTable/RebatePageTable";

const RebatePage = () => {
  const { places } = configRebate;
  const name_page = "rebate";

  const wlh = window.location.href;
  const is_page_rebate = wlh.includes(name_page);
  const last_part_url = getLastPartUrl(wlh);

  const code_region = getCodeRegion(places, last_part_url, name_page);
  const show_full_page = last_part_url === name_page;

  return (
    <div className="RebatePage">
      <GetMeta page="rebate" region={code_region} />
      {is_page_rebate && (show_full_page || code_region.empty) && (
        <RebatePageFull />
      )}
      {is_page_rebate && !show_full_page && !code_region.empty && (
        <>
          <HomePage />
          <RebatePageTable data={code_region} />
        </>
      )}
    </div>
  );
};

export default RebatePage;
