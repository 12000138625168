import "./ForSeo.scss";

import React from "react";

import { getLastPartUrl } from "../../helpers/commonHelpers";
import GetMeta from "../GetMeta/GetMeta";
import HomePage from "../HomePage/HomePage";

const CountryUSA = () => {
  const name_page = "usa";

  const wlh = window.location.href;
  const last_part_url = getLastPartUrl(wlh);

  return (
    <div className="country-info">
      <GetMeta page={name_page} region={last_part_url} />
      <HomePage />
    </div>
  );
};

export default CountryUSA;
