/* eslint-disable import/prefer-default-export */
import base64 from "react-native-base64";
/**
 * create link with params from state
 * @param {array|string} data  - state.state or data
 */
export function copyURL(str) {
  const json = JSON.stringify(str);
  try {
    const json64 = `${window.location.host}?${base64.encode(json)}`;
    return json64;
  } catch (e) {
    return "";
  }
}

export function copyLinkMortgage(str) {
  const json = JSON.stringify(str);
  try {
    const json64 = `${window.location.host}?${base64.encode(json)}`;
    return json64;
  } catch (e) {
    return "";
  }
}

export function copyLinkTwoMortgages(str) {
  const json = JSON.stringify(str);
  try {
    const json64 = `${window.location.host}/comparison/?${base64.encode(json)}`;
    return json64;
  } catch (e) {
    return "";
  }
}
