import "./CardComponent.scss";

import { Card, Typography } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RoughNotation } from "react-rough-notation";

import config from "../../configs/configCards";
import { useActualState } from "../../helpers/getStateValues";
import CardInput from "./CardInput";
import {
  formattedData,
  getApproximatelyValue,
  getMinDownPayment,
  returnPercentValue,
} from "./helpers";
import returnMoneyValue from "./returnMoneyValue";

const CardComponent = (props) => {
  const { Text } = Typography;
  const {
    val,
    name,
    title,
    text,
    prefix,
    suffix,
    min,
    max,
    step,
    onChange,
  } = props;

  const property_value = useActualState("property");
  const { tip } = getMinDownPayment(property_value);
  // const [value, setValue] = useState(val);
  const [showTip, setShowTip] = useState(false);
  const [skipFirstTime, setSkipFirstTime] = useState(true);
  let showRoughNotationTimeout;

  // update inner value if val is changed
  /* useEffect(() => {
    setValue(val);
  }, [val]); */

  useEffect(() => {
    if (skipFirstTime) {
      setSkipFirstTime(false);
      return;
    }
    clearTimeout(showRoughNotationTimeout);
    if (showTip) {
      setShowTip(false);
      setTimeout(() => {
        setShowTip(true);
      }, 1111);
    } else {
      setShowTip(true);
      showRoughNotationTimeout = setTimeout(() => {
        setShowTip(false);
      }, 5555);
    }
  }, [tip]);

  /**
   * Change value if value isNumber
   */
  function onHandleChangeNumeric(inputValue) {
    const inputValueParsed = Math.abs(parseFloat(inputValue));
    console.log(inputValue, inputValueParsed);
    if (Number.isNaN(inputValueParsed)) {
      return;
    }
    onChange(inputValueParsed);
  }

  const { percentOf, percentOf_suffix, percentOf_prefix } = config[name];
  const percentObj = useSelector((state) => state.state[percentOf]);
  const dataPercentOf = percentOf ? percentObj : false;

  return (
    <div className={`CardComponent__root ${title.replace(/\s/g, "")}`}>
      <Card size="small" title={title}>
        <Card.Grid
          className="tlt"
          hoverable={false}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text type="secondary">{name !== "down_payment" && text}</Text>
          {name === "down_payment" && (
            <RoughNotation type="highlight" color="#d4f094" show={showTip}>
              <Text type="secondary">{tip}</Text>
            </RoughNotation>
          )}
        </Card.Grid>

        <CardInput
          name={name}
          id={name}
          prefix={prefix}
          suffix={suffix}
          step={step}
          min={min}
          max={max}
          show20={name === "down_payment"}
          value={parseFloat(val.toFixed(2))}
          onChange={(v) => onHandleChangeNumeric(v)}
          onAfterChange={onChange}
          formattedData={formattedData}
          onHandleChangeNumeric={onHandleChangeNumeric}
        />
        {percentOf && (
          <CardInput
            name={name}
            id={`d-${name}`}
            step={(property_value / 100) * step}
            prefix={percentOf_prefix}
            suffix={percentOf_suffix}
            min={returnMoneyValue(min, dataPercentOf)}
            max={returnMoneyValue(max, dataPercentOf)}
            value={parseFloat(returnMoneyValue(val, dataPercentOf).toFixed(2))}
            onChange={(v) => onChange(returnPercentValue(v, dataPercentOf))}
            onAfterChange={(v) => {
              onChange(returnPercentValue(v, dataPercentOf));
            }}
            formattedData={formattedData}
            onHandleChangeNumeric={(e) =>
              onHandleChangeNumeric(returnPercentValue(e, dataPercentOf))
            }
            percentOf
          />
        )}
        <div className="approximately">
          <Text type="secondary">
            {getApproximatelyValue(name, val, property_value)}
          </Text>
        </div>
      </Card>
    </div>
  );
};

export default CardComponent;

CardComponent.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  val: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
CardComponent.defaultProps = {
  text: "",
  prefix: "",
  suffix: "",
};
