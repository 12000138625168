import "../Mortgage.scss";

import { SaveTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useDispatch, useSelector } from "react-redux";

import { addMortgage, setAddValue } from "../../../actions";
import { useActualState } from "../../../helpers/getStateValues";
import { getHumanMessage, getMessage, saveItemMortgage } from "../helpers";
import ModalTableLogic from "../ModalTableLogic";

const AddMortgage = (props) => {
  const { mortgage } = props;
  const state_values = useActualState();

  const output_data = mortgage.rent ? mortgage : state_values;
  const necessary_data = _.omit(
    output_data,
    "collapses",
    "drawer",
    "charts",
    "switchers"
  );

  const mortgage_items = useSelector((state) => state.mortgage_items);
  const dispatch = useDispatch();

  const [visible, toggleVisible] = useState(false);
  const [text_area, setTextArea] = useState("");

  // const save_location = loc.code_name ? loc : location;
  /**
   * @see ant.modal
   * @param {string} callback
   */
  function showConfirm() {
    toggleVisible(!visible);
    setTextArea("");
  }

  /**
   * @param {object} obj - data from state.state
   * @param {saveItemMortgage | null} callback - add {element} to [state.mortgage_items]
   */
  function saveMortgage(obj) {
    if (mortgage_items.length < 10) {
      const record_to_state = obj;
      const descr = [];
      descr[0] = text_area; // массив, потому что этот параметр не участвует в расчетах. Отличительный тип данных (не "number")
      record_to_state.description = descr;
      const date_arr = [];
      date_arr[0] = new Date().getTime(); // массив, потому что этот параметр не участвует в расчетах. Отличительный тип данных (не "number")
      record_to_state.create_date = date_arr;

      dispatch(addMortgage(saveItemMortgage(record_to_state)));
      showConfirm();
      getMessage(getHumanMessage("addMortgage"), 2, "success");
      setTextArea("");
      dispatch(setAddValue({ name: "drawer", value: true }));

      ReactPixel.trackCustom("Save Morgage", {
        result: saveItemMortgage(record_to_state),
      });
    } else {
      getMessage(getHumanMessage("errorAddMortgage"), 2, "error");
      ReactPixel.trackCustom("Save Morgage", {
        result: "error",
      });
    }
  }

  function handleChange(event) {
    const new_text = event.target.value.substr(0, 500);
    setTextArea(new_text);
  }

  return (
    <>
      <Button
        onClick={showConfirm}
        type="link"
        icon={<SaveTwoTone />}
        aria-label="Save"
      >
        Save
      </Button>
      <Modal
        title="Save this mortgage?"
        visible={visible}
        onOk={() => saveMortgage(necessary_data)}
        onCancel={() => showConfirm()}
        okText="YES"
        cancelText="NO"
      >
        <div className="wrapper-table-modal">
          <ModalTableLogic id={-1} data={necessary_data} />
          <p>Description</p>
          <TextArea
            autoFocus
            value={text_area}
            onChange={(e) => handleChange(e)}
            placeholder="Description, link to website etc."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddMortgage;

AddMortgage.propTypes = {
  mortgage: PropTypes.objectOf(PropTypes.any),
};

AddMortgage.defaultProps = {
  mortgage: {},
};
