import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";

export const getCashNeededTable = (values, lib) => {
  const result = lib.reduce((str, current) => {
    const val =
      current.code === "lawyer_fees" || current.code === "title_insurance"
        ? `~${formatToMoney(values[current.code])}`
        : formatToMoney(values[current.code]);
    str.push([current.title, val]);
    return str;
  }, []);
  return result;
};

export const getSummaryTable = (values, lib) => {
  const result = [];
  result.push([lib.property, formatToMoney(values.property)]);
  const down_payment = `${addSuffix(
    values.down_payment,
    "%"
  )} = ${formatToMoney((values.property / 100) * values.down_payment)}`;
  result.push([lib.down_payment, down_payment]);
  result.push([lib.borrowing, formatToMoney(values.RESULT.loan_amount)]);
  result.push([lib.mortgage_rate, addSuffix(values.mortgage_rate, "%")]);
  result.push([
    lib.monthly_payment,
    addSuffix(formatToMoney(values.RESULT.total), " / month"),
  ]);
  result.push([
    lib.monthly_total,
    addSuffix(
      formatToMoney(values.RESULT.m_monthly_expenses + values.RESULT.total),
      " / month"
    ),
  ]);
  result.push([lib.period, addSuffix(values.amortization, " years")]);
  return result;
};
