import "./RightPart.scss";

import { OrderedListOutlined } from "@ant-design/icons";
import { Button, Divider, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ResizeDetector from "react-resize-detector";
import { RoughNotation } from "react-rough-notation";
import { Link, useHistory } from "react-router-dom";

import { setValue } from "../../actions";
import { formatToMoney, isMobile } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import CashNeeded from "../CashNeeded/CashNeeded";
import ChartRechart from "../ChartRechart/ChartRechart";
import { getDataToRechart, scheduleForYears } from "../ChartRechart/helpers";
import CopyLink from "../CopyLink/CopyLink";
import AddMortgage from "../Mortgage/AddMortgage/AddMortgage";
import SelectRegion from "../SelectRegion/SelectRegion";
import { usedSets } from "./helper";
import InvestmentCompResult from "./InvestmentCompResult";

let show_rough_notation_timeout;
let skip_first = true;
const RightPart = () => {
  const parentRef = React.createRef();
  // У Ж А С
  const data = useActualState();
  const history = useHistory();
  const [show_rough_notation, setShowRoughNotation] = useState(false);
  const [width_rechart, setWidthRechart] = useState(0);
  const payment = useActualState("RESULT");
  const {
    monthly_payment,
    monthly_expenses,
    monthly_total,
    r_total,
    m_total,
    rent_or_buy,
  } = usedSets(payment, data);

  useEffect(() => {
    if (skip_first) {
      skip_first = false;
      return;
    }
    clearTimeout(show_rough_notation_timeout);
    setShowRoughNotation(true);
    show_rough_notation_timeout = setTimeout(() => {
      setShowRoughNotation(false);
    }, 4444);
  }, [monthly_total]);

  const dispatch = useDispatch();
  const setCompare = (v) => {
    dispatch(setValue("rent_or_buy", v));
    if (v) window.scrollTo(0, 300);
  };

  function isChecked(val) {
    const new_val = val === 1;
    return new_val;
  }

  function setSwitch(val) {
    const new_val = val === true ? 1 : 0;
    setCompare(new_val);
  }
  function onResize(width) {
    setWidthRechart(width);
  }

  const data_to_rechart = getDataToRechart(
    scheduleForYears(payment.payment_schedule),
    ["renter_net_worth", "landlord_net_worth"],
    "Line"
  );

  const data_to_rechart2 = getDataToRechart(
    scheduleForYears(payment.payment_schedule),
    ["interest_payment", "principal_payment"],
    "Bar"
  );

  return (
    <div className="RightPart div-wrapper mainContent">
      <SelectRegion />
      <div className="ant-collapse ant-collapse-item-active RightPart-body MonthlyPayment">
        <h3 className="ant-collapse-header text">
          Your <span className="ant-collapse-extra">monthly</span>{" "}
          <span className="ant-collapse-extra">payment</span> will be:{" "}
          <span className="ant-collapse-extra">{monthly_payment}</span>
        </h3>
        <div className="text2">
          Additionaly you will need{" "}
          <span className="ant-collapse-extra">{monthly_expenses}</span> for
          other taxes and expenses per month.
        </div>
        <h4 className="text PerMonth">
          <RoughNotation type="box" color="red" show={show_rough_notation}>
            so total is ~{" "}
            <span className="ant-collapse-extra">{monthly_total}</span> per
            month
          </RoughNotation>
        </h4>
        <div className="wrapper-btns">
          <AddMortgage />
          <Divider className="divider" type="vertical" />
          <CopyLink />
          <Divider className="divider" type="vertical" />
          <Button
            onClick={() => history.push("/payments")}
            type="link"
            icon={<OrderedListOutlined />}
            aria-label="Payments"
          >
            Payments
          </Button>
        </div>
      </div>
      <CashNeeded />
      <div className="ant-collapse ant-collapse-item-active RightPart-body">
        <div
          className="ant-collapse-header"
          style={{ textAlign: "center", paddingBottom: 10 }}
        >
          {isMobile() && (
            <>
              <span style={{ margin: 10 }}>
                Compare to rent the same property
              </span>
              <Switch
                checked={isChecked(rent_or_buy)}
                onChange={(checked) => setSwitch(checked)}
              />
            </>
          )}
          {!!rent_or_buy && (
            <>
              <InvestmentCompResult payment={payment} />
              <div ref={parentRef} className="wrapper-rechart">
                <ChartRechart width={width_rechart} data={data_to_rechart} />
              </div>
              {m_total - r_total > 0 && (
                <>
                  <p>
                    While renting you will be paying {formatToMoney(r_total)}{" "}
                    per month which is {formatToMoney(m_total - r_total)} less
                    compare to your payments in case of buying the same
                    property.
                  </p>
                  <p>
                    If you save and invest this difference you could increase
                    your Net Worth.
                  </p>
                </>
              )}

              {m_total - r_total <= 0 && (
                <>
                  <p>
                    While renting you will be paying {formatToMoney(r_total)}{" "}
                    per month which is {formatToMoney(r_total - m_total)} more
                    compare to your payments in case of buying the same
                    property.
                  </p>
                  <p>
                    So definately buying looks like much more better option for
                    you.
                  </p>
                </>
              )}
            </>
          )}
        </div>

        {!rent_or_buy && (
          <>
            <div className="summary-advice">
              <p>
                For details, please look on the chart below or go to the{" "}
                <Link to="/payments">payments section.</Link>
              </p>
            </div>
            <div className="wrapper-rechart">
              <ChartRechart width={width_rechart} data={data_to_rechart2} />
            </div>
          </>
        )}
      </div>
      <ResizeDetector
        handleWidth
        handleHeight
        onResize={onResize}
        targetDomEl={parentRef.current}
      />
    </div>
  );
};

export default RightPart;
