import PropTypes from "prop-types";
import React from "react";

import { chooseFormat } from "../../helpers/commonHelpers";
import { formatLocationData, getFlagLocation } from "./helpers";

const ModalTable = (props) => {
  // id === -1, if we add new mortgage (save item)
  const { data } = props;

  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Property</td>
          <td>{chooseFormat("propertyValue", data.property)}</td>
        </tr>
        <tr>
          <td>Down Payment (%)</td>
          <td>{chooseFormat("down_payment_proc", data.down_payment_proc)}</td>
        </tr>
        <tr>
          <td>Down Payment ($)</td>
          <td>{chooseFormat("down_payment_money", data.down_payment_money)}</td>
        </tr>
        <tr>
          <td>Mortgage Rate</td>
          <td>{chooseFormat("mortgage_rate", data.mortgage_rate)}</td>
        </tr>
        <tr>
          <td>Monthly Payment</td>
          <td>{chooseFormat("monthly_payment", data.monthly_payment)}</td>
        </tr>
        <tr>
          <td>Monthly Total</td>
          <td>{chooseFormat("monthly_total", data.monthly_total)}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>
            {getFlagLocation(data)}
            {formatLocationData(data.location)}
          </td>
        </tr>
        <tr>
          <td>Term Years</td>
          <td>{data.amortization} years</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ModalTable;

ModalTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

ModalTable.defaultProps = {
  data: {},
};
