import "./Payments.scss";

import { Col, Row } from "antd";
import React from "react";

import configPayments from "../../configs/configPayments";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import { formatLocationData } from "../Mortgage/helpers";
import { useUserLocation } from "../SelectRegion/selectRegionService";

const PaymentSummary = () => {
  const state_values = useActualState();
  const result_value = useActualState("RESULT");
  const location = useUserLocation();
  const { locationText, paymentSummary } = configPayments;

  const down_payment = formatToMoney(
    (state_values.property * state_values.down_payment) / 100
  );
  const mortgage_rate = addSuffix(state_values.mortgage_rate, "%");
  const amortization = `${state_values.amortization} years`;
  return (
    <div className="summary-card">
      <Row className="txt-right">
        <Col span={12}>{locationText} : </Col>
        <Col span={12}>{formatLocationData(location)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={12}>{paymentSummary.property} : </Col>
        <Col span={12}>{formatToMoney(state_values.property)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={12}>{paymentSummary.down_payment} : </Col>
        <Col span={12}>{down_payment}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={12}>{paymentSummary.borrowing} : </Col>
        <Col span={12}>{formatToMoney(result_value.loan_amount)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={12}>{paymentSummary.mortgage_rate} : </Col>
        <Col span={12}>{mortgage_rate}</Col>
      </Row>
      <Row className="txt-right without-border">
        <Col span={12}>{paymentSummary.period} : </Col>
        <Col span={12}>{amortization}</Col>
      </Row>
    </div>
  );
};

export default PaymentSummary;
