import "./Insurance.scss";

import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { formatToMoney } from "../../helpers/commonHelpers";
import { isCanada } from "../../helpers/helper";
import { useUserLocation } from "../SelectRegion/selectRegionService";
import { getCmhcInsurance, locationToUrl } from "./helpers";

const Insurance = () => {
  const location = useUserLocation();
  const down_payment = useSelector((state) => state.state.down_payment);
  const property_value = useSelector((state) => state.state.property);
  const insurance = getCmhcInsurance(property_value, down_payment);

  if (!isCanada()) {
    return "";
  }

  return (
    <div className="question">
      <NavLink to={locationToUrl(location)} exact>
        <QuestionCircleOutlined />
      </NavLink>
      Insurance: {insurance.insurance_percent}% (
      {formatToMoney(insurance.insurance_value)})
    </div>
  );
};

export default Insurance;
