import "./Comparison.scss";

import { Button, Card, InputNumber } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { initialStateWithLimits } from "../../reducers/baseReducer";
import { checkFieldIsMoney, returnFormatter } from "../CardComponent/helpers";

const NumericInput = (props) => {
  const { value, name, onChange, prefix, suffix, index } = props;
  const [isEditMode, setEditMode] = useState(false);
  const { min, max, step } = initialStateWithLimits[name];

  const [textInput, setTextInput] = useState(null);
  const setTextInputRef = (element) => {
    setTextInput(element);
    if (textInput) {
      textInput.focus();
      textInput.position = 3;
    }
  };
  function handleClick() {
    setEditMode(true);
  }

  const onBlur = () => {
    setEditMode(false);
    // onAfterChange(value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onBlur();
    }
  };
  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <>
      <Card.Grid
        hoverable={false}
        className={`trick-input ${checkFieldIsMoney(prefix)}`}
      >
        <div className="input-elements-wrapper">
          {!isEditMode && (
            <div className="span-holder span-value">
              <Button
                type="link"
                onClick={(e) => handleClick(e.target)}
                aria-label="value"
              >
                {returnFormatter(value, prefix, suffix)}
              </Button>
            </div>
          )}
          {isEditMode && (
            <>
              {prefix && <div className="pre-suf prefix">{prefix}</div>}
              <InputNumber
                className={`be-input ${prefix.length > 0 ? "left-25" : ""}`}
                ref={setTextInputRef}
                value={value}
                name={name}
                step={step}
                min={min}
                max={max}
                // formatter={valIn => returnFormatter(valIn, prefix, suffix)}
                onChange={(e) => onChange({ name, new_value: e, index })}
                onBlur={onBlur}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyPress(e)}
              />
              {suffix && <div className="pre-suf suffix">{suffix}</div>}
            </>
          )}
        </div>
      </Card.Grid>
    </>
  );
};

export default NumericInput;

NumericInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  index: PropTypes.number,
};

NumericInput.defaultProps = {
  prefix: "",
  suffix: "",
  index: 0,
};
