/* eslint-disable no-param-reassign */

import returnMoneyValue from "../components/CardComponent/returnMoneyValue";

// TODO tests + refactoring
export default function analizeForInvestment(data, payment) {
  const cost_of_buying = data.buying_home / 100; // %
  const cost_of_buying_value = cost_of_buying * data.property;
  const cost_of_selling = data.selling_home / 100; // %
  /**
   * Percent from original property and current property
   * we need this cause home price will be different with a time
   * @param {number} current_property_value
   */
  const totalSellingExpenses = (current_property_value) => {
    const cost_of_selling_value = cost_of_selling * current_property_value;
    return cost_of_selling_value;
  };

  const rate_of_growth_value = data.rate_of_growth / 100;
  const rent_annual_increase_value = data.rent_annual_increase / 100;

  const total_price = data.property;
  // to buy you need money for down payment + closing costs
  const initial_net_worth =
    cost_of_buying_value + returnMoneyValue(data.down_payment, total_price);

  // if buyer will decide to sell it right away after buying
  // he will pay selling costs also
  const initial_landlord_net_worth =
    initial_net_worth - totalSellingExpenses(total_price);

  // when renting you should pay upfront first and last month + insurance for first month
  const initial_renter_net_worth =
    initial_net_worth - 2 * data.rent - data.renters_insurance;

  payment.initial_renter_net_worth = initial_renter_net_worth;
  payment.initial_landlord_net_worth = initial_landlord_net_worth;

  const period_interest = 1 + data.return_investment / 12 / 100;

  // eslint-disable-next-line no-param-reassign
  payment.payment_schedule.forEach((el, idx) => {
    const month_count = el.count;
    const year_count = month_count / 12;
    const current_property_value =
      total_price * (1 + rate_of_growth_value) ** year_count;
    el.current_property_value = current_property_value;
    el.total_selling_expenses = totalSellingExpenses(current_property_value);
    const landlord_net_worth =
      current_property_value -
      el.balance -
      el.total_selling_expenses -
      cost_of_buying_value;
    el.landlord_net_worth = landlord_net_worth;

    if (idx > 0) {
      const this_year_price =
        el.count % 12 === 0
          ? current_property_value
          : payment.payment_schedule[idx - 1].this_year_price;
      el.this_year_price = this_year_price;
    } else {
      el.this_year_price = total_price;
    }

    const monthly_maintenance_value =
      (el.this_year_price * (data.maintenance / 12)) / 100;
    const monthly_taxes =
      (el.this_year_price * (data.amount_annual_taxes / 12)) / 100;
    const monthly_owner_insurance_value =
      (el.this_year_price * (data.owner_insurance / 12)) / 100;
    const monthly_other_expenses =
      data.annual_heating_costs / 12 + data.another_monthly_expenses;

    const taxes_and_maintenance =
      monthly_maintenance_value +
      monthly_taxes +
      monthly_owner_insurance_value +
      monthly_other_expenses;
    el.taxes_and_maintenance = taxes_and_maintenance;

    const total_monthly_payment = el.total_payment + taxes_and_maintenance;

    el.total_monthly_payment = total_monthly_payment;

    if (idx > 0) {
      const this_year_rent_value =
        el.count % 12 === 0
          ? data.rent * (1 + rent_annual_increase_value) ** year_count
          : payment.payment_schedule[idx - 1].this_year_rent_value;
      el.this_year_rent_value = this_year_rent_value;
    } else {
      el.this_year_rent_value = data.rent;
    }

    const monthly_renter_expenses =
      el.this_year_rent_value + data.renters_insurance;

    const r_saving = total_monthly_payment - monthly_renter_expenses;
    el.r_saving = r_saving;

    const prev_renter_net_worth =
      idx > 0
        ? payment.payment_schedule[idx - 1].renter_net_worth
        : initial_renter_net_worth;
    const renter_net_worth = prev_renter_net_worth * period_interest + r_saving;

    el.renter_net_worth = renter_net_worth;
  });
}
