/* eslint-disable import/prefer-default-export */

/**
 * Set prop.disabled for button-link to page "Comparison"
 * @param {array} arr - array of check-boxes
 * @returns {string} "disabled" or ""
 */
export function showBtnComparison(arr) {
  const is_show = arr.reduce((str, currentItem) => {
    return currentItem === true ? str + 1 : str;
  }, 0);
  let result = "disabled";
  if (is_show > 1 && is_show < 3) result = "";
  return result;
}
