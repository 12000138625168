import "./LeftPart.scss";

import { DownOutlined, LineChartOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Collapse, Switch } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { RoughNotation } from "react-rough-notation";

import { setAddValue, setGroup } from "../../actions";
import config from "../../configs/configCards";
import configTFE from "../../configs/configTextForElements";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import { useActualState } from "../../helpers/getStateValues";
import CardComponentLogic from "../CardComponent/CardComponentLogic";
import returnMoneyValue from "../CardComponent/returnMoneyValue";
import Insurance from "../Insurance/Insurance";
import SelectRegion from "../SelectRegion/SelectRegion";
import TextPanel from "../TextPanel/TextPanel";
import { getFullDescription, getPerMonth } from "./helper";

const LeftPart = () => {
  const { Panel } = Collapse;
  const { divElements } = configTFE;
  const active_keys_first = useActualState("collapses").calculator_1;
  const active_keys_second = useActualState("collapses").calculator_2;
  const payment = useActualState("RESULT");

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const texts = ["Expand all", "Collapse all"];
  const [show_text_collapse_all, setShowTextCollapseAll] = useState(1);
  const state = useActualState();
  const dispatch = useDispatch();
  const compare = state.rent_or_buy;
  const setCompare = (v) => {
    dispatch(setAddValue({ name: "rent_or_buy", value: v }));
  };
  const lastTime = window.localStorage.getItem("lastTime", 0);
  const now = Date.now();
  const firstTimeForAWhile = now - lastTime > 7 * 24 * 60 * 1000;
  if (firstTimeForAWhile) {
    window.localStorage.setItem("lastTime", now);
    setTimeout(forceUpdate, 4444);
  }

  function toggleTabsView(num) {
    let key1 = [];
    let key2 = [];
    if (num === 0) {
      key1 = ["1", "2", "3"];
      key2 = ["1", "2", "3", "4", "5", "6"];
      setShowTextCollapseAll(1);
    } else {
      setShowTextCollapseAll(0);
    }
    dispatch(
      setGroup({ group: "collapses", name: "calculator_1", value: key1 })
    );
    dispatch(
      setGroup({ group: "collapses", name: "calculator_2", value: key2 })
    );
  }

  function fCollapse(key) {
    dispatch(
      setGroup({ group: "collapses", name: "calculator_1", value: key })
    );
    setShowTextCollapseAll(1);
  }

  function sCollapse(key) {
    dispatch(
      setGroup({ group: "collapses", name: "calculator_2", value: key })
    );
    setShowTextCollapseAll(1);
  }

  function isChecked(val) {
    const new_val = val === 1;
    return new_val;
  }

  function setSwitch(val) {
    const new_val = val === true ? 1 : 0;
    setCompare(new_val);
  }

  return (
    <div className="LeftPart div-wrapper">
      <div className="topBlock">
        <div className="expand_all">
          <Button
            type="link"
            icon={show_text_collapse_all ? <UpOutlined /> : <DownOutlined />}
            onClick={() => toggleTabsView(show_text_collapse_all)}
            aria-label="expand all"
          >
            <div>{texts[show_text_collapse_all]}</div>
          </Button>
        </div>
        <div className="mobile">
          <SelectRegion />
        </div>
      </div>
      <form className="mainContent">
        <Collapse activeKey={active_keys_first} onChange={fCollapse}>
          <Panel
            header={divElements.titles.title1.text}
            extra={<>Borrowing: {formatToMoney(payment.total_price)}</>}
            key="1"
          >
            <RoughNotation
              type="box"
              animationDuration={2000}
              iterations={5}
              color="#81b526"
              show={firstTimeForAWhile}
            >
              {firstTimeForAWhile && (
                <span
                  style={{ color: "#81b526", position: "absolute", left: 160 }}
                >
                  ↓ Start here
                </span>
              )}
              <CardComponentLogic name="property" />
            </RoughNotation>

            <CardComponentLogic name="down_payment" />
            {state.down_payment < 20 && <Insurance />}
          </Panel>
          <Panel
            header={divElements.titles.title2.text}
            extra={
              <>
                {getFullDescription(state.amortization, "amortization")}
                {"   "}
                {getFullDescription(state.mortgage_rate, "mortgage_rate")}
              </>
            }
            key="2"
          >
            <TextPanel num="2" />
            <CardComponentLogic name="amortization" />
            <CardComponentLogic name="mortgage_rate" />
          </Panel>
          <Panel
            header={divElements.titles.title5.text}
            extra={`${formatToMoney(payment.m_monthly_expenses)} per month`}
            key="3"
          >
            <TextPanel num="5" />
            <CardComponentLogic name="amount_annual_taxes" />
            <CardComponentLogic name="maintenance" />
            <CardComponentLogic name="owner_insurance" />
            <CardComponentLogic name="annual_heating_costs" />
            <CardComponentLogic name="another_monthly_expenses" />
          </Panel>
        </Collapse>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <div
            className="ant-collapse-header user-switch"
            style={{
              textAlign: "center",
              padding: "10px 0",
            }}
          >
            <span style={{ margin: 10 }}>
              Compare to rent the same property
            </span>
            <Switch
              className="show-part"
              checked={isChecked(compare)}
              onChange={(checked) => setSwitch(checked)}
            />
          </div>

          <div
            onClick={() => {
              setCompare(true);
              setSwitch(true);
            }}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
            aria-label="collapse"
          >
            <Collapse
              style={{ opacity: compare ? "1.0" : "0.25" }}
              activeKey={active_keys_second}
              onChange={sCollapse}
            >
              <Panel
                header={config.rent.title}
                extra={getPerMonth(state.rent)}
                key="1"
              >
                <CardComponentLogic name="rent" />
                <CardComponentLogic name="rent_annual_increase" />
              </Panel>
              <Panel
                header={config.renters_insurance.title}
                extra={getPerMonth(state.renters_insurance)}
                key="2"
              >
                <CardComponentLogic name="renters_insurance" />
              </Panel>
              <Panel
                header={
                  <>
                    {config.return_investment.title}{" "}
                    <LineChartOutlined style={{ color: "green" }} />
                  </>
                }
                extra={addSuffix(state.return_investment, " %")}
                key="3"
              >
                <TextPanel num="7" />
                <CardComponentLogic name="return_investment" />
                <TextPanel num="8" />
              </Panel>
              <Panel
                header={
                  <>
                    {divElements.titles.title6.text}{" "}
                    <LineChartOutlined style={{ color: "green" }} />
                  </>
                }
                extra={addSuffix(state.rate_of_growth, " %")}
                key="4"
              >
                <TextPanel num="6" />
                <CardComponentLogic name="rate_of_growth" />
              </Panel>
              <Panel
                header={config.buying_home.title}
                extra={formatToMoney(
                  returnMoneyValue(state.property, state.buying_home)
                )}
                key="5"
              >
                <CardComponentLogic name="buying_home" />
              </Panel>
              <Panel
                header={config.selling_home.title}
                extra={formatToMoney(
                  returnMoneyValue(state.property, state.selling_home)
                )}
                key="6"
              >
                <CardComponentLogic name="selling_home" />
              </Panel>
            </Collapse>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LeftPart;
