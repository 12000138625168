import { EDIT_MORTGAGE } from "./types";

export default function editMortgage(obj) {
  return {
    type: EDIT_MORTGAGE,
    payload: {
      obj,
    },
  };
}
