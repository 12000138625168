import "../Mortgage.scss";
import "./ShowOneMortgage.scss";

import { ArrowDownOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  editMortgage,
  setAddValue,
  setCashNeeded,
  uploadMortgage,
} from "../../../actions";
import { getDay } from "../../../helpers/paymentHelpers";
import {
  getHumanMessage,
  getMessage,
  getMortgageName,
  getNewRow,
} from "../helpers";
import ModalTableLogic from "../ModalTableLogic";

const ShowOneMortgage = (props) => {
  const { id } = props;
  const mortgage_items = useSelector((state) => state.mortgage_items);
  const [navigate, setNavigate] = useState(false);

  const dispatch = useDispatch();
  const [visible, toggleVisible] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const not_empty_description = mortgage_items[id].description[0]
    ? mortgage_items[id].description[0]
    : "";
  const [new_description, getNewDescription] = useState(not_empty_description);

  /**
   * @see ant.modal
   * @param {string} callback
   */
  function showConfirm() {
    toggleVisible(!visible);
  }

  function handleChange(event, num) {
    const text = event.target.value;
    getNewDescription(text);
    const new_mortgage = {
      num,
      text,
    };
    dispatch(editMortgage(new_mortgage));
  }

  /**
   * @param {integer} num - [state.mortgage_items{num}]
   * @param {uploadMortgage} callback - dispatch callback - set value state.state.
   */
  function toUploadMortgage(num) {
    const new_values = mortgage_items.filter((_number, index) => index === num);
    const setstate_secondstate_location = new_values[0].location;
    const setstate_secondstate_rebate = new_values[0].rebate;
    const set_state_state = _.omit(new_values[0], "location", "rebate");
    dispatch(uploadMortgage(set_state_state));
    dispatch(
      setCashNeeded({ name: "location", value: setstate_secondstate_location })
    );
    dispatch(
      setCashNeeded({ name: "rebate", value: setstate_secondstate_rebate })
    );
    showConfirm();
    getMessage(getHumanMessage("uploadMortgage"), 2, "success");
    setNavigate(true);
    dispatch(setAddValue({ name: "drawer", value: false }));
  }

  function handleClick() {
    setEditMode(true);
  }

  const onBlur = () => {
    setEditMode(false);
  };

  return (
    <div className="Mortgage">
      {navigate ? <Redirect to="" /> : null}
      <Button
        className={getNewRow(mortgage_items[id].create_date[0] / 1000)}
        type="link"
        title="Details"
        onClick={showConfirm}
        aria-label="Details"
      >
        <EyeTwoTone />
      </Button>
      <Modal
        className="mortgage-modal"
        title="Upload data from mortgage?"
        visible={visible}
        onOk={() => toUploadMortgage(id)}
        onCancel={() => showConfirm()}
        okText="YES"
        cancelText="NO"
      >
        <h3>{getMortgageName(mortgage_items[id])}</h3>
        <div className="wrapper-table-modal">
          <ModalTableLogic id={id} />
        </div>
        {!isEditMode && (
          <>
            <Divider orientation="left" onClick={(e) => handleClick(e.target)}>
              For edit click on Description <ArrowDownOutlined />
              <ArrowDownOutlined />
              <ArrowDownOutlined />
            </Divider>
            <div
              onClick={(e) => handleClick(e.target)}
              className="div-tip"
              role="button"
              onKeyDown={(e) => handleClick(e.target)}
              tabIndex="0"
              aria-label="Description"
            >
              <pre>{new_description}</pre>
            </div>
          </>
        )}
        {isEditMode && (
          <>
            <Divider orientation="left">Description:</Divider>
            <TextArea
              autoFocus
              className="textarea-tip"
              value={new_description}
              onChange={(e) => handleChange(e, id)}
              placeholder="Description"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onBlur={onBlur}
            />
          </>
        )}
        <Divider />
        <h6 className="created-at">{`Created at ${getDay(
          mortgage_items[id].create_date[0]
        )}`}</h6>
      </Modal>
    </div>
  );
};

export default ShowOneMortgage;

ShowOneMortgage.propTypes = {
  id: PropTypes.number.isRequired,
};
