const configRebate = {
  places: {
    ON: {
      name: "Ontario",
      url_name: "Ontario",
      maximum: 4000,
      cover: 368333,
      refund: "50% refund if spouse does not qualify",
    },
    Toronto: {
      name: "Toronto",
      url_name: "Toronto",
      maximum: 4475,
      cover: 368333,
      refund: "50% refund if spouse does not qualify",
      tip: {
        num: 1,
        txt:
          "The Toronto rebate can be claimed in addition to the Ontario rebate.",
      },
    },
    BC: {
      name: "British Columbia",
      url_name: "BritishColumbia",
      maximum: 8000,
      cover: 500000,
      refund: "Partial refund for homes valued between $500,001 - $524,999",
    },
    PE: {
      name: "Prince Edward Island",
      url_name: "PrinceEdwardIsland",
      maximum: 2000,
      cover: 200000,
      refund: "No",
    },
  },
  description: {
    maximum: "Maximum rebate",
    cover: "Rebate will cover LTT to a maximum home value of",
    refund: "Partial refund available?",
    tip: "References and Notes",
  },
};

export default configRebate;
