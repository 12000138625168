const configPayments = {
  arrayMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  locationText: "Location",
  paymentSummary: {
    property: "Property value",
    down_payment: "Down payment",
    borrowing: "Borrowing",
    mortgage_rate: "Mortgage Rate",
    monthly_payment: "Monthly Payment",
    monthly_total: "Monthly Total",
    period: "Period",
  },
  generalDataTitle: [
    { header: "Month", field: "month", dataKey: "month" },
    {
      header: "Interest Payment",
      field: "interest_payment",
      dataKey: "interestPayment",
    },
    {
      header: "Total Interest",
      field: "total_interest",
      dataKey: "totalInterest",
    },
    {
      header: "Principal Payment",
      field: "principal_payment",
      dataKey: "principalPayment",
    },
    {
      header: "Total Payment",
      field: "total_payment",
      dataKey: "totalPayment",
    },
    {
      header: "Total Payments",
      field: "total_payments",
      dataKey: "totalPayments",
    },
    { header: "Balance", field: "balance", dataKey: "balance" },
    {
      header: "Owner's Net Worth",
      field: "landlord_net_worth",
      dataKey: "landlordNetWorth",
    },
    {
      header: "Renter's Net Worth",
      field: "renter_net_worth",
      dataKey: "renterNetWorth",
    },
  ],
  generalData: {
    columns: [
      {
        label: "Month",
        field: "month",
        sort: "disabled",
      },
      {
        label: "Interest Payment",
        field: "interest_payment",
        sort: "disabled",
      },
      {
        label: "Total Interest",
        field: "total_interest",
        sort: "disabled",
      },
      {
        label: "Principal Payment",
        field: "principal_payment",
        sort: "disabled",
      },
      {
        label: "Total Payment",
        field: "total_payment",
        sort: "disabled",
      },
      {
        label: "Total Payments",
        field: "total_payments",
        sort: "disabled",
      },
      {
        label: "Balance",
        field: "balance",
        sort: "disabled",
      },
      {
        label: "Landlord Net Worth",
        field: "landlord_net_worth",
        sort: "disabled",
      },
      {
        label: "Renter's Net Worth",
        field: "renter_net_worth",
        sort: "disabled",
      },
    ],
  },
};

export default configPayments;
