import "./PaymentsList.scss";

import PropTypes from "prop-types";
import React from "react";

import PaymentOneRow from "../PaymentOneRow/PaymentOneRow";
import PaymentsSwitchers from "../PaymentsSwitchers/PaymentsSwitchers";

const PaymentsList = (props) => {
  const { data } = props;
  const visual = { left: "Months", right: "Years" };

  function getFullList(payments) {
    let output = [];
    payments.rows.forEach((el, index) => {
      const i = index;
      output = [
        ...output,
        <PaymentOneRow key={i} data={el} names={payments.columns} />,
      ];
    });
    return output;
  }

  return (
    <div className="wrapper show-mobile">
      <PaymentsSwitchers name="payments_items" visual={visual} />
      <div className="wrapper-list">
        <div className="list-cards">{getFullList(data)}</div>
      </div>
    </div>
  );
};

export default PaymentsList;

PaymentsList.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
};
