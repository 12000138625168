import _ from "lodash";

import configBanks from "../../configs/configBanks";
import configRates from "../../configs/configRates";

const { rates } = configRates;
const { banks } = configBanks;

// combine rates and banks
export const getListBanks = (type_rate) => {
  const output = _.sortBy(type_rate ? rates.variable : rates.fixed, "rate").map(
    (obj) => {
      return { ...obj, ...banks[obj.name] };
    }
  );
  return output;
};

// get images of Banks with current rate
export const getCurrentRateBanks = (input_value, input_array) => {
  const flt = _.filter(input_array, (obj) => {
    return obj.rate === input_value;
  });
  const output = { name: flt[0]?.name, img: flt[0]?.img, link: flt[0]?.link };
  return output;
};
