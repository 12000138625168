import "./ChartRechart.scss";

import PropTypes from "prop-types";
import React from "react";

import configChart from "../../configs/configChart";
import { getNameSet } from "./helpers";

const CustomizedLegend = (props) => {
  const { payload } = props;
  const { name_fields } = configChart;
  // console.log("CustomizedLegend-payload: ", payload);

  return (
    <>
      {payload[0] && (
        <div className="custom-legend">
          {payload.map((item, index) => {
            const i = index;
            return (
              <span key={i} style={{ color: `${item.color}` }}>
                {getNameSet(name_fields, item.value)}.
              </span>
            );
          })}
        </div>
      )}
      {!payload[0] && <div />}
    </>
  );
};

export default CustomizedLegend;

CustomizedLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.objectOf),
};

CustomizedLegend.defaultProps = {
  payload: [],
};
