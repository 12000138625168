import "./ChartRechart.scss";

import PropTypes from "prop-types";
import React from "react";

import configChart from "../../configs/configChart";
import { formatToMoney } from "../../helpers/commonHelpers";
import { getNameSet } from "./helpers";

const CustomTooltip = (props) => {
  const { label, payload } = props;
  const { name_fields } = configChart;
  // console.log("CustomTooltip-label: ", label);
  // console.log("CustomTooltip-payload: ", payload);

  return (
    <>
      {payload[0] && (
        <div className="custom-tooltip">
          <p className="desc">After {label} year:</p>
          {payload.map((item, index) => {
            const i = index;
            return (
              <p key={i} style={{ color: `${item.color}` }}>
                {`${getNameSet(name_fields, item.name)}: ${formatToMoney(
                  item.value
                )}`}
              </p>
            );
          })}
        </div>
      )}
      {!payload[0] && <div />}
    </>
  );
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  label: PropTypes.number,
  payload: PropTypes.arrayOf(PropTypes.any),
};

CustomTooltip.defaultProps = {
  label: 0,
  payload: [],
};
