import "./InsurancePageTable.scss";

import { Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

import InsurancePageFull from "../InsurancePageFull/InsurancePageFull";

const InsurancePageTable = (props) => {
  const { data } = props;

  const history = useHistory();
  const goHome = () => {
    history.replace("/");
  };
  const tlt = `<p><span class="red-name">${data.name}</span> - Insurance </p>`;

  return (
    <Modal
      title={ReactHtmlParser(tlt)}
      visible
      onOk={goHome}
      onCancel={goHome}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div className="insurance-page">
        <InsurancePageFull />
      </div>
    </Modal>
  );
};

export default InsurancePageTable;

InsurancePageTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
