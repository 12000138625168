import { isCanada } from "../../helpers/helper";

/**
 * Returns formatted string: pref + valF + suff
 * valF, pref, suff - newValue, preffix, suffix
 */
export function returnFormatter(valF, prefix, suff) {
  let formatter;
  if (prefix === "$") {
    formatter = valF.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (suff === "%") {
    formatter = `${parseFloat(Number(valF).toFixed(2))}%`;
  } else {
    formatter = `${Number(parseInt(valF, 10) || 0).toFixed(0)} ${suff}`;
  }
  return formatter;
}

export function returnFormatterPercent(valF) {
  let abs = parseFloat(valF);
  abs = abs.toFixed(2);
  const formatter = `${abs} %`;
  return formatter;
}

export function returnParcerPercent(valP) {
  let parcer = valP.replace("%", "");
  parcer = parcer.replace(/\s+/g, "");
  parcer = parcer.toFixed(2);
  return parcer;
}

export function formattedData(valF, prefix, suffix) {
  const formatter = new Intl.NumberFormat("en-EN", {
    maximumSignificantDigits: 2,
  }).format(valF);
  const result = `${prefix || ""}${formatter}${suffix || ""}`;
  return result;
}

export function formattedDataPercent(valF) {
  const formatter = new Intl.NumberFormat("en-EN", {
    maximumSignificantDigits: 2,
  }).format(valF);
  const result = `${formatter} %`;
  return result;
}

export function returnPercentValue(a, b) {
  const result = (a / b) * 100;
  return result;
}

export function getMinDownPayment(property) {
  const property_value = property;

  const output = {
    tip: "",
  };
  if (property_value < 500000) {
    output.tip = "";
    //  " If the home costs $500,000 or less, you’ll need a minimum down payment of 5%.";
    output.min = 5;
  } else if (property_value < 1000000) {
    output.tip =
      "If the home costs more than $500,000, you’ll need a minimum of 5% down on the first $500,000 and 10% on the remainder";
    const remainder = (property_value - 500000) / 10;
    // 25 is 5% from 500k
    output.min = (100 * (remainder + 25000)) / property_value;
  } else {
    if (isCanada()) {
      output.tip =
        "If the home costs $1,000,000 or more, mortgage loan insurance is not available so minimum downpayment is 20%";
    }
    output.min = 20;
  }

  return output;
}

export function getApproximatelyValue(name_field, value, property_value) {
  const nf = name_field;
  let output = "";
  const necessary_fields = {
    amount_annual_taxes: "percent",
    maintenance: "percent",
    owner_insurance: "percent",
    annual_heating_costs: "money",
  };

  if (typeof necessary_fields[nf] !== "undefined") {
    const val =
      necessary_fields[nf] === "percent"
        ? (property_value * value) / 100 / 12
        : value / 12;
    // todo better
    const val2 = val
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .split(".")[0];
    /* approximately */
    output = `~ ${val2} per month`;
  } else {
    // console.log("undefined");
  }
  return output;
}

export function checkFieldIsMoney(val) {
  const output = val === "$" ? "is-money" : "is-not-money";
  return output;
}
